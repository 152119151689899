export const listingBgSrc = "/public/images/listing-bg.jpg";

export const getAllImageUrls = (image) => {
  if (
    image.smallURL ||
    image.mediumURL ||
    image.largeURL ||
    image.thumbnailURL ||
    image.originalURL
  ) {
    return {
      smallURL: image.smallURL,
      mediumURL: image.mediumURL,
      largeURL: image.largeURL,
      thumbnailURL: image.thumbnailURL,
      originalURL: image.originalURL,
    };
  }

  if (
    image.urls?.small ||
    image.urls?.medium ||
    image.urls?.large ||
    image.urls?.thumbnail ||
    image.urls?.original
  ) {
    return {
      smallURL: image.urls.small,
      mediumURL: image.urls.medium,
      largeURL: image.urls.large,
      thumbnailURL: image.urls.thumbnail,
      originalURL: image.urls.original,
    };
  }

  if (
    image.small ||
    image.medium ||
    image.large ||
    image.thumbnail ||
    image.original
  ) {
    return {
      smallURL: image.small,
      mediumURL: image.medium,
      largeURL: image.large,
      thumbnailURL: image.thumbnail,
      originalURL: image.original,
    };
  }
};

export const constructBasicSrcset = (image) => {
  const srcSetList = [];

  if (image.thumbnailURL) {
    srcSetList.push(`${image.thumbnailURL} 370w`);
  }
  if (image.smallURL) {
    srcSetList.push(`${image.smallURL} 640w`);
  }
  if (image.mediumURL) {
    srcSetList.push(`${image.mediumURL} 1024w`);
  }
  if (image.largeURL) {
    srcSetList.push(`${image.largeURL} 1280w`);
  }

  const srcSet = srcSetList.join(", ");
  return {
    srcSet,
    src: image.originalURL,
  };
};
