import React, { useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useI18n } from "../../../spages/spa/context/I18nContext";
import "./FlexibleDates.scss";

export const FLEXIBLE_DAYS_OPTIONS = [7, 14, 28];

export const FlexibleDatesPreview = ({ value }) => (
  <span className="FlexibleDatesPreview" data-testid="FlexibleDatesPreview">
    (
    <span className="PlusMinus">
      <span>+</span>
      <span>-</span>
    </span>
    {`${Math.round(value / 7)}`})
  </span>
);

FlexibleDatesPreview.propTypes = {
  value: PropTypes.number.isRequired,
};

const getFlexibleDateOptions = (t) => {
  const weekOptions = FLEXIBLE_DAYS_OPTIONS.map((days) => ({
    label: t("components.DateRangePicker.FlexibleDates.Weeks", {
      weeks: days / 7,
    }),
    value: days,
  }));

  return [
    {
      label: t("components.DateRangePicker.FlexibleDates.ExactDates"),
      value: 0,
    },
    ...weekOptions,
  ];
};

const FlexibleDates = ({
  selectedFlexibleDays,
  onChange,
  disableSelection,
  isMobile,
}) => {
  const { t } = useI18n();
  const flexibleDateOptions = useMemo(() => getFlexibleDateOptions(t), [t]);
  const selectedOptionRef = useRef(null);
  const containerRef = useRef(null);

  const handleChange = (e) => {
    onChange(Number(e.target.value));
  };

  useEffect(() => {
    // Scroll to the selection option on mobile
    if (isMobile && selectedOptionRef.current && containerRef.current) {
      containerRef.current.scrollLeft =
        selectedOptionRef.current.offsetLeft - 10;
    }
  }, [selectedFlexibleDays, isMobile]);

  return (
    <div
      className={classNames("FlexibleDates", {
        "FlexibleDates--mobile": isMobile,
      })}
      data-testid="FlexibleDates"
      ref={containerRef}
    >
      {flexibleDateOptions.map(({ label, value }) => {
        const isSelected =
          value === selectedFlexibleDays ||
          (!selectedFlexibleDays && value === 0);
        return (
          <div
            key={value}
            className="FlexibleDates-option"
            ref={isSelected ? selectedOptionRef : null}
          >
            <input
              id={`FlexibleDates-option-${value}`}
              type="radio"
              key={value}
              name="flexibleDates"
              onChange={(e) => handleChange(e)}
              value={value}
              checked={isSelected}
              disabled={disableSelection}
            />
            <label
              htmlFor={`FlexibleDates-option-${value}`}
              data-testid={`FlexibleDates-option-${value / 7}`}
              className="FlexibleDates-label"
              data-checked={isSelected}
            >
              {value !== 0 && (
                <span className="PlusMinus">
                  <span>+</span>
                  <span>-</span>
                </span>
              )}
              {label}
            </label>
          </div>
        );
      })}
    </div>
  );
};

FlexibleDates.propTypes = {
  selectedFlexibleDays: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  disableSelection: PropTypes.bool,
  isMobile: PropTypes.bool,
};

export default FlexibleDates;
