import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import get from "lodash/get";
import { prepareModifiersForClassNames } from "../../utils/bem";

const propTypes = {
  t: PropTypes.func.isRequired,
  children: PropTypes.node,
  field: PropTypes.string,
  error: PropTypes.object,
  className: PropTypes.string,
  customErrorMessages: PropTypes.object,
  overrideErrorMessage: PropTypes.string,
};

const ErrorIndicator = (props) => {
  const {
    t,
    field,
    error,
    className,
    customErrorMessages,
    overrideErrorMessage,
  } = props;
  const validationError =
    error &&
    (error.name === "ValidationError" || error.name === "RequiredFieldError")
      ? error
      : null;

  const msg = err(field);
  const errorType = get(validationError, `fields.${field}`, "");

  return (
    <span
      data-field={field}
      className={classnames(
        "ErrorIndicator",
        className,
        prepareModifiersForClassNames("ErrorIndicator", {
          invalid: !!msg && error.name === "ValidationError",
          required: !!msg && error.name === "RequiredFieldError",
          full: props.children.type === "textarea",
        }),
      )}
    >
      {props.children}
      {msg && (
        <span
          data-errortype={errorType}
          className={classnames(
            "ErrorIndicator-message",
            prepareModifiersForClassNames("ErrorIndicator", { error: true }),
          )}
        >
          {errorParser(msg, field, customErrorMessages, overrideErrorMessage)}
        </span>
      )}
    </span>
  );

  function err(fieldName) {
    return (
      fieldName && validationError && get(validationError.fields, fieldName)
    );
  }

  function errorParser(msg, field, customErrorMessages, overrideErrorMessage) {
    if (overrideErrorMessage) {
      return overrideErrorMessage;
    }

    const msgArr = msg.split(new RegExp(":"));
    const errorType = msgArr[0];
    const expectation = msgArr[1];

    if (customErrorMessages && customErrorMessages.hasOwnProperty(errorType)) {
      return customErrorMessages[errorType];
    }

    return t(`errors.${errorType}`, { expectation }) || msg;
  }
};

ErrorIndicator.propTypes = propTypes;
export default ErrorIndicator;
