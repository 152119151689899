import dayjs from "dayjs";
import normalize from "./normalize";
import pairs from "./pairs";

const EARLIEST_DATE = new Date(-8640000000000000 / 2);
const LATEST_DATE = new Date(8640000000000000 / 2);

const utils = {
  /**
   * Returns the current year as a Number.
   * @memberof WunderDate
   * @return {Number} currentYear
   * @example
   * const currentYear = wunderDate.currentYear()
   *
   * console.log(currentYear) // 2017
   */
  currentYear: () => dayjs.utc().get("year"),

  /**
   * Can be used to determine whether a block is within a date range or not.
   * @param {DateRange} block the block that will be checked
   * @param {DateLike} block.from
   * @param {DateLike} block.to
   * @param {DateRange} range the date range that will be checked
   * @param {DateLike} range.from
   * @param {DateLike} range.to
   * @return {boolean} isWithinRange
   *
   * @todo add example
   */
  blockWithinRange: function blockWithinRange(block, range) {
    if (!range.from && !range.to) {
      return true;
    }

    block = normalize.block(block);
    range = normalize.block(range);

    return (
      (block.from.isSame(range.from, "day") ||
        block.from.isBetween(range.from, range.to)) &&
      (block.to.isSame(range.to, "day") ||
        block.to.isBetween(range.from, range.to))
    );
  },

  /**
   * Calculates all free periods for a date range with blocks.
   * @memberof WunderDate
   * @param {DateRange} range the date range that might have free periods
   * @param {DateLike} range.from
   * @param {DateLike} range.to
   * @param {Array.<DateRange>} blocks array of blocking ranges
   * @param {Number} minDuration minimum duration for a period
   * @param {String} resolution type of time unit that will be checked (seconds|minutes|hours|days|weeks|..)
   * @return {Array.<DateRange>} freePeriods
   *
   * @todo add example
   */
  freePeriods: function freePeriods(range, blocks, minDuration, resolution) {
    if (!range) {
      throw new Error("invalid range");
    }

    if (!Array.isArray(blocks)) {
      throw new Error("invalid blocks");
    }

    range = Object.assign(
      {
        from: EARLIEST_DATE,
        to: LATEST_DATE,
      },
      range,
    );

    resolution = resolution || "days";
    minDuration = minDuration || dayjs().add(1, "month") - dayjs();

    range = {
      from: dayjs.utc(range.from),
      to: dayjs.utc(range.to),
    };

    // convert everything to dayjss
    // filter out unneeded blocks
    blocks = blocks
      .map((b) => ({
        from: dayjs.utc(b.from),
        to: dayjs.utc(b.to),
      }))
      .filter((block) => block.to > range.from && block.from < range.to);

    const intervals = pairs([
      {
        to: dayjs.utc(range.from).subtract(1, resolution),
      },
      ...blocks,
      {
        from: dayjs.utc(range.to).add(1, resolution),
      },
    ]);

    return intervals.reduce((freePeriods, interval) => {
      const lastEnd = dayjs.utc(interval[0].to).add(1, resolution);
      const nextStart = dayjs.utc(interval[1].from).subtract(1, resolution);

      if (nextStart - lastEnd >= minDuration) {
        freePeriods.push({
          from: lastEnd.format("YYYY-MM-DD"),
          to: nextStart.format("YYYY-MM-DD"),
        });
      }

      return freePeriods;
    }, []);
  },

  /**
   * Calculates the first free time period for the date range.
   * @memberof WunderDate
   * @param {DateRange} range the date range that might have free periods
   * @param {DateLike} range.from
   * @param {DateLike} range.to
   * @param {Array.<DateRange>} blocks array of blocking ranges
   * @param {Number} minDuration minimum duration for a period
   * @param {TimeUnit} resolution type of time unit that will be checked (seconds|minutes|hours|days|weeks|..)
   * @return {{(DateRange|null)}} nextFreePeriod
   *
   * @todo add example
   */
  nextFreePeriod: function nextFreePeriod(
    range,
    blocks,
    minDuration,
    resolution,
  ) {
    return utils.freePeriods(range, blocks, minDuration, resolution)[0] || null;
  },

  /**
   * Detects if a period is available withing a date range.
   * @memberof WunderDate
   * @param {DateRange} period
   * @param {DateLike} period.from
   * @param {DateLike} period.to
   * @param {DateRange} range the date range that might have free periods
   * @param {DateLike} range.from
   * @param {DateLike} range.to
   * @param {Array.<DateRange>} blocks the blocked dates
   * @return {boolean}
   *
   * @todo add example
   */
  periodAvailableWithin: function periodAvailableWithin(period, range, blocks) {
    return (
      utils.blockWithinRange(period, range) &&
      !utils.rangeWithBlocks(period, blocks)
    );
  },

  /**
   * Converts a single point in a month to the date range from the beginning of the month to it's end.
   * @memberof WunderDate
   * @param {DateLike} date
   * @return {}
   *
   * @todo add example
   */
  monthToRange: function monthToRange(date) {
    return {
      from: normalize.from(date, "month"),
      to: normalize.to(date, "month"),
    };
  },

  /**
   * Converts a date range to an array of date ranges split into their months
   * @memberof WunderDate
   * @param {DateRange} range the date range that will be converted
   * @param {DateLike} range.from starting point for the range
   * @param {DateLike} range.to end point for the range
   * @return {Array.<DateRange>} months
   *
   * @todo add example
   */
  rangeToMonths: function rangeToMonths(range) {
    return utils.rangeTo(range, "months");
  },

  /**
   * Converts a date range to an array of date ranges split into the selected time unit.
   * @param {DateRange} range the date range that will be converted
   * @param {DateLike} range.from starting point for the range
   * @param {DateLike} range.to end point for the range
   * @return {Array.<DateRange>} units
   *
   * @todo add example
   */
  rangeTo: function rangeTo(range, unit) {
    range = normalize.block(range, unit);
    const from = range.from;
    const to = range.to;
    const num = to.diff(from, unit) + 1;

    return Array(num)
      .fill(0)
      .map((_, i) => dayjs.utc(from).add(i, unit));
  },

  /**
   * Check's whether a date is inside a block in a specified time unit.
   * ** Function is deprecated and will be removed in a future release. **
   * @memberof WunderDate
   * @deprecated
   * @param {DateLike} date date that will be checked
   * @param {DateRange} block
   * @param {DateLike} block.from starting point for the block
   * @param {DateLike} block.to end point for the block
   * @param {TimeUnit} resolution
   * @param {Boolean} noUtcTimezone
   * @return {boolean}
   */
  dateIntersectsBlock: function dateIntersectsBlock({
    date,
    block,
    resolution,
    noUtcTimezone,
  }) {
    resolution = resolution || "day";
    date = noUtcTimezone ? dayjs(date).startOf("day") : dayjs.utc(date);
    block = normalize.block(block, resolution, noUtcTimezone);

    return (
      date.isBetween(block.from, block.to) ||
      date.isSame(block.from, resolution) ||
      date.isSame(block.to, resolution)
    );
  },

  /**
   * Checks if a date range intersects with blocks.
   * @memberof WunderDate
   * @deprecated will be removed in a future release.
   * @alias rangeIntersectsBlocks
   * @param {DateRange} range
   * @param {DateLike} range.from
   * @param {DateLike} range.to
   * @param {Array.<DateRange>} blocks
   * @return {boolean}
   */
  rangeWithBlocks(range, blocks) {
    if (!blocks || blocks.length === 0 || !range.from || !range.to) {
      return false;
    }

    range = {
      from: dayjs.utc(range.from),
      to: dayjs.utc(range.to),
    };

    for (const block of blocks) {
      const from = dayjs.utc(block.from);
      const to = dayjs.utc(block.to);

      if (
        range.from.isBetween(from, to) ||
        range.to.isBetween(from, to) ||
        from.isBetween(range.from, range.to) ||
        to.isBetween(range.from, range.to) ||
        range.from.isSame(from, "day") ||
        range.from.isSame(to, "day") ||
        range.to.isSame(from, "day") ||
        range.to.isSame(to, "day")
      ) {
        return true;
      }
    }

    return false;
  },

  /**
   * Comparator to be used in a sort function
   * to sort an array of ranges/blocks/objects
   * with by the from field, in ascending order
   */
  sortByFromDateAscending: (a, b) => {
    if (a.from > b.from) return 1;
    if (b.from > a.from) return -1;
    return 0;
  },
};

export default utils;
