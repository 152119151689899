import slugify from "slugify";

slugify.extend({
  ö: "oe",
  ü: "ue",
  ä: "ae",
  "!": "",
});

export default function slug(string) {
  if (!string) {
    return "";
  }

  return slugify(
    string
      .replace(/[!"'()+.:]/g, "")
      .toLowerCase()
      .trim(),
  );
}
