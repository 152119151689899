import React, { useContext } from "react";
import PropTypes from "prop-types";
import { AuthContext } from "../../../../../contexts/AuthContext";
// Links
import BecomeALandlordLink from "../../../Links/BecomeALandlordLink";
import DiscoverLink from "../../../Links/DiscoverLink";
import HelpLink from "../../../Links/HelpLink";
import LogoutLink from "../../../Links/LogoutLink";
import MyAccountLink from "../../../Links/MyAccountLink";
import MyBookingsLink from "../../../Links/MyBookingsLink";
import ShortlistLink from "../../../Links/ShortlistLink";
import { useLokaliseMenuBtnHook } from "../../../LokaliseLink/LokaliseLink";

const LoggedInTenantMenu = ({
  currentUrl,
  analyticsEvent,
  lang,
  t,
  url,
  user,
}) => {
  const { userActionsCount } = useContext(AuthContext);
  const accountActions = userActionsCount?.accountActions;
  const bookingActions = userActionsCount?.bookingActions;
  const { renderLokaliseBtn } = useLokaliseMenuBtnHook(user);
  return (
    <React.Fragment>
      <div className="AppHeaderDesktopUserMenu--large">
        <ul className="AppHeaderDesktopUserMenu-LinkList AppHeaderDesktopUserMenu-LinkList--loggedIn">
          <li className="AppHeaderDesktopUserMenu-LinkList-item">
            <MyAccountLink
              currentUrl={currentUrl}
              expand
              notifications={accountActions}
              t={t}
              url={url}
              analyticsEvent={analyticsEvent}
            />
          </li>

          <li className="AppHeaderDesktopUserMenu-LinkList-item">
            <MyBookingsLink
              t={t}
              url={url}
              currentUrl={currentUrl}
              notifications={bookingActions}
              expand
              analyticsEvent={analyticsEvent}
            />
          </li>
          {renderLokaliseBtn()}
          <li className="AppHeaderDesktopUserMenu-LinkList-item">
            <LogoutLink
              t={t}
              analyticsEvent={analyticsEvent}
              url={url}
              lang={lang}
              currentUrl={currentUrl}
            />
          </li>
        </ul>
      </div>

      <div className="AppHeaderDesktopUserMenu--medium">
        <ul className="AppHeaderDesktopUserMenu-LinkList AppHeaderDesktopUserMenu-LinkList--loggedIn">
          <li className="AppHeaderDesktopUserMenu-LinkList-item">
            <DiscoverLink
              t={t}
              url={url}
              lang={lang}
              currentUrl={currentUrl}
              expand
              analyticsEvent={analyticsEvent}
            />
          </li>

          <li className="AppHeaderDesktopUserMenu-LinkList-item">
            <ShortlistLink
              currentUrl={currentUrl}
              expand
              t={t}
              url={url}
              analyticsEvent={analyticsEvent}
            />
          </li>

          <li className="AppHeaderDesktopUserMenu-LinkList-item">
            <MyAccountLink
              currentUrl={currentUrl}
              expand
              notifications={accountActions}
              t={t}
              url={url}
              analyticsEvent={analyticsEvent}
            />
          </li>

          <li className="AppHeaderDesktopUserMenu-LinkList-item">
            <MyBookingsLink
              t={t}
              url={url}
              currentUrl={currentUrl}
              expand
              notifications={bookingActions}
              analyticsEvent={analyticsEvent}
            />
          </li>
        </ul>

        <hr className="AppHeaderDesktopUserMenu-separator" />

        <ul className="AppHeaderDesktopUserMenu-LinkList">
          <li className="AppHeaderDesktopUserMenu-LinkList-item">
            <BecomeALandlordLink url={url} />
          </li>

          <li className="AppHeaderDesktopUserMenu-LinkList-item">
            <HelpLink t={t} url={url} currentUrl={currentUrl} lang={lang} />
          </li>

          {renderLokaliseBtn()}

          <li className="AppHeaderDesktopUserMenu-LinkList-item">
            <LogoutLink
              t={t}
              url={url}
              analyticsEvent={analyticsEvent}
              lang={lang}
              currentUrl={currentUrl}
            />
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

LoggedInTenantMenu.propTypes = {
  currentUrl: PropTypes.string,
  analyticsEvent: PropTypes.func,
  lang: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  url: PropTypes.func.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
};

export default LoggedInTenantMenu;
