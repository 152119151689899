import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import WunderflatsLogo from "../../WunderflatsLogo/WunderflatsLogo";
import WunderflatsLogoBadge from "../../WunderflatsLogo/WunderflatsLogoBadge";
import "./Logo.scss";

const Logo = ({
  t,
  url,
  badgeId = "WunderflatsLogoBadge-Header",
  logoId = "WunderflatsLogo-Header",
  logoClassName,
}) => {
  return (
    <a
      href={url("home")}
      title={t("homepage")}
      aria-label={t("homepage")}
      className={classNames("AppHeader-LogoLink", logoClassName)}
    >
      <span className="AppHeader-WunderflatsLogoBadge">
        <WunderflatsLogoBadge id={badgeId} />
      </span>
      <span className="AppHeader-WunderflatsLogo">
        <WunderflatsLogo id={logoId} />
      </span>
      <span className="sr-only">Wunderflats</span>
    </a>
  );
};

Logo.propTypes = {
  t: PropTypes.func.isRequired,
  url: PropTypes.func.isRequired,
  badgeId: PropTypes.string,
  logoId: PropTypes.string,
  logoClassName: PropTypes.string,
};

export default Logo;
