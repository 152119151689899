import React, { Fragment } from "react";
import PropTypes from "prop-types";
// Links
import BecomeALandlordLink from "../../Links/BecomeALandlordLink";
import DiscoverLink from "../../Links/DiscoverLink";
import Help from "../../Links/HelpLink";
import ShortlistLink from "../../Links/ShortlistLink";
import LanguageSwitch from "./LanguageSwitch";

const NotLoggedInUser = ({
  currentUrl,
  analyticsEvent,
  lang,
  t,
  translationUrls,
  url,
}) => {
  // If `currentUrl` exists then parse it and pass as a redirect.
  let loginRoute = url("login");
  if (currentUrl) {
    const parsedCurrentURL = new URL(currentUrl);
    const redirect = parsedCurrentURL.pathname + parsedCurrentURL.search;

    loginRoute = url("login", {}, { query: { redirect } });
  }

  return (
    <Fragment>
      <div
        className="AppHeaderDrawer-signInButton"
        data-testid="AppHeaderDrawer-signInButton"
      >
        <a className="AppHeaderDrawer-signInButton-link" href={loginRoute}>
          {t("components.AppHeader.signIn")}
        </a>
      </div>

      <ul className="AppHeaderDrawer-LinkList">
        <li className="AppHeaderDrawer-LinkList-item">
          <DiscoverLink
            currentUrl={currentUrl}
            expand
            lang={lang}
            showIcon
            t={t}
            url={url}
            analyticsEvent={analyticsEvent}
          />
        </li>

        <li className="AppHeaderDrawer-LinkList-item">
          <ShortlistLink
            currentUrl={currentUrl}
            expand
            showIcon
            t={t}
            url={url}
            analyticsEvent={analyticsEvent}
          />
        </li>
        <li className="AppHeaderDrawer-LinkList-item">
          <BecomeALandlordLink url={url} showIcon />
        </li>
      </ul>

      <ul className="AppHeaderDrawer-LinkList AppHeaderDrawer-LinkList-bottom">
        <li className="AppHeaderDrawer-LinkList-item">
          <Help t={t} url={url} lang={lang} currentUrl={currentUrl} />
        </li>

        {translationUrls && (
          <li className="AppHeaderDrawer-LinkList-item">
            <LanguageSwitch lang={lang} translationUrls={translationUrls} />
          </li>
        )}
      </ul>
    </Fragment>
  );
};

NotLoggedInUser.propTypes = {
  currentUrl: PropTypes.string,
  analyticsEvent: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  translationUrls: PropTypes.shape({
    de: PropTypes.string.isRequired,
    en: PropTypes.string.isRequired,
  }),
  url: PropTypes.func.isRequired,
};

export default NotLoggedInUser;
