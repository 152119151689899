/* eslint-disable graphql/template-strings */
import gql from "./gql";

// Give different tag name to bypass linting against apollo graphql endpoint.
const nexusGql = gql;

class LRTransitionToTenantApprovedError extends Error {
  constructor(code, message) {
    super();

    this.name = this.constructor.name;
    this.code = code;
    this.message = message;
  }
}

const factory = (http) => {
  const bookingRequests = {
    acceptListingRequest(bookingRequestId, listingRequestId) {
      return http.post(
        `/booking-requests-2/${bookingRequestId}/${listingRequestId}/accept`,
      );
    },

    declineListingRequest(bookingRequestId, listingRequestId, payload) {
      return http.post(
        `/booking-requests-2/${bookingRequestId}/${listingRequestId}/decline`,
        payload,
      );
    },

    getBookingRequestsForMultilisting(groupId, query = {}) {
      return http
        .get(`/listings/g/${groupId}/booking-requests-2`, { params: query })
        .then(({ bookingRequests }) =>
          bookingRequests.sort(
            (br1, br2) =>
              new Date(br1.listingRequest.from) -
              new Date(br2.listingRequest.from),
          ),
        );
    },

    acceptMulistingRequest(bookingRequestId, listingRequestId, listingId) {
      return http.post(
        `/booking-requests-2/${bookingRequestId}/${listingRequestId}/${listingId}/accept`,
      );
    },

    getBookingRequestsForListing(listingId, query = {}) {
      return http
        .get(`/listings/${listingId}/booking-requests-2`, { params: query })
        .then(({ bookingRequests }) =>
          bookingRequests.sort(
            (br1, br2) =>
              new Date(br1.listingRequest.from) -
              new Date(br2.listingRequest.from),
          ),
        );
    },

    getConflictingBookingRequests(bookingRequestId, listingId, query) {
      return http
        .get(`/listings/${listingId}/booking-requests-2`, {
          params: query,
        })
        .then(({ bookingRequests }) =>
          bookingRequests
            .filter((br) => br._id !== bookingRequestId)
            .sort(
              (br1, br2) =>
                new Date(br1.listingRequest.from) -
                new Date(br2.listingRequest.from),
            ),
        );
    },

    getConflictingBookingRequestsForMultilisting(
      bookingRequestId,
      groupId,
      query,
    ) {
      return http
        .get(`/listings/g/${groupId}/booking-requests-2`, {
          params: query,
        })
        .then(({ bookingRequests }) =>
          bookingRequests
            .filter((br) => br._id !== bookingRequestId)
            .sort(
              (br1, br2) =>
                new Date(br1.listingRequest.from) -
                new Date(br2.listingRequest.from),
            ),
        );
    },

    getBookingRequestsForTenant(tenantId, query = {}) {
      return http
        .get(`/users/${tenantId}/tenant/booking-requests-2`, {
          params: query,
        })
        .then((data) => data.bookingRequests);
    },

    async transitionToAvailable(listingRequestId) {
      const { data, errors } = await http.post("/graphql", {
        query: gql`
          mutation ($listingRequestId: String!) {
            transitionToAvailable(
              input: { listingRequestId: $listingRequestId }
            ) {
              ... on TransitionToPayloadSuccess {
                success
              }
              ... on TransitionToPayloadError {
                code
                message
              }
            }
          }
        `,
        variables: { listingRequestId },
      });

      if (errors && errors.length) {
        throw new Error(errors.map((error) => error.message).join(","));
      }

      return data.transitionToAvailable;
    },

    async transitionToTenantWithdrew(listingRequestId, reason, subReason) {
      const { data, errors } = await http.post("/nexus", {
        query: gql`
          mutation (
            $listingRequestId: ID!
            $reason: WithdrawReasonsType!
            $subReason: String
          ) {
            transitionToTenantWithdrew(
              listingRequestId: $listingRequestId
              reason: $reason
              subReason: $subReason
            ) {
              success
            }
          }
        `,
        variables: { listingRequestId, reason, subReason },
      });

      if (errors && errors.length) {
        throw new Error(errors.map((error) => error.message).join(","));
      }

      return data.transitionToTenantWithdrew;
    },

    async createTenantFeeStripePaymentIntent(listingRequestId, proposedFee) {
      const { data, errors } = await http.post("/nexus", {
        query: gql`
          mutation createTenantFeeStripePaymentIntent(
            $listingRequestId: ID!
            $proposedFee: Int!
          ) {
            createTenantFeeStripePaymentIntent(
              listingRequestId: $listingRequestId
              proposedFee: $proposedFee
            ) {
              ... on CreateTenantFeeStripePaymentIntentPayloadSuccess {
                clientSecret
              }
              ... on CreateTenantFeeStripePaymentIntentPayloadError {
                name
                message
              }
            }
          }
        `,
        variables: { listingRequestId, proposedFee },
      });

      if (errors && errors.length) {
        throw new Error(errors.map((error) => error.message).join(","));
      }

      return data.createTenantFeeStripePaymentIntent;
    },

    async transitionToTenantApproved(listingRequestId, sftValue) {
      const { data, errors } = await http.post("/nexus", {
        query: nexusGql`
          mutation transitionToTenantApproved($listingRequestId: ID!, $sftValue: Int) {
            transitionToTenantApproved(listingRequestId: $listingRequestId, sftValue: $sftValue) {
              ... on TransitionToTenantApprovedSuccessPayload {
                success
              }

              ... on TransitionToTenantApprovedErrorPayload {
                message
                code
              }
            }
          }
        `,
        variables: { listingRequestId, sftValue },
      });

      const knownErrors = [
        "NOT_FOUND", // Error for when a resource is not found.
        "TRANSITION_NOT_POSSIBLE", // Error for when the request is not in AVAILABLE
        "UNAUTHORIZED", // Error for when user is unauthorized.
        "WRONG_SFT_VALUE", // Service fee has a wrong value
      ];

      const hasError =
        data?.transitionToTenantApproved?.__typename ===
        "TransitionToTenantApprovedPayloadError";
      const code = data?.transitionToTenantApproved?.code;
      const message = data?.transitionToTenantApproved?.message;

      if (hasError && code && knownErrors.includes(code)) {
        throw new LRTransitionToTenantApprovedError(code, message);
      }

      if ((hasError && code === "UNKNOWN") || errors?.length > 0) {
        throw new Error();
      }

      return data;
    },
  };

  return bookingRequests;
};

export default factory;
