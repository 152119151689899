import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useI18n } from "../../../spages/spa/context/I18nContext";
import { useTracker } from "../../../spages/spa/context/TrackerContext";
import { LANDLORD_INTENT_LOCATION } from "../../../tracker/constants";
import LogoPlus from "../../Icons/LogoPlus";

const BecomeALandlordLink = ({ url, showIcon = false }) => {
  const { t } = useI18n();
  const { tracker } = useTracker();

  return (
    <a
      className={classnames("AppHeader-Link")}
      href={url("landlords")}
      data-testid="AppHeader-Link-becomeLandlord"
      onClick={() => {
        tracker.events.trackLandlordIntentClicked({
          triggerLocation: LANDLORD_INTENT_LOCATION.HEADER,
        });
      }}
    >
      {showIcon && (
        <span className="AppHeader-Link-icon">
          <LogoPlus />
        </span>
      )}
      <span className="AppHeader-Link-text">
        {t("components.AppHeader.forLandlords")}
      </span>
    </a>
  );
};

BecomeALandlordLink.propTypes = {
  url: PropTypes.func.isRequired,
  showIcon: PropTypes.bool,
};

export default BecomeALandlordLink;
