import { useCallback, useEffect, useMemo } from "react";
import qs from "qs";
import { useHistory, useLocation } from "react-router-dom";
import store2 from "store2";
import toaster from "../components/Toaster/Toaster";
import { useI18n } from "../spages/spa/context/I18nContext";

export const LOCAL_STORE_KEY = "persistToasts";
export const QUERY_PARAMS_KEY = "toasts";

export const MARKETING_CONSENT_KEY = "marketingConsent";

export const unPersistToast = (id) => {
  let keys = store2(LOCAL_STORE_KEY);
  if (!keys || keys.length === 0) return;
  keys = keys.filter((key) => key !== id);
  store2(LOCAL_STORE_KEY, keys);
};

function useToasterQueryParamsTrigger({ url, toastersMap: _toastersMap }) {
  const history = useHistory();
  const location = useLocation();
  const { t, lang } = useI18n();

  const toastersMap = useMemo(() => {
    return (
      _toastersMap || [
        {
          id: MARKETING_CONSENT_KEY,
          title: t("components.toaster.marketingConsent.title"),
          text: t("components.toaster.marketingConsent.description"),
          buttonLabel: t("components.toaster.marketingConsent.buttonLabel"),
          intent: "brand",
          timeout: null,
          persist: true,
          buttonLink: url("mySettings", { lang }, {}),
          buttonClickHandler: () => unPersistToast(MARKETING_CONSENT_KEY),
          closeButtonClickHandler: () => unPersistToast(MARKETING_CONSENT_KEY),
        },
      ]
    );
  }, [t, lang, url, _toastersMap]);

  const removeToastersFromQueryParams = useCallback(
    (queryParams) => {
      delete queryParams[QUERY_PARAMS_KEY];

      history.replace({
        pathname: location.pathname,
        search: qs.stringify(queryParams, { addQueryPrefix: true }),
      });
    },
    [history, location.pathname],
  );

  useEffect(() => {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    const toasterQueryParam = query[QUERY_PARAMS_KEY];
    const toastersFromQueryParams = toasterQueryParam
      ? toasterQueryParam.split(",")
      : [];
    const toastersFromStorage = store2(LOCAL_STORE_KEY) || [];

    // Merge active toasters keys from query params and from localStorage
    const toasterKeys = [
      ...new Set([...toastersFromQueryParams, ...toastersFromStorage]),
    ];
    if (toasterKeys.length === 0) {
      store2.remove(LOCAL_STORE_KEY);
      return;
    }

    // Filter to predefined toasters only
    const toasters = toastersMap.filter((toasterToShow) =>
      toasterKeys.includes(toasterToShow.id),
    );

    const persistToastersKeys = [];
    toasters.forEach((props) => {
      // Show toaster
      toaster.basicToaster.add(props);

      // Prepare persist toasters ids to store
      if (props.persist) persistToastersKeys.push(props.id);
    });

    // Store persist toasters in local storage
    store2(LOCAL_STORE_KEY, persistToastersKeys);

    // Cleanup URL
    removeToastersFromQueryParams(query);
  }, [location.search, toastersMap, removeToastersFromQueryParams]);
}

export default useToasterQueryParamsTrigger;
