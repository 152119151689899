import React from "react";
import PropTypes from "prop-types";

const Profile = ({ width = 26, height = 26 }) => (
  <svg
    height={height}
    viewBox="0 0 26 26"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fill="#fff"
        d="M15 15.66a6.52 6.52 0 001.36-3.23c.36-2.35-.93-4.67-3.6-4.67s-4 2.18-3.6 4.67a6.42 6.42 0 001.35 3.23 5 5 0 00-3.75 4.83v.35a10 10 0 0012 .33v-.68A5 5 0 0015 15.66z"
      />

      <path
        fill="#24272e"
        d="M13 2a11 11 0 1011 11A11 11 0 0013 2zm-2.19 12.8a6.35 6.35 0 01-.88-2.48 3.62 3.62 0 01.67-2.91 2.77 2.77 0 012.18-.9 2.75 2.75 0 012.16.92 3.57 3.57 0 01.69 2.88 6.23 6.23 0 01-.9 2.52 2.34 2.34 0 01-2 1.18 2.36 2.36 0 01-1.95-1.18zm2 2.71a3.58 3.58 0 002.49-1 4.25 4.25 0 012.7 3.95 9 9 0 01-10.48-.3 4.22 4.22 0 012.75-3.65 3.6 3.6 0 002.51 1zm6.63 1.8a5.72 5.72 0 00-3.2-4 8.24 8.24 0 00.91-2.75 5 5 0 00-1-4.08A4.19 4.19 0 0012.78 7a4.2 4.2 0 00-3.31 1.43 5.09 5.09 0 00-1 4.1 7.8 7.8 0 00.9 2.76 5.72 5.72 0 00-3.09 3.65 9 9 0 1113.16.37z"
      />
    </g>
  </svg>
);

Profile.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default Profile;
