import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";

const SpaPageContext = React.createContext({
  isSpagePage: false,
});

const providerPropTypes = {
  isSpaPage: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export function SpaPageContextProvider({ isSpaPage, children }) {
  const contextValue = useMemo(() => ({ isSpaPage }), [isSpaPage]);

  return (
    <SpaPageContext.Provider value={contextValue}>
      {children}
    </SpaPageContext.Provider>
  );
}

SpaPageContextProvider.propTypes = providerPropTypes;

export function useSpaPage() {
  return useContext(SpaPageContext);
}
