// All queries with this tag are linted against the schema on the endpoint
// `api/graphql`.
import gql from "./gql";

// Give different tag name to bypass linting against apollo graphql endpoint.
const nexusGql = gql;

class UserAlreadyExistsError extends Error {
  constructor() {
    super();
    this.name = "UserAlreadyExistsError";
  }
}

const getBillingDetailsError = (nexusResponseItem) => {
  const error = { message: nexusResponseItem.message };
  error.name =
    nexusResponseItem.name !== "Error"
      ? nexusResponseItem.name
      : nexusResponseItem.__typename;
  error.validationErrorJSON =
    nexusResponseItem.validationErrorJSON &&
    JSON.parse(nexusResponseItem.validationErrorJSON);
  throw error;
};

const factory = (http, httpFrontend) => {
  return {
    update(userId, data) {
      return http.put(`/users/${userId}`, data);
    },

    getAuthenticatedUser() {
      return http.get("/users/me").then((data) => data.user);
    },

    getUserActions() {
      return httpFrontend.get("/user-actions");
    },

    async createIdVerificationSession() {
      const { data, errors } = await http.post("/nexus", {
        query: nexusGql`
          mutation createIdVerificationSession {
            createIdVerificationSession {
              verificationStatus {
                date
                url
                sessionId
              }
            }
          }
        `,
      });

      if (errors && errors.length) {
        throw new Error(errors.map((e) => `${e.message}\n`));
      }

      const { date, url, sessionId } =
        data.createIdVerificationSession.verificationStatus;

      return { date, url, sessionId };
    },

    async tenantNumberOfPendingTasks() {
      const { data, errors } = await http.post("/nexus", {
        query: nexusGql`
          query tenantNumberOfPendingTasks {
            me {
              tenantNumberOfPendingTasks
            }
          }
        `,
      });

      if (errors && errors.length) {
        throw new Error(errors.map((e) => `${e.message}\n`));
      }

      return data?.me?.tenantNumberOfPendingTasks;
    },

    async getDataForNotification(userId) {
      const { data, errors } = await http.post("/nexus", {
        query: nexusGql`
          query($userId: ID!) {
            userById(userId: $userId) {
              id
              tenantRequestedDocuments {
                type
                required
                additional
                uploadedFile {
                  createdAt
                  deleteDate
                  downloadUrl
                  fileName
                  type
                  _id
                }
              }
            }
            me {
              tenantNumberOfPendingTasks
            }
          }
        `,
        variables: { userId },
      });

      if (errors && errors.length) {
        throw new Error(errors.map((e) => `${e.message}\n`));
      }

      return {
        tenantRequestedDocuments: data?.userById?.tenantRequestedDocuments,
        tenantNumberOfPendingTasks: data?.me?.tenantNumberOfPendingTasks,
      };
    },

    async getIdVerificationStatus(userId) {
      const { data, errors } = await http.post("/nexus", {
        query: `
          query userIdVerificationStatus($userId: ID) {
            userIdVerificationStatus(userId: $userId) {
              ... on UserIdVerificationStatusUnverified {
                status
              }

              ... on UserIdVerificationStatusStarted {
                status
              }

              ... on UserIdVerificationStatusSucceeded {
                status,
                date
              }

              ... on UserIdVerificationStatusFailed {
                status
              }

              ... on UserIdVerificationStatusPending {
                status
              }
            }
          }
          `,
        variables: { userId },
      });

      if (errors && errors.length) {
        throw new Error(errors.map((e) => `${e.message}\n`));
      }

      return data.userIdVerificationStatus;
    },

    async getSolvencyInfoStatus(userId) {
      const { data, errors } = await http.post("/nexus", {
        query: nexusGql`
          query solvencyInfoStatusByUserId($userId: ID!) {
            userById(userId: $userId) {
              id
              solvencyInfoStatus {
                __typename
                documentType
                status

                ... on SolvencyInfoStatusInProgress {
                  provider
                }

                ... on SolvencyInfoStatusFailed {
                  provider
                  type
                  canRequestManualVerification
                }
              }
            }
          }
        `,
        variables: { userId },
      });

      if (errors && errors.length) {
        throw new Error(errors.map((e) => `${e.message}\n`));
      }
      return data.userById.solvencyInfoStatus;
    },

    async requestManualDocumentValidation(fileId) {
      const { data, errors } = await http.post("/nexus", {
        query: nexusGql`
          mutation startManualSolvencyCheck($fileId: ID!) {
            startManualSolvencyCheck(fileId: $fileId) {
              success
              error
            }
          }

        `,
        variables: { fileId },
      });

      if (errors && errors.length) {
        throw new Error(errors.map((e) => `${e.message}\n`));
      }
      return data.startManualSolvencyCheck;
    },

    async confirmUserEmail(token) {
      const { data, errors } = await http.post("/nexus", {
        query: nexusGql`
          mutation confirmUserEmail($token: String!) {
            confirmUserEmail(token: $token) {
              __typename

              ... on ConfirmUserEmailSuccessPayload {
                id
                authToken
                previousSubscriptions {
                  generalNews
                  productUpdates
                  promotions
                  tips
                }
              }

              ... on ConfirmUserEmailErrorPayload {
                name
                message
              }
            }
          }
        `,
        variables: { token },
      });

      if (errors && errors.length) {
        throw new Error(errors.map((e) => `${e.message}\n`));
      }

      return data;
    },

    acceptGeneralTos() {
      return http.put("/users/me/tos").then((data) => data.user);
    },

    submitKyc(userId, payload) {
      let userType;

      switch (payload.legalUser && payload.legalUser.legalType) {
        case "SOLETRADER":
          userType = "soletrader";
          break;
        case "BUSINESS":
          userType = "business";
          break;
        case "ORGANIZATION":
          userType = "organization";
          break;
        default:
          userType = "natural";
          break;
      }

      return http
        .post(`/users/${userId}/kyc/${userType}`, payload)
        .then((data) => data.user);
    },

    updateKyc(userId, documentType, payload) {
      return http
        .put(`/users/${userId}/kyc/docs/${documentType}`, payload, {
          timeout: 120000,
        })
        .then((data) => data.user);
    },

    async updateEmail(newEmail) {
      let errors = null;
      try {
        const response = await http.post("/nexus", {
          query: nexusGql`
              mutation($newEmail: String!) {
                updateEmail(newEmail: $newEmail)
              }
            `,
          variables: { newEmail },
        });

        if (response && response.errors) {
          errors = response.errors;
        }
      } catch (error) {
        errors = JSON.parse(error.request.response).errors;
      }

      if (errors && errors.length > 0) {
        if (errors[0].message === "UserAlreadyExistsError") {
          throw new UserAlreadyExistsError();
        }

        throw new Error(errors.map((e) => `${e.message}`));
      }
    },

    async cancelEmailChange() {
      const { errors } = await http.post("/nexus", {
        query: nexusGql`
        mutation {
          cancelEmailChange
        }
      `,
      });

      if (errors && errors.length) {
        throw new Error(errors.map((e) => `${e.message}\n`));
      }
    },

    // TODO: remove once ENVIRONMENT_YOUSIGN_CONTRACTS is enabled
    async isYousignEnabledForUSer(userId) {
      const query = {
        query: nexusGql`
          query userById($userId: ID!) {
            userById(userId: $userId) {
              isYousignEnabled
            }
          }
        `,
        variables: { userId },
      };
      const { data, errors } = await http.post("/nexus", query);

      if (errors && errors.length) {
        throw new Error(errors.map((e) => `${e.message}\n`));
      }

      return data.userById.isYousignEnabled;
    },

    async getUserById(userId, isSales = false) {
      const state = isSales ? "ANY" : "ACTIVE";
      const query = {
        query: nexusGql`
          query userById($userId: ID!, $state: BillingDetailsItemByIdInputState) {
            userById(userId: $userId) {
              billingDetailsListLink(state: $state) {
                id
                deleted
                firstName
                lastName
                taxID
                vatID
                countryOfIssue
                cityOfBirth
                countryOfBirth
                otherCountryOfBirth
                dateOfBirth
                isCompliant,
                address {
                  addressLine1
                  addressLine2
                  city
                  country
                  zipCode
                }
                billingAddress {
                  firstName
                  lastName
                  companyName
                  addressLine1
                  addressLine2
                  city
                  country
                  zipCode
                }
                commercialFields {
                  type
                  companyName
                  vatID
                  taxID
                  countryOfIssue
                  companyRegistrationNumber
                }
              }
            }
          }
        `,
        variables: { userId, state },
      };
      const { data, errors } = await http.post("/nexus", query);

      if (errors && errors.length) {
        throw new Error(errors.map((e) => `${e.message}\n`));
      }

      return data.userById;
    },

    async updateInvoiceEmail(invoiceEmail) {
      const response = await http.post("/nexus", {
        query: nexusGql`
          mutation updateInvoiceEmail($invoiceEmail: String!) {
            updateInvoiceEmail(invoiceEmail: $invoiceEmail) {
              __typename
              ... on UpdateInvoiceEmailSuccessPayload {
                success
                userLink {
                  invoiceEmail
                }
              }
              ... on UpdateInvoiceEmailErrorPayload {
                name
                message
              }
            }
          }
        `,
        variables: {
          invoiceEmail,
        },
      });
      if (response.errors && response.errors.length) {
        throw new Error(response.errors.map((e) => `${e.message}\n`));
      }

      if (
        response.data.updateInvoiceEmail.__typename ===
        "UpdateInvoiceEmailSuccessPayload"
      ) {
        return response.data;
      }

      if (
        response.data.updateInvoiceEmail.__typename ===
        "UpdateInvoiceEmailErrorPayload"
      ) {
        throw new Error(response.data.updateInvoiceEmail.name);
      }
    },

    async deleteBillingDetails(id) {
      const response = await http.post("/nexus", {
        query: nexusGql`
          mutation deleteBillingDetailsItem($input: DeleteBillingDetailsItemInput!) {
            deleteBillingDetailsItem(input: $input) {
              __typename
              ... on DeleteBillingDetailsItemPayloadSuccess {
                success
              }
              ... on DeleteBillingDetailsItemPayloadError {
                message
                name
              }
            }
          }
        `,
        variables: {
          input: {
            id,
          },
        },
      });

      if (response.errors && response.errors.length) {
        throw new Error(response.errors.map((e) => `${e.message}\n`));
      }

      if (
        response.data.deleteBillingDetailsItem.__typename ===
        "DeleteBillingDetailsItemPayloadSuccess"
      ) {
        return response.data.deleteBillingDetailsItem;
      }

      if (
        response.data.deleteBillingDetailsItem.__typename ===
        "DeleteBillingDetailsItemPayloadError"
      ) {
        throw getBillingDetailsError(response.data.deleteBillingDetailsItem);
      }
    },

    async createBillingDetails(input) {
      const response = await http.post("/nexus", {
        query: nexusGql`
          mutation createBillingDetailsItem($input: CreateBillingDetailsItemInput!) {
            createBillingDetailsItem(input: $input) {
              __typename
              ... on CreateBillingDetailsItemPayloadSuccess {
                billingDetailsItem {
                  id
                  firstName
                  lastName
                  taxID
                  vatID
                  countryOfIssue
                  cityOfBirth
                  countryOfBirth
                  otherCountryOfBirth
                  dateOfBirth
                  isCompliant,
                  address {
                    addressLine1
                    addressLine2
                    city
                    country
                    zipCode
                  }
                  billingAddress {
                    firstName
                    lastName
                    companyName
                    addressLine1
                    addressLine2
                    city
                    country
                    zipCode
                  }
                  commercialFields {
                    type
                    companyName
                    vatID
                    taxID
                    countryOfIssue
                    companyRegistrationNumber
                  }
                }
              }
              ... on CreateBillingDetailsItemPayloadError {
                message
                name
                validationErrorJSON
              }
            }
          }

        `,
        variables: {
          input,
        },
      });

      if (response.errors && response.errors.length) {
        throw new Error(response.errors.map((e) => `${e.message}\n`));
      }

      if (
        response.data.createBillingDetailsItem.__typename ===
        "CreateBillingDetailsItemPayloadSuccess"
      ) {
        return response.data.createBillingDetailsItem;
      }

      if (
        response.data.createBillingDetailsItem.__typename ===
        "CreateBillingDetailsItemPayloadError"
      ) {
        throw getBillingDetailsError(response.data.createBillingDetailsItem);
      }
    },

    async updateBillingDetails(id, input) {
      const response = await http.post("/nexus", {
        query: nexusGql`
          mutation updateBillingDetailsItem($input: UpdateBillingDetailsItemInput!) {
            updateBillingDetailsItem(input: $input) {
              __typename
              ... on UpdateBillingDetailsItemPayloadSuccess {
                billingDetailsItem {
                  id
                  deleted
                  firstName
                  lastName
                  taxID
                  vatID
                  countryOfIssue
                  cityOfBirth
                  countryOfBirth
                  otherCountryOfBirth
                  dateOfBirth
                  isCompliant,
                  address {
                    addressLine1
                    addressLine2
                    city
                    country
                    zipCode
                  }
                  billingAddress {
                    firstName
                    lastName
                    companyName
                    addressLine1
                    addressLine2
                    city
                    country
                    zipCode
                  }
                  commercialFields {
                    type
                    companyName
                    vatID
                    taxID
                    countryOfIssue
                    companyRegistrationNumber
                  }
                }
              }
              ... on UpdateBillingDetailsItemPayloadError {
                message
                name
                validationErrorJSON
              }
            }
          }
        `,
        variables: {
          input: { id, ...input },
        },
      });

      if (response.errors && response.errors.length) {
        throw new Error(response.errors.map((e) => `${e.message}\n`));
      }

      if (
        response.data.updateBillingDetailsItem.__typename ===
        "UpdateBillingDetailsItemPayloadSuccess"
      ) {
        return response.data.updateBillingDetailsItem;
      }

      if (
        response.data.updateBillingDetailsItem.__typename ===
        "UpdateBillingDetailsItemPayloadError"
      ) {
        throw getBillingDetailsError(response.data.updateBillingDetailsItem);
      }
    },

    async getGuessBillingDetailsItem(groupId) {
      const { data, errors } = await http.post("/nexus", {
        query: nexusGql`
          query guessBillingDetailsItem($input: GuessBillingDetailsItemInput!) {
            guessBillingDetailsItem(input: $input) {
              __typename
              ... on GuessBillingDetailsItemPayloadSuccess {
                guessedBillingDetailsItem {
                  firstName
                  lastName
                  taxID
                  vatID
                  countryOfIssue
                  placeOfBirth
                  cityOfBirth
                  countryOfBirth
                  otherCountryOfBirth
                  dateOfBirth
                  address {
                    addressLine1
                    addressLine2
                    city
                    country
                    zipCode
                  }
                  billingAddress {
                    addressLine1
                    addressLine2
                    city
                    country
                    zipCode
                  }
                  commercialFields {
                    type
                    companyName
                    vatID
                    taxID
                    countryOfIssue
                    companyRegistrationNumber
                  }
                }
              }
              ... on GuessBillingDetailsItemPayloadError {
                message
                name
              }
            }
          }
        `,
        variables: {
          input: {
            groupId,
          },
        },
      });

      if (errors && errors.length) {
        throw new Error(errors.map((e) => `${e.message}\n`));
      }

      if (
        data.guessBillingDetailsItem.__typename ===
        "GuessBillingDetailsItemPayloadSuccess"
      ) {
        return data.guessBillingDetailsItem;
      }

      if (
        data.guessBillingDetailsItem.__typename ===
        "GuessBillingDetailsItemPayloadError"
      ) {
        throw new Error(data.guessedBillingDetailsItem.name);
      }
    },
  };
};

export default factory;
