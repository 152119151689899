import React from "react";

const BurgerMenuIcon = () => (
  <svg
    className="AppHeaderUserMenu-BurgerMenuIcon"
    fill="none"
    viewBox="0 0 14 12"
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="12"
  >
    <rect
      className="AppHeaderUserMenu-BurgerMenuIcon-line"
      width="14"
      height="2"
      fill="#FFFFFF"
      rx="1"
    />
    <rect
      className="AppHeaderUserMenu-BurgerMenuIcon-line"
      width="14"
      height="2"
      y="5"
      fill="#FFFFFF"
      rx="1"
    />
    <rect
      className="AppHeaderUserMenu-BurgerMenuIcon-line"
      width="14"
      height="2"
      y="10"
      fill="#FFFFFF"
      rx="1"
    />
  </svg>
);

export default BurgerMenuIcon;
