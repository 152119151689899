import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { prepareModifiersForClassNames } from "../../utils/bem";

import "./Card.scss";

export const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  small: PropTypes.bool,
  noPadding: PropTypes.bool,
  noShadow: PropTypes.bool,
  centered: PropTypes.bool,
  WrapperComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  bordered: PropTypes.bool,
};

const Card = forwardRef(function Card(
  {
    children,
    className,
    small,
    noPadding,
    noShadow,
    centered,
    WrapperComponent = "div",
    bordered,
  },
  ref,
) {
  const classNameModifiers = {
    small,
    noPadding,
    noShadow,
    centered,
    bordered,
  };

  return (
    <WrapperComponent
      className={classnames(
        "Card",
        prepareModifiersForClassNames("Card", classNameModifiers),
        className,
      )}
      ref={ref}
      data-testid="Card"
    >
      {children}
    </WrapperComponent>
  );
});

Card.propTypes = propTypes;
export default Card;
