import React, { useCallback, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Manager, Popper, Reference } from "react-popper";
import { CSSTransition } from "react-transition-group";
import Drawer from "../Drawer/Drawer";

export const placements = {
  top: "top",
  bottom: "bottom",
  right: "right",
  left: "left",
  topStart: "top-start",
  topEnd: "top-end",
  bottomStart: "bottom-start",
  bottomEnd: "bottom-end",
  rightStart: "right-start",
  rightEnd: "right-end",
  leftStart: "left-start",
  leftEnd: "left-end",
  auto: "auto",
  autoStart: "auto-start",
  autoEnd: "auto-end",
};

const propTypes = {
  around: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  usePortal: PropTypes.bool,
  modifiers: PropTypes.arrayOf(PropTypes.object),
  placement: PropTypes.oneOf(Object.values(placements)),
  showInDrawer: PropTypes.bool,
  drawerHeightPercent: PropTypes.number,
  drawerChildrenClassname: PropTypes.string,
  className: PropTypes.string,
  innerClassname: PropTypes.string,
  overrideReferenceClassname: PropTypes.string,
  overrideInnerReferenceClassname: PropTypes.string,
  preventScroll: PropTypes.bool,
  hasAnimation: PropTypes.bool,
  hasArrow: PropTypes.bool,
  popoverContainerStyle: PropTypes.object,
  preventAutofocus: PropTypes.bool,
  zIndex: PropTypes.number,
};

const PopperInternal = ({
  around,
  children,
  show,
  onClose,
  usePortal = true,
  placement = placements.rightStart,
  modifiers,
  showInDrawer = false,
  drawerHeightPercent,
  drawerChildrenClassname,
  className,
  innerClassname,
  overrideReferenceClassname = "popper-reference",
  overrideInnerReferenceClassname = "popper-InnerReference",
  preventScroll = false,
  hasAnimation = true,
  hasArrow = true,
  preventAutofocus = false,
  popoverContainerStyle,
  zIndex = 11000,
}) => {
  useEffect(() => {
    const escapeHandler = (e) => {
      if (show && e.code === "Escape") {
        onClose();
      }
    };
    document.addEventListener("keydown", escapeHandler);
    return () => {
      document.removeEventListener("keydown", escapeHandler);
    };
  }, [show, onClose]);

  const portalEl = useRef();
  useEffect(() => {
    if (usePortal) {
      portalEl.current = document.createElement("div");
      document.body.appendChild(portalEl.current);
    }

    return () => {
      if (usePortal) {
        document.body.removeChild(portalEl.current);
      }
    };
  }, [usePortal]);

  const popperInnerContainerNode = useRef();
  const popperInnerReferenceNode = useRef();

  const handleClick = useCallback(
    (e) => {
      if (show) {
        if (
          popperInnerContainerNode.current.contains(e.target) ||
          popperInnerReferenceNode.current.contains(e.target)
        ) {
          return;
        }
        onClose();
      }
    },
    [show, onClose],
  );
  useEffect(() => {
    if (!showInDrawer) {
      document.addEventListener("mousedown", handleClick);
    }
    return () => {
      if (!showInDrawer) {
        document.removeEventListener("mousedown", handleClick);
      }
    };
  }, [showInDrawer, handleClick]);

  useEffect(() => {
    const popperInnerContainerNodeValue = popperInnerContainerNode.current;
    if (show && popperInnerContainerNodeValue && !preventAutofocus) {
      const [firstFocusable] = popperInnerContainerNodeValue.querySelectorAll(
        'button:not([tabindex="-1"]), [href]:not([tabindex="-1"]), input:not([tabindex="-1"]), select:not([tabindex="-1"]), textarea:not([tabindex="-1"]), [tabindex]:not([tabindex="-1"])',
      );
      if (firstFocusable) {
        firstFocusable.focus({ preventScroll });
      }
    }
  }, [preventScroll, show, preventAutofocus]);

  const popperRenderProp = (
    <Popper placement={placement} modifiers={modifiers}>
      {({ ref, style, arrowProps, placement }) => {
        const popoverContent = (
          <CSSTransition
            in={show}
            unmountOnExit
            classNames="popper"
            timeout={hasAnimation ? 350 : 0}
          >
            <div
              data-testid="popover-container"
              className={classnames("popper", className)}
              ref={ref}
              style={{
                ...style,
                zIndex,
                ...popoverContainerStyle,
              }}
              data-placement={placement}
            >
              <div
                ref={popperInnerContainerNode}
                className={classnames(innerClassname, "popper-inner")}
              >
                {children}
              </div>
              {hasArrow && (
                <div
                  className="popper__arrow"
                  ref={arrowProps.ref}
                  style={{
                    ...arrowProps.style,
                    transform: `${
                      arrowProps?.style?.transform || ""
                    } rotate(-45deg)`,
                  }}
                />
              )}
            </div>
          </CSSTransition>
        );

        let content = popoverContent;
        if (usePortal) {
          content = portalEl.current ? (
            ReactDOM.createPortal(popoverContent, portalEl.current)
          ) : (
            <React.Fragment />
          );
        }

        return content;
      }}
    </Popper>
  );

  // eslint-disable-next-line react/prop-types
  const referenceElementRender = ({ ref }) => (
    <div className={overrideReferenceClassname} ref={ref}>
      <div
        ref={popperInnerReferenceNode}
        className={overrideInnerReferenceClassname}
      >
        {around}
      </div>
    </div>
  );

  const referenceElementRef = useRef();
  return showInDrawer ? (
    <React.Fragment>
      {referenceElementRender({ ref: referenceElementRef })}
      <Drawer
        heightPercent={drawerHeightPercent}
        isVisible={show}
        onCloseDrawer={onClose}
        inPortal={usePortal}
        childrenClassName={drawerChildrenClassname}
        zIndex={zIndex}
      >
        {children}
      </Drawer>
    </React.Fragment>
  ) : (
    <Manager>
      <Reference>{referenceElementRender}</Reference>
      {popperRenderProp}
    </Manager>
  );
};

PopperInternal.propTypes = propTypes;

export default PopperInternal;
