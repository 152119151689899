/* eslint-disable import/prefer-default-export */

export const setLimitedInterval = (callback, timeout = 100, times = 1) => {
  let counter = 0;
  const intervalInstance = setInterval(() => {
    callback();
    counter += 1;
    if (counter === times) {
      clearInterval(intervalInstance);
    }
  }, timeout);
};
