import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import omit from "lodash/omit";
import { useI18n } from "../../spages/spa/context/I18nContext";
import { prepareModifiersForClassNames } from "../../utils/bem";
import CustomPhoneNumberInput from "../CustomPhoneNumberInput/CustomPhoneNumberInput";
import InputTooltip from "../InputTooltip/InputTooltip";

const propTypes = {
  label: PropTypes.string,
  labelFor: PropTypes.string,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  invalid: PropTypes.bool,
  prefix: PropTypes.node,
  suffix: PropTypes.node,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  borderless: PropTypes.bool,
  type: PropTypes.string,
  hideSpinners: PropTypes.bool,
  onBlur: PropTypes.func,
  labelTip: PropTypes.string,
  tooltipText: PropTypes.string,
  value: PropTypes.string,
};

const Input = ({
  label,
  labelFor,
  className,
  fullWidth,
  invalid,
  prefix,
  suffix,
  small,
  medium,
  borderless,
  type,
  hideSpinners,
  onBlur,
  labelTip,
  tooltipText,
  ...props
}) => {
  const [focused, setFocused] = useState(false);
  const inputRef = useRef(null);
  const { t } = useI18n();

  const propsToOmit = [
    "small",
    "medium",
    "prefix",
    "suffix",
    "labelTip",
    "tooltipText",
  ];

  const affixed = !!(prefix || suffix);

  const onAffixWrapperClick = () => {
    setFocused(true);
    inputRef.current?.focus();
  };

  const onInputFocus = () => {
    setFocused(true);
  };

  const onInputBlur = (evt) => {
    setFocused(false);
    if (onBlur) {
      onBlur(evt);
    }
  };

  const renderInput = () => {
    const modifiers = {
      fullWidth,
      invalid,
      borderless,
      hideSpinners,
    };

    if (type === "tel") {
      return (
        <CustomPhoneNumberInput
          focused={focused}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          value={props.value || ""}
          className={classnames(
            className,
            "Input",
            prepareModifiersForClassNames("Input", { ...modifiers, focused }),
          )}
          t={t}
          {...omit(props, propsToOmit)}
        />
      );
    }

    return (
      <input
        ref={inputRef}
        id={labelFor}
        className={classnames(
          className,
          "Input",
          prepareModifiersForClassNames("Input", modifiers),
        )}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        type={type}
        {...omit(props, propsToOmit)}
      />
    );
  };

  const renderPrefix = () => {
    return <div className="Input-prefix">{prefix}</div>;
  };

  const renderSuffix = () => {
    return <div className="Input-suffix">{suffix}</div>;
  };

  const renderAffixedInput = () => {
    return (
      <div
        className={classnames(
          "Input-affixWrapper",
          prepareModifiersForClassNames("Input-affixWrapper", {
            focused,
          }),
        )}
        onClick={onAffixWrapperClick}
      >
        {prefix ? renderPrefix() : null}
        <div className="Input-affixInputWrapper">{renderInput()}</div>
        {suffix ? renderSuffix() : null}
      </div>
    );
  };

  if (label) {
    return (
      <span
        className={classnames(
          "Input-wrapper",
          className,
          prepareModifiersForClassNames("Input-wrapper", {
            affixed,
            small,
            medium,
            borderless,
          }),
        )}
      >
        <label className="Input-label" htmlFor={labelFor}>
          {label}
          {labelTip && <span className="Input-label-tip">{labelTip}</span>}
          {tooltipText && (
            <InputTooltip
              containerClassName="Input-label-tooltipContainer"
              placement="top"
              tooltipContent={tooltipText}
            />
          )}
        </label>
        {prefix || suffix ? renderAffixedInput() : renderInput()}
      </span>
    );
  }

  return (
    <span
      className={classnames(
        "Input-wrapper",
        className,
        prepareModifiersForClassNames("Input-wrapper", {
          affixed,
          small,
          medium,
          borderless,
        }),
      )}
    >
      {prefix || suffix ? renderAffixedInput() : renderInput()}
    </span>
  );
};

Input.propTypes = propTypes;

export default Input;
