import React from "react";
import PropTypes from "prop-types";
import Drawer from "../../../Drawer/Drawer";
import LoggedInUser from "./LoggedInUser";
import NotLoggedInUser from "./NotLoggedInUser";

const AppHeaderDrawer = ({
  currentUrl,
  analyticsEvent,
  isOpen,
  lang,
  onClose,
  t,
  translationUrls,
  url,
  user,
}) => {
  return (
    <Drawer
      childrenClassName="AppHeaderDrawer-children"
      onCloseDrawer={onClose}
      isVisible={isOpen}
    >
      <div className="AppHeaderDrawer">
        {user ? (
          <LoggedInUser
            currentUrl={currentUrl}
            analyticsEvent={analyticsEvent}
            lang={lang}
            t={t}
            translationUrls={translationUrls}
            url={url}
            user={user}
          />
        ) : (
          <NotLoggedInUser
            currentUrl={currentUrl}
            analyticsEvent={analyticsEvent}
            lang={lang}
            t={t}
            translationUrls={translationUrls}
            url={url}
          />
        )}
      </div>
    </Drawer>
  );
};

AppHeaderDrawer.propTypes = {
  currentUrl: PropTypes.string,
  analyticsEvent: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  lang: PropTypes.string.isRequired,
  notifications: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  translationUrls: PropTypes.shape({
    en: PropTypes.string.isRequired,
    de: PropTypes.string.isRequired,
  }),
  url: PropTypes.func.isRequired,
  user: PropTypes.object,
};

export default AppHeaderDrawer;
