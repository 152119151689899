import cookie from "js-cookie";
import get from "lodash/get";
import { nanoid } from "nanoid";
import qs from "qs";
import store from "store2";
import { canUseDOM } from "./dom";

const getShortlistValue = () => {
  if (!store.get("default")) {
    return "no";
  }
  return store.get("default").length > 0 ? "yes" : "no";
};

const STUDENT_COOKIE = "student";

const getCustomerType = (isStudent, user) => {
  if (isStudent) {
    return "tenant-student";
  }
  if (user && user.landlord) {
    return "landlord";
  }
  return "tenant";
};

function cleanupLocalStorage() {
  const persistedDataLayer = store.local.get("persistedDataLayer");

  if (persistedDataLayer) {
    Object.keys(persistedDataLayer).forEach((key) => {
      if (persistedDataLayer[key]) {
        if (!persistedDataLayer[key].__timestamp__) {
          delete persistedDataLayer[key];
        } else {
          const twoDaysInMS = 1000 * 60 * 60 * 24 * 2;
          if (
            new Date() - new Date(persistedDataLayer[key].__timestamp__) >
            twoDaysInMS
          ) {
            delete persistedDataLayer[key];
          }
        }
      }
    });
  }

  // there is a possible edge case that this might remove new dataLayerKeys that are stored when this function is executing
  // for a bit of sanity the cleanup function will be called right after any update to the localStorage
  store.local.set("persistedDataLayer", {
    ...persistedDataLayer,
  });
}

const initialDataLayer = (pageType, { user, ...additionalProperties } = {}) => {
  if (!pageType) {
    throw new Error(
      "dataLayer.initialDataLayer: Please provide argument pageType",
    );
  }

  let isStudent = false;
  if (canUseDOM()) {
    isStudent = cookie.get(STUDENT_COOKIE) === "true";
  } else {
    isStudent = get(additionalProperties, "isStudent", false);
  }

  return [
    {
      loginState: user ? "loggedIn" : "loggedOut",
      customerId: user ? String(user._id) : "",
      pageType,
      customerType: getCustomerType(isStudent, user),
      shortlist: getShortlistValue(),
      ...additionalProperties,
    },
  ];
};

// A dataLayerKey can be used to persist dataLayer variables
// between pages
const generateDataLayerKey = () => {
  return nanoid();
};

// persistDataLayerVariables can be used to persist dataLayer variables that need
// to be present across multiple pages. Whenever the dataLayer changes, and you want
// to pick up certain variables from it on another page later, you should call
// persistDataLayerVariables(dataLayerKey, [...]) with the variables you want to pick back up.
// Pages that should populate the dataLayer from the persisted variables should be linked to
// with a query-parameters called `dataLayerKey`.
//
// # Example
// Assume we want to dataLayer[].preferred_price_range between ListingsPage and ListingDetailsPage.
// We will, when we set filters, generate a dataLayerKey. Then make sure all listings are linked with
// a query parameter `dataLayerKey`. When the user sets or changes the preferred price range, we will
// call `persistDataLayerVariables(dataLayerKey, ['preferred_price_range'])`.
// When the user clicks a listing (it will contain the dataLayerKey), src/spages/bootstrap-client.js
// will look for the query parameter dataLayerKey and resurrect the persisted dataLayer variables for
// that key from the localstorage and push them into the current dataLayer.
const persistDataLayerVariables = (dataLayerKey, dlVariableKeys) => {
  const dlVariables = window.dataLayer.reduce(
    (dlVariables, currentDlObject) => {
      Object.entries(currentDlObject).forEach(([key, value]) => {
        if (dlVariableKeys.includes(key)) {
          dlVariables[key] = value;
        }
      });

      return dlVariables;
    },
    {},
  );

  store.local.set("persistedDataLayer", {
    ...store.local.get("persistedDataLayer"),
    [dataLayerKey]: { ...dlVariables, __timestamp__: new Date() },
  });

  cleanupLocalStorage();
};

// populateDataLayerFromPersisted() gets called whenever the JS of a page kicks in,
// in src/spages/bootstrap-client.js. It searches the query params for an existing
// dataLayerKey and takes persisted dataLayer variables from the local storage based on the key.
const populateDataLayerFromPersisted = () => {
  const query = qs.parse(window.location.search.slice(1));

  const { dataLayerKey } = query;

  if (!dataLayerKey) {
    return;
  }

  const dlVariables =
    store.local.get("persistedDataLayer") &&
    store.local.get("persistedDataLayer")[dataLayerKey];

  if (!dlVariables) {
    return;
  }

  delete dlVariables.__timestamp__;

  try {
    window.dataLayer.push(dlVariables);
  } catch (err) {
    if (window && window.rollbar) {
      window.rollbar.error(err);
    }
  }
};

const pushToDataLayer = (data) => {
  if (!canUseDOM()) {
    return;
  }

  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  if (Array.isArray(data)) {
    window.dataLayer.push(...data);
  } else if (typeof data === "object") {
    window.dataLayer.push({ ...data });
  }
};

export default {
  initialDataLayer,
  generateDataLayerKey,
  persistDataLayerVariables,
  populateDataLayerFromPersisted,
  pushToDataLayer,
};
