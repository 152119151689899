import React from "react";
import PropTypes from "prop-types";
import analytics from "../../../analytics";
import AppHeader from "../../../components/AppHeader/AppHeader";
import { Button } from "../../../components/DesignSystem/Button/Button";
import EmailAddress from "../../../components/EmailAddress/EmailAddress";
import Footer from "../../../components/Footer/Footer";
import PhoneNumber from "../../../components/PhoneNumber/PhoneNumber";
import dataLayer from "../../../utils/data-layer";
import { urlMaker } from "../../../utils/url";
import { useI18n } from "../../spa/context/I18nContext";
import REGIONS from "./regionsData";

const propTypes = {
  user: PropTypes.object,
  error: PropTypes.object.isRequired,
  currentUrl: PropTypes.string.isRequired,
  hideTryAgainButton: PropTypes.bool,
};

const ErrorPage = ({ error, user, currentUrl, hideTryAgainButton }) => {
  React.useEffect(() => {
    if (error.name === "ResourceNotFoundError") {
      dataLayer.pushToDataLayer(
        dataLayer.initialDataLayer("404 Page", { user }),
      );
    }
  }, [error.name, user]);

  const { t, lang } = useI18n();

  const title =
    t(`spages.error.ErrorPage.${error.name}.title`) ||
    t("spages.error.ErrorPage.title");
  const subTitle =
    t(`spages.error.ErrorPage.${error.name}.subTitle`) ||
    t("spages.error.ErrorPage.subTitle");
  const url = urlMaker(lang);

  return (
    <div className="ErrorPage" data-testid="ErrorPage">
      <div className="ErrorPage-fullHeightContainer">
        <AppHeader
          currentUrl={currentUrl}
          lang={lang}
          t={t}
          theme="white"
          user={user}
          analyticsEvent={analytics.event}
        />
        <div className="ErrorPage-mainContainer">
          <h1 className="ErrorPage-title">{title}</h1>
          <h2 className="ErrorPage-subTitle" data-testid="ErrorPage-subTitle">
            {subTitle}
          </h2>

          <nav className="ErrorPage-actionBar">
            <span className="ErrorPage-actionBarItem">
              <PhoneNumber t={t} />
            </span>
            <span className="ErrorPage-actionBarItem">
              <EmailAddress t={t} />
            </span>
          </nav>
          {!hideTryAgainButton && (
            <nav className="ErrorPage-actionBar">
              <span className="ErrorPage-actionBarItem">
                <Button size="medium" colorVariant="primary" href={currentUrl}>
                  {t("spages.error.ErrorPage.tryAgain")}
                </Button>
              </span>
            </nav>
          )}
        </div>
      </div>
      <Footer url={url} regions={REGIONS} user={user} />
    </div>
  );
};

ErrorPage.propTypes = propTypes;

export default ErrorPage;
