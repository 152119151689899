export default () => {
  const inner = document.createElement("p");
  inner.style.width = "100%";
  inner.style.height = "200px";

  const outer = document.createElement("div");
  outer.style.position = "absolute";
  outer.style.top = "0px";
  outer.style.left = "0px";
  outer.style.visibility = "hidden";
  outer.style.width = "200px";
  outer.style.height = "150px";
  outer.style.overflow = "hidden";
  outer.appendChild(inner);

  document.body.appendChild(outer);
  const w1 = inner.offsetWidth;
  const h1 = inner.offsetHeight;

  outer.style.overflow = "scroll";
  let w2 = inner.offsetWidth;
  let h2 = inner.offsetHeight;
  if (w1 === w2) {
    w2 = outer.clientWidth;
  }

  if (h1 === h2) {
    h2 = outer.clientWidth;
  }

  document.body.removeChild(outer);

  return { width: w1 - w2, height: h1 - h2 };
};
