import React from "react";
import PropTypes from "prop-types";

const LogoutLink = ({ t, analyticsEvent, url, lang, currentUrl }) => {
  let logoutRoute = url("logout", { lang });
  if (currentUrl) {
    const parsedCurrentURL = new URL(currentUrl);
    const redirect = parsedCurrentURL.pathname + parsedCurrentURL.search;

    logoutRoute = url("logout", { lang }, { query: { redirect } });
  }

  return (
    <a
      href={logoutRoute}
      className="AppHeader-Link AppHeader-Link-logoutLink"
      data-testid="AppHeader-Link-logout"
      onClick={() => analyticsEvent("Menu", "menu_logout")}
    >
      <span className="AppHeader-Link-text">
        {t("components.AppHeader.logout")}
      </span>
    </a>
  );
};

LogoutLink.propTypes = {
  currentUrl: PropTypes.string,
  t: PropTypes.func.isRequired,
  analyticsEvent: PropTypes.func.isRequired,
  url: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};

export default LogoutLink;
