export const MAX_PRICE_CAP = 5000;

const algorithm = (histogramRange) =>
  histogramRange && histogramRange.length > 0
    ? {
        getPosition(value, min, max) {
          if (value <= min) {
            return 0;
          }
          if (value >= max) {
            return 100;
          }

          let currMin = min;
          let currMax = max;
          let currItemIndex = 0;
          const barWidth = 100 / histogramRange.length;
          for (let i = histogramRange.length - 1; i >= 0; i--) {
            if (value > histogramRange[i].price) {
              currMin = histogramRange[i].price;
              currMax = histogramRange[i + 1].price;
              currItemIndex = i;
              break;
            }
          }

          const currPosInBar = (value - currMin) / (currMax - currMin);
          const relativePos = barWidth * currPosInBar;
          const res = currItemIndex * barWidth + relativePos;
          return res;
        },
        getValue(pos, min, max) {
          if (pos === 0) {
            return min;
          }

          if (pos === 100) {
            return `${MAX_PRICE_CAP}+`;
          }

          const barWidth = 100 / histogramRange.length;
          const rangeIndex = Math.floor(pos / barWidth); // get the index of the current element in the range array
          const leftStart = rangeIndex * barWidth; // left % position of the current bar
          const positionInBar = (pos - leftStart) / barWidth; // slider % position inside current bar (0...1)
          const lastIndex = histogramRange.length - 1;
          const priceRange =
            rangeIndex < lastIndex
              ? histogramRange[rangeIndex + 1].price -
                histogramRange[rangeIndex].price
              : max - histogramRange[lastIndex].price; // Price range current bar represents
          // Calculate priceDifference: priceRange relative to positionInBar
          const priceDifference = priceRange * positionInBar;
          // Add priceDifference to current bar price, this is our result
          const currentPrice =
            histogramRange[rangeIndex].price + priceDifference;

          if (currentPrice >= MAX_PRICE_CAP) {
            return `${MAX_PRICE_CAP}+`;
          }
          return Math.round(currentPrice);
        },
      }
    : {
        getPosition(val) {
          return val;
        },
        getValue() {
          return 0;
        },
      };

export default algorithm;
