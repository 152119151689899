import promiseMemo from "promise-memoize";
import RedirectError from "../../../../../errors/redirectError";

const getRegionsMemo = promiseMemo(
  (api) => {
    return api.listings.getRegions();
  },
  { maxAge: 60000 },
);

// TODO: Investigate if the functions below needs to be removed
const confirmTransitionToTenantApproved = async ({ api, listingRequestId }) => {
  let transitionToTenantApprovedStatus = null;

  try {
    const transitionToTenantApprovedResponse =
      await api.bookingRequests.transitionToTenantApproved(listingRequestId);

    transitionToTenantApprovedStatus = {
      success:
        transitionToTenantApprovedResponse.transitionToTenantApproved.success,
    };
  } catch (error) {
    transitionToTenantApprovedStatus = { success: false, error };
  }

  return transitionToTenantApprovedStatus;
};

export default async ({ api, user, url, query }) => {
  const stays = await api.stays.getMyStayListForUser(user._id);

  const { transitionToTenantApproved } = query;

  if (transitionToTenantApproved) {
    await confirmTransitionToTenantApproved({
      api,
      listingRequestId: transitionToTenantApproved,
    });
  }

  if (stays?.length === 1 && stays[0]?.id) {
    const redirectTo = url(
      "requestPage",
      { stayId: stays[0].id },
      { query: { hideLobbyLink: true } },
    );

    throw new RedirectError({
      redirectTo,
      status: 307,
    });
  }

  const regions = await getRegionsMemo(api);

  return { stays, regions };
};
