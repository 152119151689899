import React from "react";
import PropTypes from "prop-types";
import { useI18n } from "../../spa/context/I18nContext";

const propTypes = {
  flashMessage: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

function FlashMessage({ flashMessage, onClose }) {
  const { t } = useI18n();
  const MESSAGES = {
    deleteUserDataConfirmed: t("flash.deleteUserDataConfirmed"),
    downloadUserDataConfirmed: t("flash.downloadUserDataConfirmed"),
  };

  return (
    <div className="FlashMessage">
      {MESSAGES[flashMessage]}
      <button className="FlashMessage-closeButton" onClick={onClose} />
    </div>
  );
}

FlashMessage.propTypes = propTypes;

export default FlashMessage;
