import React from "react";
import PropTypes from "prop-types";

const Wishlist = ({ width = 26, height = 26 }) => (
  <svg
    height={height}
    viewBox="0 0 26 26"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fill="#fff"
        d="M13 23l-1.45-1.32C6.4 17 3 13.93 3 10.15a5.45 5.45 0 015.5-5.5A6 6 0 0113 6.74a6 6 0 014.5-2.09 5.45 5.45 0 015.5 5.5c0 3.78-3.4 6.86-8.55 11.54z"
      />
      <path
        fill="#24272e"
        d="M13 24.35l-2.12-1.93C5.53 17.57 2 14.37 2 10.15a6.42 6.42 0 016.5-6.5A7 7 0 0113 5.33a7 7 0 014.5-1.68 6.42 6.42 0 016.5 6.5c0 4.21-3.51 7.41-8.83 12.24zM8.5 5.65a4.46 4.46 0 00-4.5 4.5c0 3.33 3.26 6.29 8.21 10.78l.79.72.78-.7c5-4.52 8.22-7.47 8.22-10.8a4.46 4.46 0 00-4.5-4.5 5 5 0 00-3.74 1.74l-.76.89-.76-.89A5 5 0 008.5 5.65z"
      />
    </g>
  </svg>
);

Wishlist.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default Wishlist;
