// All queries with this tag are linted against the schema on the endpoint
// `api/graphql`.
import gql from "./gql";

// Give different tag name to bypass linting against apollo graphql endpoint.
const nexusGql = gql;

const factory = (http) => {
  return {
    async getByFederalState(federalState) {
      const query = {
        query: nexusGql`
          query municipalities($federalState: STATES_OF_GERMANY_ENUM!) {
            municipalities(federalState: $federalState) {
              id
              name
              federalState
              bbox
            }
          }
        `,
        variables: { federalState },
      };

      const { data, errors } = await http.post("/nexus", query);

      if (errors && errors.length) {
        throw new Error(errors.map((e) => `${e.message}\n`));
      }

      return data.municipalities;
    },
  };
};

export default factory;
