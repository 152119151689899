import RedirectError from "../../../../../errors/redirectError";

export default async ({ api, user, params }) => {
  const { referrals } = await api.referrals.getInvitees();

  if (user && !user.landlord) {
    throw new RedirectError({
      redirectTo: `/${params.lang}`,
      status: 302,
    });
  }
  return {
    invitees: referrals,
  };
};
