import cookie from "js-cookie";
import getWFCONFIG from "../utils/WF_CONFIG";

const WF_CONFIG = getWFCONFIG();

function init() {
  if (!window.gtag) return;
  window.gtag("js", new Date());
  window.gtag("config", WF_CONFIG.GA4_MEASUREMENT_ID);
}

function pushUserIdToDataLayer({ user }) {
  function handler(event) {
    const consent = event.detail || window.CookieFirst?.consent;
    const userId = consent?.performance ? user?._id : undefined;

    window.dataLayer.push({
      uid: userId,
      userId,
      customerId: userId,
    });
  }

  return handler;
}

// This function can be used to register event handlers that will push
// the actual user id to the data layer if performance consent is given
function addEventListenersToPushUserIdToDataLayer({ user }) {
  window.addEventListener("cf_init", pushUserIdToDataLayer({ user }));
  window.addEventListener("cf_consent", pushUserIdToDataLayer({ user }));
}

// This function can be used to unregister event handlers responsible for
// pushing user id into the data layer
function removeEventListenersToPushUserIdToDataLayer({ user }) {
  window.removeEventListener("cf_init", pushUserIdToDataLayer({ user }));
  window.removeEventListener("cf_consent", pushUserIdToDataLayer({ user }));
}

// e.g. listing, click publish button, {listing._id}
function _event(category, action, label, value) {
  window.dataLayer.push({
    event: "gaEvent",
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
    eventValue: value,
  });
}

function category(category) {
  return _event.bind(exports, category);
}

function gaReady() {
  return window.gtag && typeof window.gtag === "function";
}

function onReady(cb) {
  const checkIfReady = window.setInterval(() => {
    if (!gaReady()) return;
    window.clearInterval(checkIfReady);
    cb();
  }, 0);
}

function getClientId(cb) {
  // The user might have an adblocker installed which might prevent the callback from being called
  // hence preventing tracking. In this case, we want to use the visitorId as a custom clientId.
  // Therefore, we will be able to perform some form of analytics on this user even if not fully
  onReady(() => {
    let hasDefaultedClientId = false;

    const checkIfClientIdIsAvailable = window.setTimeout(() => {
      hasDefaultedClientId = true;
      cb(cookie.get("visitorId"));
    }, 500);

    window.gtag(
      "get",
      WF_CONFIG.GA4_MEASUREMENT_ID,
      "client_id",
      (clientId) => {
        if (!hasDefaultedClientId) {
          clearTimeout(checkIfClientIdIsAvailable);

          cb(clientId);
        }
      },
    );
  });
}

function getSessionId(cb) {
  // The session_id must be sent as a number for attribution to work properly, hence we do the conversion here
  // The user might have an adblocker installed which might prevent the callback from being called
  // hence preventing tracking. In this case, we want to inject null into the callback
  // We don't necessarily care about attribution since this wouldn't work anyway even if we assign a random timestamp.
  onReady(() => {
    let hasDefaultedSessionId = false;

    const checkIfSessionIdIsAvailable = window.setTimeout(() => {
      hasDefaultedSessionId = true;
      cb(null);
    }, 500);

    window.gtag(
      "get",
      WF_CONFIG.GA4_MEASUREMENT_ID,
      "session_id",
      (sessionId) => {
        if (!hasDefaultedSessionId) {
          clearTimeout(checkIfSessionIdIsAvailable);

          cb(Number(sessionId));
        }
      },
    );
  });
}

function getClientIdPromise() {
  return new Promise((resolve) => {
    getClientId((clientId) => resolve(clientId));
  });
}

function getSessionIdPromise() {
  return new Promise((resolve) => {
    getSessionId((sessionId) => resolve(sessionId));
  });
}

export default {
  init,
  getClientIdPromise,
  getClientId,
  getSessionIdPromise,
  getSessionId,
  onReady,
  gaReady,
  category,
  event: _event,
  addEventListenersToPushUserIdToDataLayer,
  removeEventListenersToPushUserIdToDataLayer,
};
