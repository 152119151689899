import store from "store2";

export const validateAcquisitionChannel = (channel) => {
  if (typeof channel === "string") {
    return channel;
  }
  if (channel instanceof Array) {
    // we should only care about the first value
    return channel?.[0];
  }

  // Incase an invalid channel is passed then we default to null
  return null;
};

export const setAcquisitionChannel = (utmSource) => {
  const channel = validateAcquisitionChannel(utmSource ?? document.referrer);
  let acquisitionChannel;

  if (channel && channel.indexOf("wunderflats") === -1) {
    switch (channel) {
      case "immobilienscout24.de":
        acquisitionChannel = "IS24";
        break;
      case "immowelt.de":
        acquisitionChannel = "IMMOWELT";
        break;
      case "nestpick.com":
        acquisitionChannel = "NESTPICK";
        break;
      case "https://www.bing.com/":
        acquisitionChannel = "BING";
        break;
      case "https://www.google.com/":
        acquisitionChannel = "GOOGLE";
        break;
      case "https://duckduckgo.com/":
        acquisitionChannel = "DUCKDUCKGO";
        break;
      default:
        acquisitionChannel = channel;
        break;
    }
    store.local.set("acquisitionChannel", acquisitionChannel);
  }
};

export const getAcquisitionChannel = () => {
  return validateAcquisitionChannel(store.local.get("acquisitionChannel"));
};
