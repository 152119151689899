import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { isCurrentPage } from "../../../utils/url";
import WishlistIcon from "../../Icons/Wishlist";

const ShortlistLink = ({
  t,
  url,
  currentUrl,
  showIcon = false,
  expand = false,
  analyticsEvent,
}) => {
  const triggerGAEvent = () => {
    analyticsEvent("Menu", "menu_click_wishlist");
  };

  return (
    // TODO: Make this a <Link> instead of <a> when we are in the SPA
    <a
      href={url("shortlist")}
      className={classNames(
        "AppHeader-Link",
        {
          "AppHeader-Link--active": isCurrentPage(currentUrl, "shortlist"),
        },
        {
          "AppHeader-Link--expand": expand,
        },
      )}
      data-testid="AppHeader-Link-shortlist"
      onClick={triggerGAEvent}
    >
      {showIcon && (
        <span className="AppHeader-Link-icon">
          <WishlistIcon />
        </span>
      )}

      <span className="AppHeader-Link-text">{t("shortlist")}</span>
    </a>
  );
};

ShortlistLink.propTypes = {
  currentUrl: PropTypes.string,
  expand: PropTypes.bool,
  showIcon: PropTypes.bool,
  t: PropTypes.func.isRequired,
  url: PropTypes.func.isRequired,
  analyticsEvent: PropTypes.func.isRequired,
};

export default ShortlistLink;
