import nexusGql from "./gql";

const factory = (http) => {
  return {
    async listingRequestByIdWithLatestBookingOffer(id) {
      const { data, errors } = await http.post("/nexus", {
        query: nexusGql`
          query listingRequestById($id: ID!) {
            listingRequestById(listingRequestId: $id) {
              id
              selectedContract
              status {
                type
                reason
                setByLink {
                  id
                }
                setAt
              }
              type
              optionToExtend
              paidBy
              reasonForStay
              notes
              from
              to
              requestedAt
              adultsInformation {
                name
              }
              petsInformation {
                description
              }
              childrenInformation {
                type
              }
              workInformation {
                jobTitle
                companyName
                department
              }
              studyInformation {
                studyProgram
                universityName
              }
              listingLink {
                id
              }
              bookingRequestLink {
                id
              }
              bookingOffersLink {
                id
                cancellationReason
              }
              tenantStatus {
                type
                actionable
                reason
                explanation
              }

              latestBookingOffer {
                id
                type
                selectedContract
                from
                to
                price
                deposit
                bankAccountId
                depositBankAccountId
                firstMonthsRent
                lastMonthsRent
                tenantNames
                landlordFee
                additionalCosts {
                  price
                  description {
                    en
                    de
                  }
                }
                authorisedSignatory
                cleaningFeeFrequency
                contractType
                optionToExtend
                paymentReference
                reasonForStay
                reasonForStayExplanation
                landlordBillingAddress {
                  addressLine1
                  addressLine2
                  city
                  country
                  name
                  region
                  zipCode
                }
                landlord {
                  id
                  firstName
                  lastName
                  company {
                    name
                  }
                  authorisedSignatory {
                    name
                    email
                  }
                }
                tenant {
                  id
                  firstName
                  lastName
                  company {
                    name
                  }
                }
                isOnlineSigning
                stage {
                  type
                  setByLink {
                    id
                  }
                }
                landlordAddress {
                  name
                  addressLine1
                  addressLine2
                  city
                  country
                  zipCode
                }
                tenantAddress {
                  name
                  addressLine1
                  addressLine2
                  city
                  country
                  zipCode
                }
                listing {
                  id
                  billingDetailsItemId
                  address {
                    addressLine1
                    addressLine2
                    zipCode
                    city
                    country
                  }
                  area
                  beds
                  groupId
                  price
                  deposit
                  rooms
                  floor
                  title {
                    de
                    en
                  }
                  partOfGroup
                }
                locationInBuilding {
                  en
                  de
                }
                bookingRequest
                cancellationReason
                individualAgreements {
                  en
                  de
                }
                pets
                smoking
                petRules {
                  conditions {
                    en
                    de
                    fr
                    pl
                    pt
                    it
                    nl
                    ru
                    es
                  }
                  rule
                }
                smokingRules {
                  conditions {
                    en
                    de
                    fr
                    pl
                    pt
                    it
                    nl
                    ru
                    es
                  }
                  rule
                }
                numberOfKeys
                keysInformation {
                  en
                  de
                }
                finalCleaningFee
                timeOfFirstPayment
                timeOfDepositPayment
                houseRulesText {
                  en
                  de
                }
                duration {
                  days
                  months
                }
                tenantLink {
                  id
                }
                createdByLink {
                  id
                }
                landlordLink {
                  id
                }
                listingRequestLink {
                  id
                }
                yousignContract {
                  landlord {
                    id
                    status
                  }
                  tenant {
                    id
                    status
                  }
                }
              }
            }
          }
        `,
        variables: { id },
      });

      if (errors && errors.length) {
        throw new Error(errors.map((e) => `${e.message}\n`));
      }

      return data.listingRequestById;
    },
  };
};

export default factory;
