import { bookingRequests } from "@wunderflats/constants";
import dayjs from "dayjs";

const { STATUS } = bookingRequests;

function calculateFinalFrom(booking) {
  const bookingFrom = dayjs(booking.to.slice(0, 10)).add(1, "day");
  const today = dayjs.utc();
  return dayjs.utc(bookingFrom).isBefore(today)
    ? today.format("YYYY-MM-DD")
    : bookingFrom.format("YYYY-MM-DD");
}

export default async ({ api, params: { bookingId }, user }) => {
  const booking = await api.bookings.getTenantBookingById(bookingId);

  const bookingRequests = (
    await api.bookingRequests.getBookingRequestsForTenant(user._id, {
      fields: ["listingRequests", "status"],
    })
  ).filter((bookingRequest) => {
    return bookingRequest.status.type !== "LOST";
  });
  const allListingRequests = bookingRequests.reduce(
    (allListingRequests, bookingRequest) => {
      if (bookingRequest.listingRequests) {
        return [...allListingRequests, ...bookingRequest.listingRequests];
      }
      return allListingRequests;
    },
    [],
  );

  const latestListingRequest = allListingRequests.find((listingRequest) => {
    return listingRequest.id === booking.listingRequestId;
  });
  const { listing } = latestListingRequest;
  const extensionListingRequest = allListingRequests.find((listingRequest) => {
    return (
      booking.listing._id === listingRequest.listing._id &&
      listingRequest.type === "EXTENSION" &&
      listingRequest.dealStatus.type === STATUS.Open &&
      listingRequest?.status?.type !== "TENANT_WITHDREW" &&
      (String(listingRequest.from).substr(0, 10) ===
        String(booking.to).substr(0, 10) ||
        String(listingRequest.from).substr(0, 10) ===
          dayjs.utc(booking.to).add(1, "day").format("YYYY-MM-DD"))
    );
  });

  const finalFrom = calculateFinalFrom(booking);

  const blocks = await api.listings.getBlockedDates(listing._id, {
    from: dayjs.utc().add(1, "day").format("YYYY-MM-DD"),
    to: dayjs.utc().add(55, "years").format("YYYY-MM-DD"),
  });

  return {
    blocks,
    extensionFrom: finalFrom,
    isExtension: true,
    isListingRequested: extensionListingRequest,
    latestListingRequest,
    listing,
  };
};
