/* eslint-disable import/prefer-default-export */
import camelcase from "camelcase";
import flat from "flat";
import IntlMessageFormat from "intl-messageformat";
import { refreshLokaliseKeys } from "../components/AppHeader/LokaliseLink/LokaliseLink";
import { setLimitedInterval } from "./interval";
import getWfConfig from "./WF_CONFIG";

const WF_CONFIG = getWfConfig();

export const create = (s = {}, l = "undefined", { isLokaliseEnabled } = {}) => {
  const strings = s;
  const locale = l;

  const localizer = {
    lang: locale.substr(0, 2),

    render({ shouldExcludeStrings = false } = {}) {
      return JSON.stringify({
        strings: shouldExcludeStrings ? null : strings,
        locale,
      });
    },

    bootstrap(hydrant) {
      const data = JSON.parse(hydrant);
      localizer.init(data.strings, data.locale);
    },

    t(path, data, suppressNotFoundError) {
      if (isLokaliseEnabled && !data) {
        if (
          typeof window !== "undefined" &&
          window.document &&
          window.document.createElement
        ) {
          setLimitedInterval(
            () => {
              refreshLokaliseKeys();
            },
            1000,
            3,
          );
        }

        return `{.${path}.}`;
      }

      const string = strings[path];
      // If string contains a property name within curly braces and no data is passed
      //  we need to assign object with that property to data to avoid error
      if (
        !data &&
        typeof string === "string" &&
        string.match(/.*\{[^{}]+\}.*/)
      ) {
        const propertyName = string.split("{")[1].split("}")[0];
        data = { [propertyName]: undefined };
      }

      if (typeof string === "object") {
        throw new Error(`Localizer.t: Partial call (${path}) not allowed!`);
      }

      const msg = new IntlMessageFormat(string || "", localizer.lang);
      try {
        const translation = msg.format(camel(data || {}));

        if (translation === "") {
          // If you see this it means something regarding the locales broke.
          // Either there is a translation missing or the localizer was used
          // before it was rehydrated on an spage.
          if (WF_CONFIG.NODE_ENV === "development" && !suppressNotFoundError) {
            console.error(`No translation found for ${path}.`);
          }
        }

        return translation;
      } catch (err) {
        if (err.message.startsWith("A value must be provided for")) {
          return "";
        }

        throw err;
      }
    },

    keyExists(path, data) {
      return localizer.t(`${path}`, data) !== "";
    },
  };

  return localizer;
};

function camel(obj) {
  const flattened = flat(obj);
  return Object.keys(flattened).reduce((vals, prop) => {
    vals[camelcase(prop)] = flattened[prop];
    return vals;
  }, {});
}

const POSTFIXES_WHEN_ENDS_WITH_S = {
  en: "'",
  de: "",
};
const POSTFIXES = {
  en: "'s",
  de: "s",
};

export const possessive = ({ term, lang }) => {
  let postfix = "";
  if (term.endsWith("s")) {
    postfix = POSTFIXES_WHEN_ENDS_WITH_S[lang];
  } else {
    postfix = POSTFIXES[lang];
  }

  return term + postfix;
};

export const extractLanguageContent = ({ langContentDictionary, lang }) => {
  return langContentDictionary?.[lang] || langContentDictionary?.en || "";
};
