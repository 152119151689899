import gql from "./gql";

const factory = (http) => {
  return {
    async createListingDetailsViewedEvent(groupId, { referrer, collection }) {
      await http.post("/graphql", {
        query: gql`
          mutation (
            $groupId: String!
            $referrer: CreateListingDetailsViewedEventReferrer
            $collection: String
          ) {
            createListingDetailsViewedEvent(
              groupId: $groupId
              referrer: $referrer
              collection: $collection
            )
          }
        `,
        variables: {
          groupId,
          referrer,
          collection,
        },
      });
    },
    async createListingViewedEvent(
      groupId,
      context,
      similarToGroupId,
      { page, position, collection } = {},
    ) {
      await http.post("/graphql", {
        query: gql`
          mutation (
            $groupId: String!
            $context: ApartmentViewedContext!
            $similarToGroupId: String
            $page: Int
            $position: Int
            $collection: String
          ) {
            createListingViewedEvent(
              groupId: $groupId
              context: $context
              similarToGroupId: $similarToGroupId
              page: $page
              position: $position
              collection: $collection
            )
          }
        `,
        variables: {
          groupId,
          context,
          similarToGroupId,
          page: Number(page),
          position,
          collection,
        },
      });
    },
    async createListingShortlistedEvent(groupId) {
      await http.post("/graphql", {
        query: gql`
          mutation ($groupId: String!) {
            createListingShortlistedEvent(groupId: $groupId)
          }
        `,
        variables: {
          groupId,
        },
      });
    },
  };
};

export default factory;
