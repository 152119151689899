import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { isCurrentPage } from "../../../utils/url";

const HelpLink = ({ t, url, lang, currentUrl }) => (
  <a
    href={url("help")}
    className={classnames("AppHeader-Link", {
      "AppHeader-Link--active": isCurrentPage(currentUrl, {
        name: "help",
        lang,
      }),
    })}
    data-testid="AppHeader-Link-help"
  >
    <span className="AppHeader-Link-text">
      {t("components.AppHeader.help")}
    </span>
  </a>
);

HelpLink.propTypes = {
  t: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  url: PropTypes.func.isRequired,
  currentUrl: PropTypes.string,
};

export default HelpLink;
