import React from "react";
import PropTypes from "prop-types";
import "./Avatar.scss";

const Avatar = ({ name, notifications = 0 }) => {
  return (
    <div className="Avatar">
      <span className="Avatar-letter">{name ? name.charAt(0) : "-"}</span>
      {notifications > 0 && (
        <span className="Avatar-notifications">
          {notifications > 9 ? "9+" : notifications}
        </span>
      )}
    </div>
  );
};

Avatar.propTypes = {
  name: PropTypes.string,
  notifications: PropTypes.number,
};

export default Avatar;
