import faqsApiClient from "../../../../../api/faqsApi";

async function getFaqData(referralFaqsCategoryId, lang) {
  const faqsApi = faqsApiClient();

  try {
    const faqs = await faqsApi.getFaqs({ language: lang });

    const category = faqs.categories.find(
      (category) => category.id === referralFaqsCategoryId,
    );

    if (!category) return [];

    const subcategories = await faqs.subcategories.filter(
      (subcategory) => subcategory.category_id === category.id,
    );

    return subcategories.map((subcategory) => ({
      categoryTitle: subcategory.name,
      items: faqs.posts?.reduce((acc, post) => {
        if (post.folder_id === subcategory.id) {
          acc.push({
            title: post.title,
            content: post.description,
          });
        }

        return acc;
      }, []),
    }));
  } catch (error) {
    return [];
  }
}

async function getReferralName({ referralId, api }) {
  const { user: referralInfo } =
    await api.referrals.getUserInfoByReferralId(referralId);

  return referralInfo.firstName || referralInfo.lastName
    ? `${referralInfo.firstName}${
        referralInfo.lastName ? ` ${referralInfo.lastName}` : ""
      }`
    : referralInfo.email;
}

export default async ({ api, params }) => {
  const referralFaqsCategoryId = 75000004617;

  const [invitedBy, faqData] = await Promise.all([
    getReferralName({
      referralId: params.referralId,
      api,
    }),
    getFaqData(referralFaqsCategoryId, params.lang),
  ]);

  return {
    invitedBy,
    faqData,
  };
};
