import React from "react";
import PropTypes from "prop-types";

const CustomPhoneNumberInputLocked = ({
  t,
  isVerified,
  dialCode,
  phoneNumber,
  className,
}) => (
  <div className={className}>
    <div>
      <span className="CustomPhoneNumberInput-dialCode-locked">{dialCode}</span>
      <span>{phoneNumber}</span>
    </div>
    {isVerified && (
      <div className="CustomPhoneNumberInput-isVerified">
        <span className="CustomPhoneNumberInput-isVerified-tickMark" />
        <span className="CustomPhoneNumberInput-isVerified-text">
          {t("pages.UserBackend.SMSNotifications.verified")}
        </span>
      </div>
    )}
  </div>
);

CustomPhoneNumberInputLocked.propTypes = {
  t: PropTypes.func,
  isVerified: PropTypes.bool,
  dialCode: PropTypes.string,
  phoneNumber: PropTypes.string,
  className: PropTypes.string,
};

export default CustomPhoneNumberInputLocked;
