import React from "react";
import propTypes from "prop-types";
import { createRoot } from "react-dom/client";

import "./Overlay.scss";

class Overlay extends React.Component {
  portalElement = null;

  componentDidMount() {
    const { portalId = "overlay" } = this.props;
    let p = portalId && document.getElementById(portalId);
    if (!p) {
      p = document.createElement("div");
      p.id = portalId;
      p.className = `Overlay${
        this.props.className ? ` ${this.props.className}` : ""
      }`;
      document.body.appendChild(p);
    }
    this.portalElement = p;
    this.componentDidUpdate();
  }

  componentWillUnmount() {
    const { portalId = "overlay" } = this.props;

    if (document.getElementById(portalId)) {
      document.body.removeChild(this.portalElement);
    }
  }

  componentDidUpdate() {
    const { portalId, children, ...otherProps } = this.props;
    createRoot(this.portalElement).render(
      <div {...otherProps}>{children}</div>,
    );
  }

  render() {
    return null;
  }
}

Overlay.propTypes = {
  children: propTypes.node,
  className: propTypes.string,
  portalId: propTypes.string,
};

export default Overlay;
