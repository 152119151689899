import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";

const DeviceContext = React.createContext({
  isAPhone: false,
});

const providerPropTypes = {
  isAPhone: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export function DeviceContextProvider({ isAPhone, children }) {
  const contextValue = useMemo(() => ({ isAPhone }), [isAPhone]);

  return (
    <DeviceContext.Provider value={contextValue}>
      {children}
    </DeviceContext.Provider>
  );
}

DeviceContextProvider.propTypes = providerPropTypes;

export function useDevice() {
  return useContext(DeviceContext);
}
