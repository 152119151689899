import React, { createContext } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import { canUseDOM, isChild } from "../../utils/dom";
import RoundCloseSvg from "./RoundCloseSvg";

const propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.node,
  roundCloseButton: PropTypes.bool,
  bodyClassName: PropTypes.string,
  centered: PropTypes.bool,
};

export const ModalContext = createContext({});

class Modal extends React.Component {
  constructor(props) {
    super(props);
    if (canUseDOM()) {
      document.body.style.overflow = "hidden";
      this.popup = document.createElement("div");
      this.popup.className = classnames(this.props.className || "Modal", {
        "Modal--centered": props.centered,
      });
      this.close = this.close.bind(this);
      this.onClickAnywhere = this.onClickAnywhere.bind(this);
      this.onKeyDown = this.onKeyDown.bind(this);
      document.body.appendChild(this.popup);
    }
  }

  componentDidMount() {
    const browserHeight = window.innerHeight;
    const modalBody = document.querySelector(".Modal-body");
    // Add 30px for the space needed for the close button
    if (
      (modalBody && modalBody.offsetHeight + 30 > browserHeight) ||
      !this.props.centered
    ) {
      modalBody.classList.add("Modal-body--top-anchored");
    }
    document.addEventListener("keydown", this.onKeyDown, { capture: true });
    document.addEventListener("click", this.onClickAnywhere, {
      capture: true,
    });
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onClickAnywhere);
    document.body.style.overflow = "";
    document.removeEventListener("keydown", this.onKeyDown);
    if (this.popup.parentNode !== document.body) return;
    document.body.removeChild(this.popup);
  }

  onClickAnywhere(e) {
    const target = e.target;
    const path = (e.composedPath && e.composedPath()) || [];
    const hasCardModalInPath = path.some((el) => {
      if (el.className && typeof el.className === "string") {
        return (
          el.className.includes("CardModal") || el.className.includes("Drawer")
        );
      }
      return false;
    });

    const ModalBody = document.querySelector(".Modal-body");
    if (
      target === ModalBody ||
      isChild(ModalBody, target) ||
      hasCardModalInPath
    )
      return;
    this.close();
  }

  onKeyDown(e) {
    if (e.keyCode !== 27) return;
    this.close();
  }

  close() {
    document.removeEventListener("click", this.onClickAnywhere);
    if (this.props.onClose) this.props.onClose();
  }

  render() {
    const { roundCloseButton } = this.props;
    // Render a placeholder
    return canUseDOM() ? (
      ReactDOM.createPortal(
        <div className={classnames("Modal-body", this.props.bodyClassName)}>
          {!roundCloseButton && (
            <button
              onClick={this.close}
              className="btn-close"
              data-testid="Modal-close-button"
            >
              Close
            </button>
          )}
          {roundCloseButton && (
            <button
              onClick={this.close}
              className="Modal-roundCloseButton"
              type="button"
              data-testid="Modal-close-button"
            >
              <RoundCloseSvg />
            </button>
          )}
          <ModalContext.Provider value={{ closeModal: this.close }}>
            {this.props.children}
          </ModalContext.Provider>
        </div>,
        this.popup,
      )
    ) : (
      <div className="Modal-body" />
    );
  }
}

Modal.propTypes = propTypes;

export default Modal;
