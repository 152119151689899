import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
  color: PropTypes.string,
  speed: PropTypes.string,
};

const SvgSpinner = (props) => {
  const { className = "", size = 20, color = "#fff", speed = "slow" } = props;

  return (
    <svg
      style={{ width: `${size}px`, height: `${size}px` }}
      className={`SvgSpinner${className ? ` ${className}` : ""} ${speed}`}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={color}>
        <path
          d="m10 20a10 10 0 1 1 10-10 10 10 0 0 1 -10 10zm0-18a8 8 0 1 0 8 8 8 8 0 0 0 -8-8z"
          opacity=".35"
        />
        <path d="m19 11a1 1 0 0 1 -1-1 8 8 0 0 0 -8-8 1 1 0 0 1 0-2 10 10 0 0 1 10 10 1 1 0 0 1 -1 1z" />
        <path d="m10 20a10 10 0 0 1 -10-10 1 1 0 0 1 2 0 8 8 0 0 0 8 8 1 1 0 0 1 0 2z" />
      </g>
    </svg>
  );
};

SvgSpinner.propTypes = propTypes;

export default SvgSpinner;
