/**
 * This namespace is a private module inside of @wunderflats/dates
 *
 * __Using this outside of the module is not recommended.__
 *
 * __If you want to export these functions as part of the public API, please add them to the correct namespace.__
 * @namespace intersection
 */

/**
 * Method's of collides can be used to check if objects collide.
 * @namespace
 * @memberof intersection
 */
export const collides = {
  /**
   * ```One Dimensional(!)```
   *
   * Check if point is on line
   * @memberof intersection.collides
   * @param {Number} point
   * @param {Array.<Number>} line
   * @param {Number} line.0 starting point of the line
   * @param {Number} line.1 end point of the line
   * @return {boolean}
   */
  pointWithLine(point, line) {
    return line[0] <= point && line[1] >= point;
  },
  /**
   * ```One Dimensional(!)```
   *
   * Check if line collides with point
   * @memberof intersection.collides
   * @param {Array.<Number>} line
   * @param {Number} line.0 starting point of the line
   * @param {Number} line.1 end point of the line
   * @param {Number} point
   * @return {boolean}
   */
  lineWithPoint(line, point) {
    return collides.pointWithLine(point, line);
  },
  /**
   * ```One Dimensional(!)```
   *
   * Check, if two lines cross
   * last parameter can be used to include touching of lines
   *
   *
   * Collision of two lines (A and B):
   *
   * ¬(A.start > B.end ∧ A.end < B.start)
   * <=>
   * ¬(A.start > B.end) ∧ ¬(A.end < B.start)
   *
   * => (A.start <  B.end) ∧ (A.end > B.start)
   *
   * @memberof intersection.collides
   * @param {Array.<Number>} a firstLine
   * @param {Number} a.0 starting point of a
   * @param {Number} a.1 end point of a
   * @param {Array.<Number>} b secondLine
   * @param {Number} b.0 starting point of b
   * @param {Number} b.1 end point of b
   * @param {boolean} includeTouch
   * @default includeTouch false
   * @return {boolean}
   */
  lineWithLine(a, b, includeTouch) {
    if (includeTouch) {
      return a[0] <= b[1] && a[1] >= b[0];
    }
    return a[0] < b[1] && a[1] > b[0];
  },
};

/**
 * Method's of collision can be used to get exact collision values.
 * @namespace
 * @memberof intersection
 */
export const collision = {
  /**
   * ```One Dimensional(!)```
   *
   * Get overlap distance of two line segments
   * @memberof intersection.collision
   * @param {Array.<Number>} a firstLine
   * @param {Number} a.0 starting point of a
   * @param {Number} a.1 end point of a
   * @param {Array.<Number>} b secondLine
   * @param {Number} b.0 starting point of b
   * @param {Number} b.1 end point of b
   * @return {Number} overlapDistance
   */
  lineWithLine(a, b) {
    const A = a[0];
    const B = a[1];
    const C = b[0];
    const D = b[1];

    return Math.max(0, Math.min(B, D) - Math.max(A, C));
  },
};
