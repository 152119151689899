import React from "react";
import PropTypes from "prop-types";

const Bookings = ({ width = 26, height = 26 }) => (
  <svg
    height={height}
    viewBox="0 0 26 26"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path fill="#fff" d="M6.88 6.68h12.24v14.2H6.88z" />
      <path d="M14.67 2.37h-4a2 2 0 00-2 2h8a2 2 0 00-2-2zM20 5.33H6a4 4 0 00-4 4v8.55a4 4 0 004 4h14a4 4 0 004-4V9.33a4 4 0 00-4-4zM6.38 19.88H6a2 2 0 01-2-2V9.33a2 2 0 012-2h.38zm12.24 0H7.38V7.33h11.24zm3.38-2a2 2 0 01-2 2h-.38V7.33H20a2 2 0 012 2z" />
    </g>
  </svg>
);

Bookings.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default Bookings;
