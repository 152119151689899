/* eslint-disable react/prop-types */
import React from "react";

const Slider = () => {
  return (
    <svg
      width="26"
      height="10"
      viewBox="0 0 26 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="10.97"
        y="6.34375"
        width="15.0106"
        height="3.37155"
        rx="1.68578"
        transform="rotate(-25 10.97 6.34375)"
        fill="#AEBBC4"
      />
      <rect
        x="1.42493"
        y="0.000244141"
        width="15.0106"
        height="3.37155"
        rx="1.68578"
        transform="rotate(25 1.42493 0.000244141)"
        fill="#AEBBC4"
      />
    </svg>
  );
};

export default Slider;
