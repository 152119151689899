import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";

const CurrentBboxContext = React.createContext({
  bbox: null,
});

const providerPropTypes = {
  bbox: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export function CurrentBboxContextProvider({ bbox, children }) {
  const contextValue = useMemo(() => ({ bbox }), [bbox]);

  return (
    <CurrentBboxContext.Provider value={contextValue}>
      {children}
    </CurrentBboxContext.Provider>
  );
}

CurrentBboxContextProvider.propTypes = providerPropTypes;

export function useCurrentBbox() {
  return useContext(CurrentBboxContext);
}
