import React from "react";
import PropTypes from "prop-types";
import InviteLandlordIcon from "../../Icons/InviteLandlord";

const InviteLandlords = ({ showIcon = false, t, url, analyticsEvent }) => {
  return (
    <a
      href={url("inviteLandlord")}
      className="AppHeader-Link"
      data-testid="AppHeader-Link-inviteLandlord"
      onClick={() => analyticsEvent("Menu", "menu_invite")}
    >
      {showIcon && (
        <span className="AppHeader-Link-icon">
          <InviteLandlordIcon />
        </span>
      )}

      <span className="AppHeader-Link-text">
        {t("components.AppHeader.inviteLandlords")}
      </span>
    </a>
  );
};

InviteLandlords.propTypes = {
  showIcon: PropTypes.bool,
  t: PropTypes.func.isRequired,
  url: PropTypes.func.isRequired,
  user: PropTypes.object,
  analyticsEvent: PropTypes.func.isRequired,
};

export default InviteLandlords;
