import cookie from "js-cookie";
import { EXPERIMENT_COOKIE_KEY } from "../analytics/experiments";

export const userEntity = (user) => {
  const { _id = null, landlord = null, createdAt = null } = user ?? {};

  function getUserType() {
    // We need to generate the user type which can be LANDLORD | SALES | TENANT
    // However if _id is null then it means the user isn't probably signed in, hence the userType becomes UNKNOWN
    if (_id) {
      if (cookie.get("tiramisu") === "true") return "SALES";

      return landlord ? "LANDLORD" : "TENANT";
    }

    return "UNKNOWN";
  }

  function getExperimentData() {
    const experimentsCookie = cookie.get(EXPERIMENT_COOKIE_KEY);

    if (experimentsCookie) {
      return Object.entries(JSON.parse(experimentsCookie)).map((test) => ({
        name: test[0],
        variant: test[1],
      }));
    }

    return [];
  }

  return {
    schema: "iglu:com.wunderflats/entity_user/jsonschema/2-0-0",
    data: {
      visitor_id: cookie.get("visitorId"),
      user_id: _id,
      user_type: getUserType(),
      date_signed: createdAt,
      logged_in: Boolean(_id),
      experiment: getExperimentData(),
    },
  };
};

export const pageEntity = ({ language, pageName, url }) => {
  const languageDictionary = {
    en: "ENGLISH",
    de: "DEUTSCH",
    fr: "FRANCAIS",
  };

  return {
    schema: "iglu:com.wunderflats/entity_page/jsonschema/1-0-0",
    data: {
      page_url: url,
      page_language: languageDictionary[language] || "UNKNOWN",
      page_type: pageName,
    },
  };
};

export const listingEntity = ({ groupId }) => {
  return {
    schema: "iglu:com.wunderflats/entity_listing/jsonschema/1-0-0",
    data: {
      group_id: groupId,
    },
  };
};
