import React from "react";
import PropTypes from "prop-types";

const InviteLandlord = ({ width = 26, height = 26 }) => (
  <svg
    height={height}
    viewBox="0 0 26 26"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M22.38 3.62L11.09 14.91M22.55 3.45L11.41 14.59 3.45 9.82l19.1-6.37z"
        fill="#ffffff"
      />
      <path d="M23.25 2.75a1 1 0 00-1-.24L3.14 8.87a1 1 0 00-.2 1.81l7.74 4.64 4.64 7.74a1 1 0 00.86.49h.11a1 1 0 00.84-.68l6.36-19.1a1 1 0 00-.24-1.02zM5.83 10.08l13.54-4.51-7.89 7.89zm10.09 10.09l-3.38-5.64 7.89-7.9z" />
    </g>
  </svg>
);

InviteLandlord.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default InviteLandlord;
