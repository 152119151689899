import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";
import FranceFlag from "../../Icons/Flags/France";
import GermanyFlag from "../../Icons/Flags/Germany";
import RussianFlag from "../../Icons/Flags/Russia";
import UkraineFlag from "../../Icons/Flags/Ukraine";
import UnitedKingdomFlag from "../../Icons/Flags/UnitedKingdom";

const LanguageDropdown = ({ lang, translationUrls }) => {
  const [isOpen, setIsOpen] = useState(false);
  const languageDropdownRef = useRef();
  const toggleButtonRef = useRef();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const onClickOutsideHandler = useCallback(
    (e) => {
      if (
        isOpen &&
        !languageDropdownRef.current.contains(e.target) &&
        !toggleButtonRef.current.contains(e.target)
      ) {
        setIsOpen(false);
      }
    },
    [isOpen],
  );

  useEffect(() => {
    window.addEventListener("click", onClickOutsideHandler);

    return () => {
      window.removeEventListener("click", onClickOutsideHandler);
    };
  }, [onClickOutsideHandler]);

  const renderFlag = () => {
    switch (lang) {
      case "uk":
        return <UkraineFlag />;
      case "de":
        return <GermanyFlag />;
      case "fr":
        return <FranceFlag />;
      case "ru":
        return <RussianFlag />;
      default:
        return <UnitedKingdomFlag />;
    }
  };

  return (
    <div className="AppHeader-LanguageDropdown">
      <button
        className={classNames("AppHeader-LanguageDropdownButton", {
          "AppHeader-LanguageDropdownButton--active": isOpen,
        })}
        onClick={toggleDropdown}
        type="button"
        ref={toggleButtonRef}
      >
        {renderFlag()}
      </button>

      <CSSTransition
        in={isOpen}
        timeout={150}
        classNames="AppHeader-DropdownTransition"
        unmountOnExit
      >
        <ul
          className="AppHeader-LanguageDropdownList"
          ref={languageDropdownRef}
        >
          <li>
            <a
              href={translationUrls.en}
              className="AppHeader-LanguageDropdownLink"
              data-testid="AppHeader-LanguageDropdownLink-english"
            >
              <span className="AppHeader-LanguageDropdownLink-flag">
                <UnitedKingdomFlag />
              </span>
              <span className="AppHeader-LanguageDropdownLink-text">
                English
              </span>
            </a>
          </li>
          <li>
            <a
              href={translationUrls.de}
              className="AppHeader-LanguageDropdownLink"
              data-testid="AppHeader-LanguageDropdownLink-german"
            >
              <span className="AppHeader-LanguageDropdownLink-flag">
                <GermanyFlag />
              </span>
              <span
                className="AppHeader-LanguageDropdownLink-text"
                data-testid="LanguageDropdown-germanOption"
              >
                Deutsch
              </span>
            </a>
          </li>
          {translationUrls.fr && (
            <li>
              <a
                href={translationUrls.fr}
                className="AppHeader-LanguageDropdownLink"
                data-testid="AppHeader-LanguageDropdownLink-french"
              >
                <span className="AppHeader-LanguageDropdownLink-flag">
                  <FranceFlag />
                </span>
                <span
                  className="AppHeader-LanguageDropdownLink-text"
                  data-testid="LanguageDropdown-frenchOption"
                >
                  Français
                </span>
              </a>
            </li>
          )}

          <li>
            <a
              href={translationUrls.uk}
              className="AppHeader-LanguageDropdownLink"
              data-testid="AppHeader-LanguageDropdownLink-ukranian"
            >
              <span className="AppHeader-LanguageDropdownLink-flag">
                <UkraineFlag />
              </span>
              <span
                className="AppHeader-LanguageDropdownLink-text"
                data-testid="LanguageDropdown-ukraineOption"
              >
                Українська
              </span>
            </a>
          </li>
          <li>
            <a
              href={translationUrls.ru}
              className="AppHeader-LanguageDropdownLink"
              data-testid="AppHeader-LanguageDropdownLink-russian"
            >
              <span className="AppHeader-LanguageDropdownLink-flag">
                <RussianFlag />
              </span>
              <span
                className="AppHeader-LanguageDropdownLink-text"
                data-testid="LanguageDropdown-russianyarOption"
              >
                Pусский
              </span>
            </a>
          </li>
        </ul>
      </CSSTransition>
    </div>
  );
};

LanguageDropdown.propTypes = {
  lang: PropTypes.string.isRequired,
  translationUrls: PropTypes.shape({
    en: PropTypes.string.isRequired,
    de: PropTypes.string.isRequired,
    fr: PropTypes.string.isRequired,
    uk: PropTypes.string.isRequired,
    ru: PropTypes.string.isRequired,
  }).isRequired,
};

export default LanguageDropdown;
