import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./IconButton.scss";
import { Link } from "react-router-dom";

const commonPropTypes = {
  disabled: PropTypes.bool,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf([
    "small",
    "medium",
    "large",
    "extraLarge",
    "ultraLarge",
  ]),
  alignIconToTheRight: PropTypes.bool,
  iconBackgroundColor: PropTypes.string,
  iconBorderColor: PropTypes.string,
  icon: (props, _propName, componentName) => {
    if (!props.icon && !props.label) {
      return new Error(
        `One of props 'icon' or 'label' was not specified in '${componentName}'.`,
      );
    }
  },
  label: (props, _propName, componentName) => {
    if (!props.icon && !props.label) {
      return new Error(
        `One of props 'icon' or 'label' was not specified in '${componentName}'.`,
      );
    }
  },
  name: PropTypes.string.isRequired,
};

const onlyBasePropTypes = {
  element: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType])
    .isRequired,
  href: PropTypes.string,
  to: PropTypes.string,
};

const onlyLinkPropTypes = {
  href: PropTypes.string.isRequired,
};

const onlyInternalLinkPropTypes = {
  to: PropTypes.string.isRequired,
};

const IconBase = forwardRef(
  (
    {
      element: BaseElement,
      disabled,
      dataTestId,
      className,
      size = "medium",
      alignIconToTheRight = false,
      icon,
      label,
      iconBackgroundColor,
      iconBorderColor,
      name,
      ...props
    },
    ref,
  ) => {
    let iconSize;
    switch (size) {
      case "large":
        iconSize = 14;
        break;
      case "extraLarge":
        iconSize = 16;
        break;
      case "ultraLarge":
        iconSize = 18;
        break;
      default: // the default value for the medium size
        iconSize = 12;
        break;
    }

    return (
      <BaseElement
        disabled={disabled}
        className={classnames(className, "IconButton", `IconButton--${size}`, {
          "IconButton--alignIconToTheRight": alignIconToTheRight,
        })}
        data-testid={dataTestId}
        ref={ref}
        name={name}
        {...props}
      >
        {icon && (
          <span
            className="IconButton-icon"
            style={{ background: iconBackgroundColor, border: iconBorderColor }}
          >
            {React.cloneElement(icon, { width: iconSize, height: iconSize })}
          </span>
        )}
        {label && <span className="IconButton-label">{label}</span>}
      </BaseElement>
    );
  },
);

IconBase.propTypes = {
  ...commonPropTypes,
  ...onlyBasePropTypes,
};

export const IconButton = forwardRef((props, ref) => {
  return <IconBase element="button" ref={ref} {...props} />;
});

IconButton.propTypes = {
  ...commonPropTypes,
};

export const IconLink = forwardRef(({ className, disabled, ...props }, ref) => (
  <IconButton
    element="a"
    style={disabled ? { pointerEvents: "none" } : undefined}
    className={classnames(className, {
      "IconButton--disabled": disabled,
    })}
    ref={ref}
    {...props}
  />
));

IconLink.propTypes = {
  ...commonPropTypes,
  ...onlyLinkPropTypes,
};

export const IconClientSideLink = forwardRef(
  ({ className, disabled, ...props }, ref) => (
    <IconButton
      style={disabled ? { pointerEvents: "none" } : undefined}
      className={classnames(className, {
        "IconButton--disabled": disabled,
      })}
      element={Link}
      ref={ref}
      {...props}
    />
  ),
);

IconClientSideLink.propTypes = {
  ...commonPropTypes,
  ...onlyInternalLinkPropTypes,
};
