/* eslint-disable react/prop-types */
import React from "react";

import "./TopLoadingBar.scss";

const TopLoadingBar = ({ firstPageLoad, loading }) => {
  return (
    !firstPageLoad && (
      <div
        data-isloading={!!loading}
        className={`TopLoadingBar ${
          loading ? "TopLoadingBar-loading" : "TopLoadingBar-loaded"
        }`}
      />
    )
  );
};

export default TopLoadingBar;
