import gql from "./gql";

const factory = (http) => {
  return {
    async getFeatureFlags() {
      const {
        data: { featureFlags },
      } = await http.post("/graphql", {
        query: gql`
          query featureFlags {
            featureFlags(state: ENABLED, type: GLOBAL) {
              name
            }
          }
        `,
      });

      return featureFlags;
    },
  };
};

export default factory;
