const factory = (http) => {
  return {
    createGDPRRequest(payload) {
      return http.post("/gdpr/requests", payload);
    },
    confirmGDPRRequest(payload) {
      return http.post("/gdpr/requests/confirm", payload);
    },
  };
};

export default factory;
