import * as amplitude from "@amplitude/analytics-browser";
import getWFCONFIG from "../../utils/WF_CONFIG";

const WF_CONFIG = getWFCONFIG();

const initAmplitudeTracker = () => {
  amplitude.init(WF_CONFIG.AMPLITUDE_API_KEY, {
    serverZone: "EU",
    defaultTracking: true,
  });

  if (!window.CookieFirst?.consent?.functional) {
    amplitude.setOptOut(true);
  }
};

export default initAmplitudeTracker;
