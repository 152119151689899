import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useSwipeable } from "react-swipeable";
import { CSSTransition } from "react-transition-group";
import { disablePageScroll, enablePageScroll } from "scroll-lock";
import { canUseDOM } from "../../utils/dom";
import Slider from "../Icons/Slider";

function getScrollParent(node) {
  if (node == null) {
    return null;
  }

  if (node === document) {
    return node;
  }

  const nodeStyle = window.getComputedStyle(node);

  /*
    If the target element or any of its ancestors has overflowing content or a border,
    it's scrollHeight will be larger than its clientHeight and we will tag this element as a scroll container.
    That's why we also need to check if the ancestor node has overflow set explicitly.
  */

  if (
    node.scrollHeight > node.clientHeight &&
    (nodeStyle.overflow === "auto" ||
      nodeStyle.overflow === "scroll" ||
      nodeStyle.overflowY === "auto" ||
      nodeStyle.overflowY === "scroll")
  ) {
    return node;
  }

  return getScrollParent(node.parentNode);
}

const propTypes = {
  children: PropTypes.any.isRequired,
  onCloseDrawer: PropTypes.func.isRequired,
  isVisible: PropTypes.any,
  inPortal: PropTypes.bool,
  heightPercent: PropTypes.number,
  childrenClassName: PropTypes.string,
  className: PropTypes.string,
  onOpenedDrawer: PropTypes.func,
  dataTestId: PropTypes.string,
};

const Drawer = ({
  isVisible,
  children,
  onCloseDrawer,
  onOpenedDrawer,
  inPortal = true,
  heightPercent,
  childrenClassName,
  className,
  dataTestId,
  zIndex = 1100,
}) => {
  const innerCloseDrawer = (e) => {
    e.stopPropagation();
    onCloseDrawer();
  };
  const drawerInner = useRef();
  const drawerInput = useRef();

  useEffect(() => {
    const currentDrawerInput = drawerInput.current;
    if (isVisible) {
      disablePageScroll(currentDrawerInput);

      return () => {
        enablePageScroll(currentDrawerInput);
      };
    }
  }, [isVisible]);

  const isSwipeEnabled = useRef(true);
  const handlers = useSwipeable({
    onSwiped: ({ absY }) => {
      if (
        isSwipeEnabled.current &&
        absY / drawerInner.current.offsetHeight > 1 / 3
      ) {
        drawerInner.current.style = `transition: transform 250ms; transform: translateY(100%);`;
        return onCloseDrawer();
      }
      drawerInner.current.style = `transition: transform 250ms; transform: translateY(0%);`;
    },
    onSwiping: ({ deltaY, event }) => {
      const scrollParent = getScrollParent(event.target);
      const scrollable =
        !!scrollParent && drawerInner.current.contains(scrollParent);
      if (isSwipeEnabled.current && deltaY > 0) {
        drawerInner.current.style = `transform: translateY(${deltaY}px);`;
      } else if (deltaY < 0 && scrollable) {
        isSwipeEnabled.current = false;
        drawerInner.current.style = `transform: translateY(0px);`;
      } else {
        drawerInner.current.style = `transform: translateY(0px);`;
      }
    },
  });

  const handleOverlayClick = (e) => {
    drawerInner.current.style = `${
      heightPercent && `height: ${heightPercent}%;`
    }`;
    if (drawerInner.current.contains(e.target) && isVisible) {
      return;
    }
    e.stopPropagation();
    onCloseDrawer();
  };

  const portalRef = useRef(canUseDOM() && document.createElement("div"));

  useEffect(() => {
    const portalRefValue = portalRef.current;
    document.body.appendChild(portalRefValue);
    return () => {
      document.body.removeChild(portalRefValue);
    };
  }, []);

  const main = (
    <CSSTransition
      unmountOnExit
      in={isVisible}
      timeout={250}
      classNames="Drawer"
      onEntered={onOpenedDrawer}
    >
      <div
        className={classnames("Drawer", className)}
        data-testid={dataTestId}
        onClick={handleOverlayClick}
        style={{ zIndex }}
      >
        <div
          style={{ height: `${heightPercent}%` }}
          className="Drawer-inner"
          ref={drawerInner}
        >
          <div {...handlers} className="Drawer-sliderContainer">
            <div className="Drawer-slider" onClick={innerCloseDrawer}>
              <Slider />
            </div>
            <div
              onTouchStart={(e) => {
                const scrollParent = getScrollParent(e.target);
                const scrollable =
                  !!scrollParent && drawerInner.current.contains(scrollParent);
                if (scrollable && scrollParent.scrollTop !== 0) {
                  isSwipeEnabled.current = false;
                } else {
                  isSwipeEnabled.current = true;
                }
              }}
              ref={drawerInput}
              className={classnames(childrenClassName, "Drawer-children")}
            >
              {children}
            </div>
            <div className="Drawer-bottom" />
          </div>
        </div>
      </div>
    </CSSTransition>
  );

  let component = null;
  if (canUseDOM()) {
    component = inPortal ? createPortal(main, portalRef.current) : main;
  }

  return component;
};

Drawer.propTypes = propTypes;

export default Drawer;
