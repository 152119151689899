import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { prepareModifiersForClassNames } from "../../utils/bem";

function EmailAddress({ t, booking, subtle, transparent }) {
  const email = booking
    ? "booking@wunderflats.com"
    : t("contactDetails.support.email");
  const href = `mailto:${email}`;

  return (
    <a
      className={classnames(
        prepareModifiersForClassNames("EmailAddress", {
          subtle,
          transparent,
        }),
      )}
      href={href}
    >
      <i className="EmailAddress-emailIcon" />
      <span className="EmailAddress-emailAddress">{email}</span>
    </a>
  );
}

EmailAddress.propTypes = {
  t: PropTypes.func.isRequired,
  booking: PropTypes.bool,
  subtle: PropTypes.bool,
  transparent: PropTypes.bool,
};

export default EmailAddress;
