const factory = (http) => {
  return {
    invite(emails) {
      return http.post("/users/me/referrals", { emails });
    },

    getUserInfoByReferralId(referralId) {
      return http.get(`/users/by/referral/${referralId}`);
    },

    getInvitees() {
      return http.get("/users/me/referrals");
    },
  };
};

export default factory;
