import get from "lodash/get";
import pick from "lodash/pick";
import Rollbar from "rollbar";
import serialize from "serialize-javascript";
import getWFCONFIG from "../utils/WF_CONFIG";

const WF_CONFIG = getWFCONFIG();

export default (ROLLBAR_POST_SERVER_ITEM_ACCESS_TOKEN, active) => {
  if (!WF_CONFIG.NODE_ENV) {
    throw new Error("Missing environment variable: NODE_ENV");
  }

  if (!ROLLBAR_POST_SERVER_ITEM_ACCESS_TOKEN) {
    throw new Error(
      "error-reporter.js: Please provide ROLLBAR_POST_SERVER_ITEM_ACCESS_TOKEN",
    );
  }

  if (!active) {
    return (err, req, cb) => {
      console.info("Rollbar disabled, not reporting error.");
      console.error((err && err.stack) || err);

      if (cb) {
        cb();
      }
    };
  }

  const rollbar = new Rollbar({
    accessToken: WF_CONFIG.ROLLBAR_POST_SERVER_ITEM_ACCESS_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    verbose: true,
  });

  rollbar.configure({
    payload: { environment: WF_CONFIG.NODE_ENV },
  });

  return function reportError(err, req, cb) {
    // Don't report 404 errors
    if (err && err.name === "ResourceNotFoundError") {
      if (cb) {
        cb();
      }

      return;
    }

    console.info("Reporting error to Rollbar...");
    const sessionId = get(req, "cookies.sessionId", "");
    const visitorId = get(req, "cookies.visitorId", "");
    const user = get(req, "user", {});

    req.rollbar_person = {
      ...pick(user, ["_id", "landlord", "language", "gaClientId"]),
      sessionId,
      visitorId,
      id: req.user ? req.user._id : visitorId,
    };

    const reportCallback = (rollbarErr) => {
      if (rollbarErr) {
        console.error("Could not report error to Rollbar");
        console.error({ rollbarErr, req });
      } else {
        console.info("Finished reporting error to Rollbar");
      }

      if (cb) {
        cb();
      }
    };
    if (err instanceof Error) {
      rollbar.error(err, req, reportCallback);
    } else {
      rollbar.error(
        new Error(
          serialize(err, { unsafe: true, ignoreFunction: true, space: 2 }),
        ),
        req,
        reportCallback,
      );
    }
  };
};
