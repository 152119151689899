import { COOKIE_NAME as LISTING_FILTER_COOKIE } from "../../../../utils/default-listing-filter";
import {
  parseShareableToken,
  SHORTLIST_DATES_COOKIE,
  sortListings,
} from "../../../../utils/shortlist/shortlist";

const loaderWithKey = ({ api, token }) => {
  const loadData = async () => {
    const data = parseShareableToken(token);
    let shortlistData = [];
    let userName = "";
    let from;
    let to;

    if (Object.keys(data).length > 0) {
      const { groupIds } = data;
      userName = data.userName;
      from = data.from;
      to = data.to;
      const listings = await api.listings.getListingsByGroupIds({ groupIds });

      shortlistData = listings.map((item) => {
        return {
          isOnline: item.__typename === "PublicGroup",
          ...item,
        };
      });
    }

    return { userName, from, to, shortlistData };
  };

  return { loadData };
};

const loaderWithoutKey = ({ api, cookies, user }) => {
  const getDatesFromCookie = (cookie) => {
    const defaultValue = { fromDate: null, toDate: null };
    if (!cookie) return defaultValue;
    try {
      const filtersJson = JSON.parse(cookie);
      return { fromDate: filtersJson.from, toDate: filtersJson.to };
    } catch (e) {
      return defaultValue;
    }
  };

  const loadData = async () => {
    const { fromDate: from, toDate: to } = cookies[SHORTLIST_DATES_COOKIE]
      ? getDatesFromCookie(cookies[SHORTLIST_DATES_COOKIE])
      : getDatesFromCookie(cookies[LISTING_FILTER_COOKIE]);

    if (!user) {
      return { shortlistData: [], from, to };
    }

    // Check if "from" and "to" dates are set in listing filters cookie
    // If yes, check AV for the shortlisted listings
    // If not, only return online listings, which will be all the listings in the shortlist
    const data = await api.shortlist.getShortlist();
    const items = data.shortlist?.items || [];

    const shortlistData = items
      .map((item) => {
        return {
          addedAt: item.addedAt,
          isOnline: item.group.__typename === "PublicGroup",
          ...item.group,
        };
      })
      .sort(sortListings);

    return { shortlistData, from, to, userName: user.firstName };
  };

  return { loadData };
};

const shortlistLoaderFactory = ({ api, user, cookies, query }) => {
  const getLoader = () => {
    if (query.token) {
      return loaderWithKey({ api, token: query.token });
    }

    return loaderWithoutKey({ api, user, cookies });
  };

  return {
    getLoader,
  };
};

export default shortlistLoaderFactory;
