export default async ({ api, user, query }) => {
  const userId = query.userId || user._id;
  const idVerificationStatus = await api.users.getIdVerificationStatus();
  const solvencyInfoStatus = await api.users.getSolvencyInfoStatus(userId);
  const bankAccounts = await api.bankAccounts.getBankAccounts(userId);
  const { tenantRequestedDocuments } =
    await api.users.getDataForNotification(userId);

  const { billingDetailsListLink } = await api.users.getUserById(
    userId,
    user.permissions.includes("sales"),
  );

  return {
    bankAccounts,
    billingDetails: billingDetailsListLink,
    idVerificationStatus,
    solvencyInfoStatus,
    tenantRequestedDocuments,
  };
};

export const shouldAccountPageBeReloaded = () => {
  return false;
};
