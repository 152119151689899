import axios from "axios";
import { canUseDOM } from "./dom";
import { mapLegacyRegionToRegionSlug } from "./legacy-region-mapping";
import getWFCONFIG from "./WF_CONFIG";

const WF_CONFIG = getWFCONFIG();

export const createBboxFromCenter = ([lng, lat]) => [
  lng - 0.01,
  lat - 0.01,
  lng + 0.01,
  lat + 0.01,
];

export const createCityBboxFromCenter = ([lng, lat]) => [
  lng - 0.06,
  lat - 0.06,
  lng + 0.06,
  lat + 0.06,
];

export const getBboxStringFromArray = (bboxArr) => {
  const [left, bottom, right, top] = bboxArr;
  return `${left},${bottom},${right},${top}`;
};

export const getEncodedBboxStringFromLatLngArray = ([
  { lat, lng },
  { lat: lat2, lng: lng2 },
]) => getBboxStringFromArray([lng, lat, lng2, lat2]);

export const getBboxArrayFromString = (bboxStr) => {
  if (bboxStr) {
    const bboxCoordinatesSeparatorsRegex = /,|;|%2C|%3B/g;
    const bboxStrArr = bboxStr.split(bboxCoordinatesSeparatorsRegex);

    const bboxArr = bboxStrArr.map(Number);

    if (bboxArr.length !== 4 || bboxArr.some(isNaN)) {
      throw new Error("Couldn't extract coordinates from string");
    }

    const [left, bottom, right, top] = bboxArr;

    return [
      { lat: bottom, lng: left },
      { lat: top, lng: right },
    ];
  }
};

export const getCenterFromBboxString = (bbox) => {
  if (!bbox?.trim()) return;

  try {
    const [{ lat: bottom, lng: left }, { lat: top, lng: right }] =
      getBboxArrayFromString(bbox);

    return {
      lat: (bottom + top) / 2,
      lng: (left + right) / 2,
    };
  } catch (e) {
    console.error(e);
  }
};

export const fetchSuggestions = async ({
  text,
  lang = "en",
  bbox,
  version = 0,
}) => {
  const center = getCenterFromBboxString(bbox);
  const proximity = center ? `&proximity=${center.lng}%2C${center.lat}` : "";

  const lowercaseLang = lang.toLowerCase();
  const languages = lowercaseLang.split(/\s*,\s*/);
  const mainLang = languages[0];

  if (!lowercaseLang.includes("en")) {
    languages.push("en");
  }

  let accessToken;
  if (canUseDOM()) {
    accessToken = WF_CONFIG.GEOCODING_SEARCH_ACCESS_TOKEN;
  } else {
    accessToken = WF_CONFIG.GEOCODING_SEARCH_ACCESS_TOKEN_SSR;
  }

  const comma = "%2C";
  const suggestionCountries =
    WF_CONFIG.SUPPORTED_LISTING_COUNTRIES?.toLowerCase().split(",").join(comma);
  const suggestionTypes = [
    "postcode",
    "place",
    "locality",
    "neighborhood",
  ].join(comma);
  const suggestionLanguages = languages.join(comma);

  let suggestions = [];
  try {
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${text}.json?access_token=${accessToken}&language=${suggestionLanguages}&country=${suggestionCountries}&types=${suggestionTypes}&limit=10${proximity}`;
    const response = await axios.get(url);

    suggestions = response.data.features.map((feature) => {
      const text = feature[`text_${mainLang}`] || feature.text;
      const textEn = feature.text_en || feature.text;
      const {
        id,
        place_type: placeType,
        context: contextArr = [],
        bbox,
        center,
      } = feature;

      const regionPlace = contextArr.find((c) => c.id.startsWith("place."));
      const region = (
        regionPlace ? regionPlace[`text_${mainLang}`] || regionPlace.text : text
      ).toLowerCase();

      const context = contextArr
        .map((c) => c[`text_${mainLang}`] || c.text)
        .join(", ");

      const contextEn = contextArr.map((c) => c.text_en || c.text).join(", ");

      return {
        id,
        text,
        textEn,
        context,
        contextEn,
        region,
        placeType,
        bbox,
        center,
      };
    });
  } catch (e) {
    if (canUseDOM()) {
      window.rollbar?.error("Mapbox call error", e);
    } else {
      console.error("Mapbox call error", e);
    }
  }

  return { suggestions, version };
};

export const getGeocodingItemLabel = (selectedSuggestion) => {
  // selectedSuggestion shape:
  // {
  //     "id": string,
  //     "text": string,
  //     "textEn": string,
  //     "context": string,
  //     "contextEn": string,
  //     "region": string,
  //     "placeType": [
  //         "locality"
  //     ],
  //     "bbox": [],
  //     "center": []
  // }
  return `${selectedSuggestion.text}, ${selectedSuggestion.context}`;
};

export const getGeocodingItemEnglishLabel = (selectedSuggestion) => {
  return `${selectedSuggestion.textEn}, ${selectedSuggestion.contextEn}`;
};

export const getTranslatedLabel = (searchTextOrSlug) => {
  const words = searchTextOrSlug.split(/\s+/g);
  if (words.length > 1) {
    return searchTextOrSlug;
  }
};

export const convertRegionToApiCallFormat = (region) => {
  let regionOrBbox;
  if (!region.includes(";")) {
    if (!region.includes(",")) {
      regionOrBbox = mapLegacyRegionToRegionSlug(region);
    } else {
      const [lat1, lon1, lat2, lon2] = region.split(",");
      regionOrBbox = `${lat1},${lon1};${lat2},${lon2}`;
    }
  } else {
    regionOrBbox = region;
  }

  return regionOrBbox;
};
