import gql from "./gql";

// Give different tag name to bypass linting against apollo graphql endpoint.
const nexusGql = gql;

class YousignGetContractUrlError extends Error {
  constructor(message) {
    super();
    this.name = this.constructor.name;
    this.message = message;
  }
}

class UpdateYousignMemberStatusError extends Error {
  constructor(message) {
    super();
    this.name = this.constructor.name;
    this.message = message;
  }
}

const factory = (http) => {
  return {
    async getBookingOfferPreview(bookingOffer) {
      return http.post(
        `/booking-offers/rental-contracts/template-preview.pdf`,
        { ...bookingOffer },
        {
          responseType: "blob",
        },
      );
    },

    async signBookingOffer(bookingOfferId) {
      const {
        data: { decideForNextStatus },
      } = await http.post("/graphql", {
        query: gql`
          mutation ($id: ObjectId!) {
            decideForNextStatus(bookingOfferId: $id) {
              stage {
                type
              }
            }
          }
        `,
        variables: {
          id: bookingOfferId,
        },
      });

      return decideForNextStatus;
    },

    async reviewBookingOffer({ bookingOfferChanges }) {
      const {
        data: { reviewBookingOfferForLandlord },
      } = await http.post("/graphql", {
        query: gql`
          mutation reviewBookingOfferForLandlord(
            $input: ReviewBookingOfferForLandlordInput!
          ) {
            reviewBookingOfferForLandlord(input: $input) {
              __typename
              ... on ReviewBookingOfferForLandlordPayloadSuccess {
                newBookingOfferId
              }
              ... on ReviewBookingOfferForLandlordPayloadError {
                message
              }
            }
          }
        `,
        variables: {
          input: bookingOfferChanges,
        },
      });

      return reviewBookingOfferForLandlord;
    },

    async bookingOfferByIdNexus(id) {
      const { data, errors } = await http.post("/nexus", {
        query: nexusGql`
          query bookingOfferById($id: ID!) {
            bookingOfferById(bookingOfferId: $id) {
              id
              tenantLink {
                id
              }
              landlordLink {
                id
              }
              listing {
                id
                billingDetailsItemId
                address {
                  addressLine1
                  addressLine2
                  zipCode
                  city
                  country
                }
                area
                beds
                groupId
                price
                deposit
                rooms
                floor
                title {
                  de
                  en
                  fr
                  pl
                  pt
                  it
                  nl
                  ru
                  es
                }
                partOfGroup
              }
              listingRequestLink {
                id
              }
              createdByLink {
                id
              }
              tenantAddress {
                city
                zipCode
                addressLine1
                country
              }
              isOnlineSigning
              cancellationReason
              stage {
                type
                setByLink {
                  id
                }
              }

              from
              to
              price
              deposit
              bankAccountId
              depositBankAccountId
              firstMonthsRent
              lastMonthsRent
              tenantNames
              landlordFee
              additionalCosts {
                price
                description {
                  de
                  en
                  fr
                  pl
                  pt
                  it
                  nl
                  ru
                  es
                }
              }
              authorisedSignatory
              cleaningFeeFrequency
              contractType
              optionToExtend
              paymentReference
              reasonForStay
              reasonForStayExplanation
              landlordBillingAddress {
                addressLine1
                addressLine2
                city
                country
                name
                region
                zipCode
              }
              landlord {
                id
                firstName
                lastName
                company {
                  name
                }
                authorisedSignatory {
                  name
                  email
                }
              }
              tenant {
                id
                firstName
                lastName
                company {
                  name
                }
              }
              landlordAddress {
                name
                addressLine1
                addressLine2
                city
                country
                zipCode
              }
              tenantAddress {
                name
                addressLine1
                addressLine2
                city
                country
                zipCode
              }
              bookingRequest
              individualAgreements {
                de
                en
                fr
                pl
                pt
                it
                nl
                ru
                es
              }
              pets
              smoking
              petRules {
                conditions {
                  de
                  en
                  fr
                  pl
                  pt
                  it
                  nl
                  ru
                  es
                }
                rule
              }
              smokingRules {
                conditions {
                  de
                  en
                  fr
                  pl
                  pt
                  it
                  nl
                  ru
                  es
                }
                rule
              }
              numberOfKeys
              keysInformation {
                de
                en
                fr
                pl
                pt
                it
                nl
                ru
                es
              }
              finalCleaningFee
              timeOfFirstPayment
              timeOfDepositPayment
              houseRulesText {
                de
                en
                fr
                pl
                pt
                it
                nl
                ru
                es
              }
              duration {
                days
                months
              }
              yousignContract {
                landlord {
                  id
                  status
                }
                tenant {
                  id
                  status
                }
              }
            }
          }
        `,
        variables: { id },
      });

      if (errors && errors.length) {
        throw new Error(errors.map((e) => `${e.message}\n`));
      }

      return data.bookingOfferById;
    },

    async getYousignContractUrl({
      bookingOfferId,
      language,
      successRedirectUrl,
      errorRedirectUrl,
    }) {
      const { data, errors } = await http.post("/nexus", {
        query: nexusGql`
          query getYousignContractUrl(
            $id: ID!
            $language: String!
            $successRedirectUrl: String!
            $errorRedirectUrl: String!
          ) {
            getYousignContractUrl(
              bookingOfferId: $id
              language: $language
              successRedirectUrl: $successRedirectUrl
              errorRedirectUrl: $errorRedirectUrl
            ) {
              __typename
              ... on GetYousignContractUrlPayloadSuccess {
                success
                yousignContractUrl
              }
              ... on GetYousignContractUrlPayloadError {
                name
                message
              }
            }
          }
        `,
        variables: {
          id: bookingOfferId,
          language,
          successRedirectUrl,
          errorRedirectUrl,
        },
      });

      if (errors && errors.length > 0) {
        throw new YousignGetContractUrlError(JSON.stringify(errors));
      }

      const response = data.getYousignContractUrl;

      if (response.__typename === "GetYousignContractUrlPayloadError") {
        throw new YousignGetContractUrlError(
          JSON.stringify({
            name: response.name,
            message: response.message,
          }),
        );
      }

      return response.yousignContractUrl;
    },

    async updateYousignMemberStatus({ bookingOfferId }) {
      const { data, errors } = await http.post("/nexus", {
        query: nexusGql`
          mutation updateYousignMemberStatus($id: ID!) {
            updateYousignMemberStatus(bookingOfferId: $id) {
              __typename
              ... on UpdateYousignMemberStatusPayloadSuccess {
                success
              }
              ... on UpdateYousignMemberStatusPayloadError {
                name
                message
              }
            }
          }
        `,
        variables: {
          id: bookingOfferId,
        },
      });

      if (errors && errors.length > 0) {
        throw new UpdateYousignMemberStatusError(JSON.stringify(errors));
      }

      const response = data.updateYousignMemberStatus;
      if (response.__typename === "UpdateYousignMemberStatusPayloadError") {
        throw new UpdateYousignMemberStatusError({
          name: response.name,
          message: response.message,
        });
      }

      return true;
    },
  };
};

export default factory;
