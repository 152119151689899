import React from "react";

const Close = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      {...props}
    >
      <path
        transform="rotate(135 13.73 5.322)"
        fill="#24272E"
        d="M13.73 5.322h12v1.6h-12z"
      />
      <path
        transform="rotate(45 5.248 4.191)"
        fill="#24272E"
        d="M5.248 4.191h12v1.6h-12z"
      />
    </svg>
  );
};

export default Close;
