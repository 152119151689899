import { sanitizeUrl } from "@braintree/sanitize-url";
import { canUseDOM } from "./dom";

export default (url, origin) => {
  let currentOrigin;

  if (origin) {
    currentOrigin = origin;
  } else if (canUseDOM()) {
    currentOrigin = window?.location?.origin;
  } else {
    currentOrigin = "/";
  }

  if (/^http(s)?:\/\//.test(url) && !url.startsWith(currentOrigin)) {
    return "/";
  }

  const sanitizedURL = sanitizeUrl(url);

  // `sanitize-url` returns `about:blank` when the string
  // starts with either `javascript`, `data`, `vbscript` etc.
  // Since we want to keep users in the website, I'm casting it to "/".
  if (sanitizedURL === "about:blank") {
    return "/";
  }

  return sanitizedURL;
};
