import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { isCurrentPage } from "../../../utils/url";
import AccountIcon from "../../Icons/Account";

const MyAccountLink = ({
  t,
  url,
  currentUrl,
  expand = false,
  notifications = 0,
  showIcon = false,
  analyticsEvent,
}) => {
  return (
    <a
      href={url("myProfile")}
      className={classNames(
        "AppHeader-Link",
        {
          "AppHeader-Link--active": isCurrentPage(currentUrl, "myProfile"),
        },
        {
          "AppHeader-Link--expand": expand,
        },
      )}
      data-testid="AppHeader-Link-account"
      onClick={() => analyticsEvent("Menu", "menu_click_account")}
    >
      {showIcon && (
        <span className="AppHeader-Link-icon">
          <AccountIcon />
        </span>
      )}

      <span className="AppHeader-Link-text">
        {t("components.AppHeader.settings")}
      </span>

      {notifications > 0 && (
        <span className="AppHeader-Link-badge">
          {notifications > 9 ? "9+" : notifications}
        </span>
      )}
    </a>
  );
};

MyAccountLink.propTypes = {
  t: PropTypes.func.isRequired,
  url: PropTypes.func.isRequired,
  notifications: PropTypes.number,
  currentUrl: PropTypes.string,
  expand: PropTypes.bool,
  showIcon: PropTypes.bool,
  analyticsEvent: PropTypes.func.isRequired,
};

export default MyAccountLink;
