import dayjs from "dayjs";
import get from "lodash/get";
import datesUtils from "../utils/dates";
import { sanitizeQuery } from "../utils/filterUtils";
import { convertRegionToApiCallFormat } from "../utils/geocodingHelpers";
import { cityNameFromDeToEn } from "../utils/listing-utils";
import gql from "./gql";

const { sortByFromDateAscending } = datesUtils;

// Give different tag name to bypass linting against apollo graphql endpoint.
const nexusGql = gql;

const factory = (http) => {
  return {
    getUserListings(userId) {
      return http.get(`/users/${userId}/listings`).then((data) => data.items);
    },

    getRegions() {
      return http.get("/regions");
    },

    getPriceHistogram(slug, query = {}) {
      // remove empty fields from params
      const realQuery = {};
      Object.keys(query)
        .filter((key) => query[key] && key !== "minPrice" && key !== "maxPrice")
        .forEach((key) => (realQuery[key] = query[key]));

      const region = convertRegionToApiCallFormat(slug);

      return http.get(`/regions/${region}/prices`, {
        params: realQuery,
      });
    },

    getStructuredDataByRegionSlug(slug) {
      return http.get(`/regions/${slug}/structured-data`);
    },

    getListingsForRegion(region, page, _query) {
      // remove empty fields from params
      const realQuery = {};
      Object.keys(_query)
        .filter((p) => _query[p])
        .forEach((p) => (realQuery[p] = _query[p]));

      // convert euros to cents
      if (realQuery.maxPrice) {
        realQuery.maxPrice *= 100;
      }

      // convert euros to cents
      if (realQuery.minPrice) {
        realQuery.minPrice *= 100;
      }

      // Replace 'from' query param with 'availableFrom' query param
      // (we use 'from' in the front-end for vanity purposes)
      if (realQuery.from) {
        realQuery.availableFrom = realQuery.from;
        delete realQuery.from;
      }

      // Replace 'to' query param with 'availableTo' query param
      // (we use 'to' in the front-end for vanity purposes)
      if (realQuery.to) {
        realQuery.availableTo = realQuery.to;
        delete realQuery.to;
      }

      // api's pagination is 0-indexed
      if (page) {
        realQuery.page = page - 1;
      }

      realQuery.itemsPerPage = 30;

      const regionOrBbox = convertRegionToApiCallFormat(region);

      const cleanQuery = sanitizeQuery(realQuery);
      return http
        .get(`/regions/${regionOrBbox}/query`, { params: cleanQuery })
        .then((data) => data);
    },

    getListing(listingId) {
      return http.get(`/listings/${listingId}`).then((data) => data.listing);
    },

    async getAvailableListingsByGroupId({ groupId, from, to }) {
      return http
        .get(`/listings/g/${groupId}/availability`, {
          params: { from, to },
        })
        .then((data) => data.listings);
    },

    async getListingsByGroupIds({ groupIds }) {
      const { data, errors } = await http.post("/graphql", {
        query: gql`
          query publicListingsByGroupIds($groupIds: [String!]!) {
            publicListingsByGroupIds(groupIds: $groupIds) {
              __typename
              ... on PublicGroup {
                groupId
                _id
                minBookingDuration
                maxBookingDuration
                active
                deleted
                published
                sftValue
                restrictions {
                  onlyStudentsAllowed
                }
                listings {
                  availableFrom
                  availableTo
                  vacancies {
                    from
                    to
                  }
                }
                pictures {
                  imageId
                  urls {
                    large
                    medium
                    og
                    small
                    original
                    thumbnail
                  }
                }
                coverImage {
                  name
                  urls {
                    large
                    medium
                    og
                    small
                    original
                    thumbnail
                  }
                }
                title {
                  en
                  de
                }
                price
                address {
                  zipCode
                  districts
                  city
                  location {
                    coordinates
                  }
                }
                rooms
                beds
                accommodates
                area
                finalCleaningFee
                deposit
                partOfGroup
                labels
              }
              ... on OfflineGroup {
                groupId
                active
                sftValue
                deleted
                published
                address {
                  city
                  districts
                }
              }
            }
          }
        `,
        variables: {
          groupIds,
        },
      });
      if (errors && errors.length) {
        throw new Error(errors.map((e) => `${e.message}\n`));
      }
      return data.publicListingsByGroupIds;
    },

    deleteListing(listingId) {
      return http.delete(`/listings/${listingId}`);
    },

    getSimilarListings(listingId, filters) {
      const query = {};

      if (filters) {
        Object.keys(filters)
          .filter((p) => filters[p])
          .forEach((p) => (query[p] = filters[p]));
      }

      return http
        .get(`/listings/${listingId}/similar`, { params: query })
        .then((data) => data.items);
    },

    async createSimilarListingClickedEvent({ currentGroupId, clickedGroupId }) {
      const response = await http.post("/graphql", {
        query: gql`
          mutation ($input: CreateSimilarListingClickedEventInput!) {
            createSimilarListingClickedEvent(input: $input) {
              ... on CreateSimilarListingClickedEventSuccess {
                success
              }
              ... on CreateSimilarListingClickedEventError {
                code
                message
              }
            }
          }
        `,
        variables: {
          input: {
            currentGroupId,
            clickedGroupId,
          },
        },
      });

      return (
        response.data?.createSimilarListingClickedEvent?.success === true ||
        false
      );
    },

    getBlockedDates(listingId, duration) {
      const params = duration;

      return http
        .get(`/listings/${listingId}/blocked-dates`, { params })
        .then((data) => data.items);
    },

    updateListingV2(listingId, values) {
      return http
        .put(`/listings-v2/${listingId}`, values)
        .then((data) => data.listing);
    },

    updateMultiListingV2(groupId, values) {
      return http
        .put(`/listings-v2/g/${groupId}`, values)
        .then((data) => data.listing);
    },

    uploadListingImage(listingId, formData) {
      return http
        .post(`/listings/${listingId}/images`, formData, {
          timeout: 60 * 1000 * 60,
        })
        .then((data) => data.image);
    },

    uploadHouseRules(groupId, formData) {
      return http
        .post(
          `/listings/house-rules`,
          { groupId, ...formData },
          {
            timeout: 60 * 1000 * 60,
          },
        )
        .then(({ fileUrl, fileName }) => ({ fileUrl, fileName }));
    },

    getPriceSuggestion({
      zipCodes,
      city,
      areaSize,
      roomsAmount,
      countryCode = "DE",
    }) {
      return http.get("/listings/priceSuggestion", {
        params: {
          city,
          zipCodes,
          areaSize,
          roomsAmount,
          countryCode,
        },
      });
    },

    async syncFromIcs(listingId) {
      const { listing } = await http.put(`/listings/${listingId}/ics`);
      const icsBlocks = await this.getIcsBlocks(listingId);
      return { listing, icsBlocks };
    },

    async getIcsBlocks(listingId) {
      const { items } = await http.get(
        `/listings/${listingId}/blocks?ics=true`,
      );

      // sort ics blocks
      const now = dayjs.utc();
      const icsBlocks = items
        .filter(
          (block) => dayjs.utc(block.from) >= now || dayjs.utc(block.to) >= now,
        )
        .sort(sortByFromDateAscending);

      return icsBlocks;
    },

    getBlocks(listingId) {
      return http
        .get(`/listings/${listingId}/blocks`)
        .then((data) => data.items.sort(sortByFromDateAscending));
    },

    addBlock(listingId, duration) {
      return http
        .post(`/listings/${listingId}/blocks`, duration)
        .then((res) => res.block);
    },

    deleteBlock(blockId) {
      return http.delete(`/blocks/${blockId}`).then(() => ({}));
    },

    async canListingBeRequested({ listingId, from, to }) {
      const { data, errors } = await http.post("/nexus", {
        query: nexusGql`
          query canListingBeRequested(
            $listingId: ID!
            $from: Date!
            $to: Date!
          ) {
            canListingBeRequested(listingId: $listingId, from: $from, to: $to) {
              status
              reason
            }
          }
        `,
        variables: { listingId, from, to },
      });

      if (errors && errors.length) {
        throw new Error(errors.map((e) => `${e.message}\n`));
      }

      return data?.canListingBeRequested || { status: false, reason: null };
    },

    async getMinBookingDuration({ city, zipCode, country }) {
      // This query will fetch the minBookingDuration by country + (city or zipCode)

      const cityInEn = cityNameFromDeToEn(city);
      const { data, errors } = await http.post("/nexus", {
        query: nexusGql`
          query minBookingDurationByRegion(
            $city: String
            $zipCode: String
            $country: String
          ) {
            minBookingDurationByRegion(city: $city, zipCode: $zipCode, country: $country) {
              minBookingDuration
            }
          }
        `,
        variables: { city: cityInEn, zipCode, country },
      });

      if (errors && errors.length) {
        throw new Error(errors.map((e) => `${e.message}\n`));
      }

      return data?.minBookingDurationByRegion || { minBookingDuration: null };
    },

    async getMoveInDateSuggestionForListing({ listingId, from, to }) {
      const { data, errors } = await http.post("/nexus", {
        query: nexusGql`
          query getMoveInDateSuggestionForListing(
            $listingId: ID!
            $from: Date!
            $to: Date!
          ) {
            listingById(listingId: $listingId) {
              moveInDateSuggestion(from: $from, to: $to){
                isWithin
                minSuggestedFromDate
                maxSuggestedFromDate
              }
            }
          }
        `,
        variables: { listingId, from, to },
      });

      if (errors && errors.length) {
        throw new Error(errors.map((e) => `${e.message}\n`));
      }

      return data?.listingById || { moveInDateSuggestion: null };
    },

    async requestListing({
      acquisitionChannel = null,
      gaClientId,
      gaSessionId,
      listingId,
      payload,
      tenantId,
      filters,
      totalSearchResults,
      positionOnPage,
      listingWasOnPage,
    }) {
      const { data, errors } = await http.post("/graphql", {
        query: gql`
          mutation ($input: CreateListingRequestInput!) {
            createListingRequest(input: $input) {
              ... on CreateListingRequestPayloadSuccess {
                listingRequest {
                  from
                  id
                  bookingRequestId
                  requestedAt
                  reasonForStay
                  sftValue
                  workInformation {
                    employment
                    jobTitle
                    companyName
                    department
                  }
                  studyInformation {
                    studyProgram
                    universityName
                  }
                  listing {
                    price
                  }
                  status {
                    type
                  }
                  to
                }
                createdABookingRequest
              }
              ... on CreateListingRequestPayloadError {
                message
              }
            }
          }
        `,
        variables: {
          input: {
            acquisitionChannel,
            gaClientId,
            gaSessionId,
            listingId,
            payload,
            tenantId,
            filters,
            totalSearchResults,
            listingWasOnPage,
            positionOnPage,
          },
        },
      });
      if (errors && errors.length) {
        // We need to pass the raw array of errors so we can
        // construt proper error messages in the booking wizard

        const apolloErrors = errors.map((e) =>
          get(e, "extensions.exception", null),
        );
        if (apolloErrors) {
          throw apolloErrors;
        } else {
          throw errors.map((e) => get(e, "extensions", null));
        }
      }
      return data.createListingRequest;
    },
  };
};

export default factory;
