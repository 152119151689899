import {
  constructBasicSrcset,
  getAllImageUrls,
  listingBgSrc,
} from "./wunderflat-image";

const sections = {
  LIVING_DINING_ROOM: "livingDiningRoom",
  ROOM_BEDROOM: "roomBedroom",
  KITCHEN: "kitchen",
  BATHROOM: "bathroom",
  WORK_STUDY_ROOM: "workStudyRoom",
  HALL_CORRIDOR: "hallCorridor",
  WALK_IN_CLOSET: "walkInCloset",
  EMPTY_ROOM: "emptyRoom",
  TERRACE: "terrace",
  BALCONY: "balcony",
  STAIRS: "stairs",
  OUTDOOR_HOUSE: "outdoorHouse",
  OUTDOOR_BUILDING: "outdoorBuilding",
  GARDEN: "garden",
  PARKING: "parking",
  POOL: "swimmingPool",
  GYM: "gym",
  ENERGY_CERTIFICATE: "energyCertificate",
  FLOOR_PLAN: "floorPlanImage",
  WATER_VIEW: "other",
  MOUNTAIN_VIEW: "other",
  MAP_LOCATION: "other",
  NON_RELATED: "other",
  OTHER: "other",
  CELLAR: "cellar",
  DETAILS: "details",
  DINING_ROOM: "diningRoom",
  DOCUMENTS: "documentsImage",
  LAUNDRY_ROOM: "laundryRoom",
  LIVING_ROOM: "livingRoomImage",
  RECEPTION_LOBBY: "receptionLobby",
  STORAGE_PANTRY: "storagePantry",
  OFFICE: "office",
  TWO_D_FLOOR_PLAN: "2dFloorPlan",
  THREE_D_FLOOR_PLAN: "3dFloorPlan",
  UNCATEGORIZED: null,
};

export const translateSectionLabel = (section, t) => {
  const translatableSection = sections[section];

  if (!t || !translatableSection) {
    return null;
  }

  switch (translatableSection) {
    case sections.BATHROOM:
      return t("bathroom");

    case sections.WORK_STUDY_ROOM:
      return t("workStudyRoom");

    case sections.EMPTY_ROOM:
      return t("emptyRoom");

    case sections.ENERGY_CERTIFICATE:
      return t("energyCertificate");

    case sections.FLOOR_PLAN:
      return t("floorPlanImage");

    case sections.GARDEN:
      return t("garden");

    case sections.GYM:
      return t("gym");

    case sections.HALL_CORRIDOR:
      return t("hallCorridor");

    case sections.KITCHEN:
      return t("kitchen");

    case sections.LIVING_DINING_ROOM:
      return t("livingDiningRoom");

    case sections.OUTDOOR_BUILDING:
      return t("outdoorBuilding");

    case sections.OUTDOOR_HOUSE:
      return t("outdoorHouse");

    case sections.PARKING:
      return t("parking");

    case sections.POOL:
      return t("swimmingPool");

    case sections.ROOM_BEDROOM:
      return t("roomBedroom");

    case sections.STAIRS:
      return t("stairs");

    case sections.TERRACE:
      return t("terrace");

    case sections.BALCONY:
      return t("balcony");

    case sections.WALK_IN_CLOSET:
      return t("walkInCloset");

    case sections.CELLAR:
      return t("cellar");

    case sections.DETAILS:
      return t("details");

    case sections.DINING_ROOM:
      return t("diningRoom");

    case sections.DOCUMENTS:
      return t("documentsImage");

    case sections.LAUNDRY_ROOM:
      return t("laundryRoom");

    case sections.LIVING_ROOM:
      return t("livingRoomImage");

    case sections.RECEPTION_LOBBY:
      return t("receptionLobby");

    case sections.STORAGE_PANTRY:
      return t("storagePantry");

    case sections.OFFICE:
      return t("office");

    case sections.TWO_D_FLOOR_PLAN:
      return t("2dFloorPlan");

    case sections.THREE_D_FLOOR_PLAN:
      return t("3dFloorPlan");

    case sections.OTHER:
      return t("components.ImageGallery.caption.other");

    default:
      return null;
  }
};

export const willSectionRepeat = (section, sections) => {
  return !!sections.filter(
    (_section) => _section && _section.lastIndexOf(`${section}__`) !== -1,
  ).length;
};

export const extractCoverImageFromListing = (listing) => {
  const image = { src: listingBgSrc, alt: "Listing image" };
  if (listing?.coverImage?.urls?.original) {
    return {
      ...image,
      ...constructBasicSrcset(getAllImageUrls(listing?.coverImage)),
    };
  }

  if (listing?.coverImageLink?.originalURL) {
    return {
      ...image,
      ...constructBasicSrcset(getAllImageUrls(listing?.coverImageLink)),
    };
  }

  if (
    (listing?.images?.[0]?.originalURL ||
      listing?.images?.[0]?.urls?.original) &&
    !listing?.pictures
  ) {
    return {
      ...image,
      ...constructBasicSrcset(getAllImageUrls(listing?.images?.[0])),
    };
  }

  if (
    listing?.pictures?.[0]?.urls?.original ||
    listing?.pictures?.[0]?.originalURL
  ) {
    return {
      ...image,
      ...constructBasicSrcset(getAllImageUrls(listing?.pictures?.[0])),
    };
  }

  if (
    listing?.images?.[0]?.originalURL ||
    listing?.images?.[0]?.urls?.original
  ) {
    return {
      ...image,
      ...constructBasicSrcset(getAllImageUrls(listing?.images?.[0])),
    };
  }

  return image;
};

export const extractCoverImageFromBooking = (booking) => {
  return extractCoverImageFromListing(booking?.listing);
};

export const extractCoverImageFromListingRequest = (listingRequest) => {
  return extractCoverImageFromListing(listingRequest?.listing);
};
