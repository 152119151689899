import React from "react";
import PropTypes from "prop-types";

const French = ({ height = 22, width = 22 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width={width}
    height={height}
  >
    <mask
      height="512"
      width="512"
      y="0"
      x="0"
      maskUnits="userSpaceOnUse"
      id="mask0_9_1234"
      style={{ maskType: "alpha" }}
    >
      <circle id="circle58" fill="#C4C4C4" r="256" cy="256" cx="256" />
    </mask>
    <g id="g67" mask="url(#mask0_9_1234)">
      <path d="M 0,0 H 170 V 512 H 0 Z" fill="#0055a4" id="rect61" />
      <path d="M 170,0 H 342 V 512 H 170 Z" fill="#f0f0f0" id="rect63" />
      <path d="M 342,0 H 512 V 512 H 342 Z" fill="#ef4135" id="rect65" />
    </g>
  </svg>
);

French.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default French;
