import { ARTEFACTS_BASE_URL } from "../GCPArtefacts/static-artefacts";
import { countryDialCodes } from "./country-dial-codes";

const getFlagIcon = (countryCode) => {
  if (!countryCode) return "/public/images/planet.svg";

  return countryCode !== "US-CA"
    ? `${ARTEFACTS_BASE_URL}/flag-icons/${countryCode.toLowerCase()}.svg`
    : "/public/images/us-ca-flag.svg";
};

export { getFlagIcon, countryDialCodes };
