import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import * as defaultListingFilter from "../../../utils/default-listing-filter";
import { isCurrentPage } from "../../../utils/url";
import SearchIcon from "../../Icons/Search";

const DiscoverLink = ({
  t,
  url,
  lang,
  currentUrl,
  showIcon = false,
  analyticsEvent,
}) => {
  const [href, setHref] = useState("");

  useEffect(() => {
    const cookieParams = defaultListingFilter.get();
    if (Object.keys(cookieParams).length > 0) {
      const { region: cookieRegion, ...query } = cookieParams;
      setHref(url("listinglist", { region: cookieRegion }, { query }));
    }
  }, [url]);

  return (
    <a
      href={href}
      className={classnames("AppHeader-Link", {
        "AppHeader-Link--active": isCurrentPage(currentUrl, {
          name: "listinglist",
          lang,
        }),
      })}
      data-testid="AppHeader-Link-discover"
      onClick={() => {
        analyticsEvent("Menu", "menu_click_apartments");
      }}
    >
      {showIcon && (
        <span className="AppHeader-Link-icon">
          <SearchIcon />
        </span>
      )}

      <span className="AppHeader-Link-text">
        {t("components.AppHeader.apartments")}
      </span>
    </a>
  );
};

DiscoverLink.propTypes = {
  t: PropTypes.func,
  url: PropTypes.func.isRequired,
  lang: PropTypes.string,
  currentUrl: PropTypes.string,
  showIcon: PropTypes.bool,
  analyticsEvent: PropTypes.func.isRequired,
};

export default DiscoverLink;
