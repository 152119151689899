import faqsApiClient from "../../../../../api/faqsApi";
import { throwRedirectErrorIfUnsupportedLang } from "../utils/languageSupport";

export default async ({ params, url }) => {
  throwRedirectErrorIfUnsupportedLang({ params, lang: params.lang, url });

  const faqsApi = faqsApiClient();

  try {
    const faqs = await faqsApi.getFaqs({ language: params.lang });

    const post = faqs.posts.find((post) => String(post.id) === params.postId);

    const category = faqs.categories.find(
      (category) => category.id === post.category_id,
    );

    const subcategory = faqs.subcategories.find(
      (subcategory) => subcategory.id === post.folder_id,
    );

    return {
      category,
      subcategory,
      post,
    };
  } catch {
    return {
      category: null,
      subcategory: null,
      post: null,
    };
  }
};
