/**
 * Asynchronously retrieves a document from the API.
 *
 * @param {Object} options - The options for the API call.
 * @param {Object} options.api - The API object to use for the request.
 * @param {Object} options.params - The parameters for the API call.
 * @param {string} [options.params.lang="en"] - The language of the document to retrieve.
 *
 * @returns {Promise<Array>} A promise that resolves to an array containing the retrieved document.
 */
export default async ({ api }) => [
  await api.documents.getDocument({
    lang: "en",
    country: "en",
    name: "tos",
  }),
];
