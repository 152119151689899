import { MutationCache, QueryCache, QueryClient } from "@tanstack/react-query";
import getWFCONFIG from "../../utils/WF_CONFIG";

const WF_CONFIG = getWFCONFIG();

const sendToRollbarIfNeeded = (error) => {
  const handledErrors = [
    "CreateBillingDetailsItemPayloadError",
    "UpdateBillingDetailsItemPayloadError",
    "DeleteBillingDetailsItemPayloadError",
    "NotAllowedWhileImpersonatingError",
    "Not Found",
    "NotPermittedError",
    "ValidationError",
  ];

  if (
    !handledErrors.includes(error.name) &&
    !handledErrors.includes(error.response?.statusText)
  ) {
    window.rollbar?.error(error);
    if (WF_CONFIG.NODE_ENV === "development") {
      console.error("[React Query] Below Error has been reported to Rollbar");
    }
  } else if (WF_CONFIG.NODE_ENV === "development") {
    console.error(
      "[React Query] Below Error has been handled and NOT reported to Rollbar",
    );
  }
};

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: sendToRollbarIfNeeded,
  }),
  mutationCache: new MutationCache({
    onError: sendToRollbarIfNeeded,
  }),
});

export default queryClient;
