/* eslint-disable react/no-multi-comp */
import React from "react";
import PropTypes from "prop-types";
import qs from "qs";
import { useLocation } from "react-router-dom";
import ForgotPasswordModal from "../../components/ForgotPasswordModal/ForgotPasswordModal";
import { useAuth } from "../../contexts/AuthContext";
import sanitizeURL from "../../utils/sanitize-url";
import { useApi } from "../spa/context/ApiContext";
import { useI18n } from "../spa/context/I18nContext";
import FlashMessage from "./FlashMessage/FlashMessage";

const AuthContainer = ({ children, ...props }) => {
  const { t } = useI18n();
  const api = useApi();

  const getStateFromHash = (hash) => ({
    hash,
    showForgotPassword: hash === "forgotPassword",
    showSetPassword: hash === "setPassword",
  });

  const {
    user,
    forgotPassword,
    forgotPasswordLoading,
    forgotPasswordError,
    forgotPasswordSuccess,
  } = useAuth();
  const location = useLocation();

  const [state, setState] = React.useState({
    ...getStateFromHash(location.hash),
    flash: props.query.flash || "",
  });

  React.useEffect(() => {
    props.analytics.addEventListenersToPushUserIdToDataLayer({ user });

    return () => {
      props.analytics.removeEventListenersToPushUserIdToDataLayer({ user });
    };
  }, [user, props.analytics, api]);

  const { flash } = state;

  const removeDanglingSlash = (text) =>
    text.endsWith("/") ? text.slice(0, -1) : text;

  const removeHashFromURL = () => {
    window.location.hash = "";
    history.pushState(
      "",
      document.title,
      `${window.location.pathname}${window.location.search}`,
    );
  };

  const closeModals = (ignoreRedirect = false) => {
    const query = qs.parse(window.location.search.slice(1));
    if (query.redirect && !ignoreRedirect) {
      const { redirect, ...rest } = query;

      window.location.href = sanitizeURL(
        `${removeDanglingSlash(redirect)}${
          Object.keys(rest).length
            ? `?${qs.stringify(rest, { encode: true })}`
            : ""
        }`,
      );
    } else {
      removeHashFromURL();
    }
  };

  const onForgotPassword = (payload) => {
    return forgotPassword(payload).catch(() => {});
  };

  const hideForgotPassword = () => {
    const event = props.analytics.category("global");
    event("hide forgot password");

    closeModals(true);

    if (props.onHideModal) {
      props.onHideModal();
    }
  };

  const hideFlashMessage = () => {
    const query = qs.parse(window.location.search.slice(1));
    const queryWithoutFlash = qs.stringify({ ...query, flash: undefined });
    const hash = location.hash;

    window.history.replaceState(
      null,
      undefined,
      window.location.pathname +
        (queryWithoutFlash ? `?${queryWithoutFlash}` : "") +
        (hash ? `#${hash}` : ""),
    );

    setState({ ...state, flash: "" });
  };

  return (
    <div className="root">
      {!!flash && (
        <FlashMessage flashMessage={flash} onClose={hideFlashMessage} />
      )}

      {children}

      {state.showForgotPassword && (
        <ForgotPasswordModal
          t={t}
          success={forgotPasswordSuccess}
          loading={forgotPasswordLoading}
          error={forgotPasswordError}
          onClose={hideForgotPassword}
          onSubmit={onForgotPassword}
          url={props.url}
        />
      )}
    </div>
  );
};

AuthContainer.propTypes = {
  query: PropTypes.object.isRequired,
  analytics: PropTypes.shape({
    category: PropTypes.func.isRequired,
    event: PropTypes.func.isRequired,
    addEventListenersToPushUserIdToDataLayer: PropTypes.func.isRequired,
    removeEventListenersToPushUserIdToDataLayer: PropTypes.func.isRequired,
  }).isRequired,
  children: PropTypes.node,
  user: PropTypes.object,
  url: PropTypes.func.isRequired,
  onHideModal: PropTypes.func,
};

export default AuthContainer;
