import faqsApiClient from "../../../../../api/faqsApi";
import { throwRedirectErrorIfUnsupportedLang } from "../utils/languageSupport";

export default async ({ params, url }) => {
  throwRedirectErrorIfUnsupportedLang({ params, lang: params.lang, url });

  const faqsApi = faqsApiClient();

  try {
    const faqs = await faqsApi.getFaqs({ language: params.lang });

    const subcategory = faqs.subcategories.find(
      (subcategory) => String(subcategory.id) === params.subcategoryId,
    );

    const posts = faqs.posts.filter(
      (post) => post.folder_id === subcategory.id,
    );

    const category = faqs.categories.find(
      (category) => category.id === subcategory.category_id,
    );

    return {
      category,
      subcategory,
      posts,
    };
  } catch {
    return {
      category: null,
      subcategory: null,
      posts: [],
    };
  }
};
