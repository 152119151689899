import promiseMemo from "promise-memoize";
const getRegionsMemo = promiseMemo(
  (api) => {
    return api.listings.getRegions();
  },
  { maxAge: 60000 },
);

export default async ({ api, params }) => {
  const regions = await getRegionsMemo(api);
  const stay = await api.stays.getMyStayForId(params.stayId);
  const idVerificationStatus = await api.users.getIdVerificationStatus();
  stay.listingRequests = stay.listingRequestsLink;

  return {
    stay,
    regions,
    idVerificationStatus,
  };
};

export const shouldRequestPageReload = (prev, curr) => {
  if (prev.params.section !== curr.params.section) {
    return true;
  }

  return false;
};
