import omit from "lodash/omit";
import { matchPath } from "react-router-dom";

export const getMatchedRoute = ({ path, routes }) => {
  let res;

  for (const iterator of routes) {
    const match = matchPath(path, iterator);
    if (match && match.params) {
      res = {
        ...iterator,
        path,
        params: match.params,
      };
      break;
    }
  }

  return res;
};

export const getPageData = async ({
  matchedRoute,
  query,
  api,
  user,
  cookies,
  originalUrl,
  url,
  isAPhone,
  isMobile,
  featureFlags,
}) => {
  // Remove sensitive data from cookies such as authToken
  const sanitizedCookies = omit(cookies, ["authToken"]);

  return matchedRoute.getData
    ? matchedRoute.getData({
        query,
        params: matchedRoute.params,
        api,
        user,
        cookies: sanitizedCookies,
        originalUrl,
        url,
        isAPhone,
        isMobile,
        featureFlags,
      })
    : {};
};
