import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import useMedia from "../../../hooks/useMedia";
import AppHeaderDesktopUserMenu from "./AppHeaderDesktopUserMenu/AppHeaderDesktopUserMenu";
import AppHeaderDrawer from "./AppHeaderDrawer/AppHeaderDrawer";
import ToggleButton from "./ToggleButton/ToggleButton";

const AppHeaderUserMenu = ({
  currentUrl,
  analyticsEvent,
  lang,
  t,
  translationUrls,
  url,
  user,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const userMenuRef = useRef();
  const toggleButtonRef = useRef();

  const isSmallScreen = useMedia("(max-width: 480px)");

  const toggleMenu = () => {
    if (!isMenuOpen) {
      analyticsEvent("Menu", "menu_open");
    }
    setIsMenuOpen(!isMenuOpen);
  };

  const onDrawerClose = () => {
    setIsMenuOpen(false);
  };

  const onClickOutsideHandler = useCallback(
    (e) => {
      if (
        isMenuOpen &&
        !isSmallScreen &&
        !toggleButtonRef.current.contains(e.target) &&
        !userMenuRef.current.contains(e.target)
      ) {
        setIsMenuOpen(false);
      }
    },

    [isMenuOpen, isSmallScreen],
  );

  useEffect(() => {
    window.addEventListener("click", onClickOutsideHandler);

    return () => {
      window.removeEventListener("click", onClickOutsideHandler);
    };
  }, [onClickOutsideHandler]);

  useEffect(() => {
    if (isMenuOpen && !user) {
      setIsMenuOpen(false);
    }
    // Disabled hooks linting because I wanted to get the lint-rule
    // in without changing the semantics of existing code.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div className="AppHeaderUserMenu">
      <ToggleButton
        className={classNames({
          "AppHeaderUserMenu-ToggleButton--active": isMenuOpen,
          "AppHeaderUserMenu-ToggleButton--userIsLoggedIn": user,
        })}
        onClick={toggleMenu}
        user={user}
        ref={toggleButtonRef}
      />

      <AppHeaderDesktopUserMenu
        currentUrl={currentUrl}
        analyticsEvent={analyticsEvent}
        isOpen={isMenuOpen && !isSmallScreen}
        lang={lang}
        ref={userMenuRef}
        t={t}
        url={url}
        user={user}
      />

      <AppHeaderDrawer
        currentUrl={currentUrl}
        analyticsEvent={analyticsEvent}
        isOpen={isMenuOpen && isSmallScreen}
        lang={lang}
        onClose={onDrawerClose}
        t={t}
        translationUrls={translationUrls}
        url={url}
        user={user}
      />
    </div>
  );
};

AppHeaderUserMenu.propTypes = {
  currentUrl: PropTypes.string,
  analyticsEvent: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  translationUrls: PropTypes.shape({
    de: PropTypes.string.isRequired,
    en: PropTypes.string.isRequired,
  }),
  url: PropTypes.func.isRequired,
  user: PropTypes.object,
};

export default AppHeaderUserMenu;
