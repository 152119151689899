import gql from "./gql";

const factory = (http) => {
  return {
    async getCategories({ lang }) {
      const { data } = await http.post("/graphql", {
        query: gql`
          query categories($lang: String!) {
            categories(lang: $lang) {
              description
              tagline
              svgTitle
              heroImageLandscape {
                alt
                height
                url
                width
              }
              heroImagePortrait {
                alt
                height
                url
                width
              }
              label
              linkedFromSRPForCities
              slug
              title
              titleFontFamily
              lang
            }
          }
        `,
        variables: {
          lang,
        },
      });
      return data;
    },
  };
};

export default factory;
