import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { urlMaker } from "../../utils/url";
import BackButton from "../BackButton/BackButton";
import AppHeaderNavigation from "./AppHeaderNavigation";
import Logo from "./Logo/Logo";

import "./AppHeader.scss";

const AppHeader = React.forwardRef(
  (
    {
      children,
      className,
      currentUrl,
      analyticsEvent = () => {},
      border = true,
      lang,
      showBackButton,
      t,
      theme = "white",
      translationUrls,
      user,
      showLandlordLinks = true,
    },
    ref,
  ) => {
    const url = urlMaker(lang);

    return (
      <header
        ref={ref}
        className={classNames(
          "AppHeader",
          `AppHeader-theme--${theme}`,
          className,
        )}
      >
        <div
          className={classNames("AppHeader-container", {
            "AppHeader-container--border": border,
          })}
        >
          <figure className="AppHeader-brand">
            {showBackButton && (
              <BackButton
                t={t}
                className="AppHeader-backButton"
                type="translucid"
              />
            )}

            {!showBackButton && <Logo t={t} url={url} />}
          </figure>

          {children && <div className="AppHeader-children">{children}</div>}

          <AppHeaderNavigation
            currentUrl={currentUrl}
            analyticsEvent={analyticsEvent}
            lang={lang}
            t={t}
            translationUrls={translationUrls}
            url={url}
            user={user}
            showLandlordLinks={showLandlordLinks}
          />
        </div>
      </header>
    );
  },
);

AppHeader.propTypes = {
  border: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  currentUrl: PropTypes.string,
  analyticsEvent: PropTypes.func,
  lang: PropTypes.string.isRequired,
  showBackButton: PropTypes.bool,
  t: PropTypes.func.isRequired,
  theme: PropTypes.oneOf([
    "transparent",
    "white",
    "image",
    "basicTransparent",
    "brandGradient",
  ]),
  translationUrls: PropTypes.shape({
    en: PropTypes.string.isRequired,
    de: PropTypes.string.isRequired,
  }),
  user: PropTypes.object,
  showLandlordLinks: PropTypes.bool,
};

export default AppHeader;
