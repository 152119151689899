import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import * as events from "../../../tracker/events";
import * as tracker from "../../../tracker/tracker";
import { getDisplayName } from "../../../utils/react-utils";

const TrackerContext = React.createContext(tracker);

const providerPropTypes = {
  tracker: PropTypes.object,
  children: PropTypes.node,
};

export function TrackerProvider({ tracker, children }) {
  const contextValue = useMemo(
    () => ({
      tracker: {
        ...tracker,
        events,
      },
    }),
    [tracker],
  );

  return (
    <TrackerContext.Provider value={contextValue}>
      {children}
    </TrackerContext.Provider>
  );
}

TrackerProvider.propTypes = providerPropTypes;

export function useTracker() {
  return useContext(TrackerContext);
}

export function withTracker(Component) {
  function WrappedComponent(props) {
    const trackerValue = useTracker();
    return <Component {...props} {...trackerValue} />;
  }

  // Assign static properties like propTypes:
  for (const key of Object.keys(Component)) {
    WrappedComponent[key] = Component[key];
  }

  WrappedComponent.displayName = `WithI18n(${getDisplayName(Component)})`;

  return WrappedComponent;
}
