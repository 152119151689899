import { bookingOffers, listingRequests } from "@wunderflats/constants";
import dayjs from "dayjs";
import omit from "lodash/omit";
import { extractCoverImageFromListing } from "../../../../utils/listing-image-utils";

const { REASON_FOR_STAY } = bookingOffers;
const { EMPLOYMENT_TYPES } = listingRequests;

const BookingWizardStep = {
  ABOUT_BOOKING: "ABOUT_BOOKING",
  ABOUT_TENANTS: "ABOUT_TENANTS",
  ABOUT_RELOCATION: "ABOUT_RELOCATION",
  REVIEW: "REVIEW",
};

export function mapStepNumberToBookingWizardStepNameForEvents(stepNumber) {
  switch (stepNumber) {
    case "1":
      return BookingWizardStep.ABOUT_BOOKING;
    case "2":
      return BookingWizardStep.ABOUT_TENANTS;
    case "3":
      return BookingWizardStep.ABOUT_RELOCATION;
    case "4":
      return BookingWizardStep.REVIEW;
    default:
      return null;
  }
}

export function blackListPayload(payload) {
  const apprenticeshipOmittedFields = [
    "studyInformation",
    "workInformation.employment",
    "workInformation.jobTitle",
    "workInformation.department",
  ];
  const workOmittedFields = ["studyInformation", "workInformation.department"];
  const internshipOmittedFields = [
    "studyInformation",
    "workInformation.employment",
    "workInformation.jobTitle",
  ];
  const studyOmittedFields = ["workInformation"];
  const refugeeOmittedFields = ["workInformation", "studyInformation"];

  let omittedFields = workOmittedFields;
  if (payload.reasonForStay === REASON_FOR_STAY.Apprenticeship) {
    omittedFields = apprenticeshipOmittedFields;
  } else if (payload.reasonForStay === REASON_FOR_STAY.Internship) {
    omittedFields = internshipOmittedFields;
  } else if (payload.reasonForStay === REASON_FOR_STAY.Studying) {
    omittedFields = studyOmittedFields;
  } else if (payload.reasonForStay === "REFUGEE") {
    omittedFields = refugeeOmittedFields;
  }

  if (
    payload.workInformation &&
    payload.workInformation.employment === EMPLOYMENT_TYPES.SelfEmployed
  ) {
    omittedFields.push("workInformation.companyName");
  }

  return omit(payload, omittedFields);
}

export function parseFormToPayload({
  formData,
  tenant,
  steps,
  isExtension,
  bookingId,
}) {
  const parsedApiPayload = blackListPayload({
    type: "STANDARD",
    from: formData[steps.step1.stepNumber].from,
    to: formData[steps.step1.stepNumber].to,
    optionToExtend: formData[steps.step1.stepNumber].optionToExtend,
    tenantInformation: {
      firstName: tenant.firstName,
      lastName: tenant.lastName,
      phone: formData[steps.step3.stepNumber].phone,
    },
    adultsInformation: formData[steps.step2.stepNumber].adultsInformation.map(
      (adult) => ({
        name: adult.adultName,
        gender: adult.adultGender,
        relationship: adult.adultRelationship,
      }),
    ),
    childrenInformation: (
      formData[steps.step2.stepNumber].childrenInformation || []
    ).map((child) => ({
      type: child.childType,
    })),
    petsInformation: (
      formData[steps.step2.stepNumber].petsInformation || []
    ).map((pet) => ({
      description: pet.petName,
    })),

    reasonForStay: formData[steps.step3.stepNumber].reasonToStay,
    paidBy: formData[steps.step3.stepNumber].paidBy,
    notes: formData[steps.step3.stepNumber].notes,
    workInformation: {
      employment: formData[steps.step3.stepNumber].employmentType,
      jobTitle: formData[steps.step3.stepNumber].jobTitle,
      companyName: formData[steps.step3.stepNumber].companyName,
      department: formData[steps.step3.stepNumber].department,
    },
    studyInformation: {
      studyProgram: formData[steps.step3.stepNumber].studyProgram,
      universityName: formData[steps.step3.stepNumber].university,
    },
  });

  if (isExtension && bookingId) {
    parsedApiPayload.type = "EXTENSION";
    parsedApiPayload.extendsBookingId = bookingId;
  }

  return parsedApiPayload;
}

export function getListingSummaryCoverImageProps(listing) {
  return extractCoverImageFromListing(listing);
}

export function trackEventsOnBookingWizardSteps({
  stepNumber,
  steps,
  tracker,
  formData,
  listing,
  proceedToNextStep,
  formValidationErrors,
}) {
  switch (stepNumber) {
    case steps.step1.stepNumber:
      return tracker.events.trackRequestToBookStep1({
        formData,
        proceedToNextStep,
        listing,
      });
    case steps.step2.stepNumber:
      return tracker.events.trackRequestToBookStep2({
        formData,
        proceedToNextStep,
        listing,
        formErrors: formValidationErrors,
      });
    case steps.step3.stepNumber:
      return tracker.events.trackRequestToBookStep3({
        formData,
        listing,
        proceedToNextStep,
        formErrors: formValidationErrors,
      });
    case steps.step4.stepNumber:
      return tracker.events.trackRequestToBookStep4({
        proceedToNextStep,
        listing,
      });
    default:
      return null;
  }
}

export function trackNewBookingWizardEvents({
  stepNumber,
  tracker,
  stepsFormState,
  steps,
  listing,
  totalRent,
  user,
  listingRequest,
}) {
  const bookingWizardStep =
    mapStepNumberToBookingWizardStepNameForEvents(stepNumber);
  const createdBySales = user.permissions.includes("sales");

  const step1Data = stepsFormState[steps.step1.stepNumber] || {};
  const step2Data = stepsFormState[steps.step2.stepNumber] || {};
  const step3Data = stepsFormState[steps.step3.stepNumber] || {};

  const listingRequestType = step1Data.type || "STANDARD";
  let childrenAge;

  switch (bookingWizardStep) {
    case BookingWizardStep.ABOUT_BOOKING:
      tracker.events.trackAboutTheBookingStepOnBookingWizardCompleted({
        tenantUserId: user._id,
        fromDate: step1Data.from,
        toDate: step1Data.to,
        requestOptionToExtend: step1Data.optionToExtend,
        listingRequestType,
      });
      break;

    case BookingWizardStep.ABOUT_TENANTS:
      if (
        step2Data.childrenInformation &&
        step2Data.childrenInformation.length > 0
      ) {
        childrenAge = step2Data.childrenInformation.map(
          (child) => child.childType,
        );
      }

      tracker.events.trackAboutTheTenantsStepOnBookingWizardCompleted({
        tenantUserId: user._id,
        numberOfPets: step2Data.petsInformation?.length,
        numberOfAdults: step2Data.adultsInformation?.length || 0,
        childrenAge,
        listingRequestType,
      });
      break;

    case BookingWizardStep.ABOUT_RELOCATION:
      tracker.events.trackAboutRelocationStepOnBookingWizardCompleted({
        tenantUserId: user._id,
        reasonForStay: step3Data.reasonToStay,
        whoIsPaying: step3Data.paidBy,
        listingRequestType,
      });
      break;

    case BookingWizardStep.REVIEW:
      if (listingRequest) {
        const totalStayDays = dayjs
          .utc(listingRequest.to)
          .diff(dayjs.utc(listingRequest.from), "days", false);

        tracker.events.trackListingRequestCreated({
          tenantUserId: user._id,
          listingCity: listing.address.city,
          listingRequestId: listingRequest.id,
          bookingRequestId: listingRequest.bookingRequestId,
          listingId: listing._id,
          groupId: listing.groupId,
          totalRent,
          monthlyRent: listing.price,
          fromDate: listingRequest.from,
          toDate: listingRequest.to,
          reasonForStay: step3Data.reasonToStay,
          requestOptionToExtend: step1Data.optionToExtend,
          totalStayDays,
          createdBySales,
        });
      }
      break;
    default:
      return null;
  }
}

export const verboseDateFormat = ({ date, defaultString = "" }) => {
  return date ? dayjs(date).format("DD MMM, YYYY") : defaultString;
};

/** We don't show a suggestion to switch move in dates according to max allowed vacancy if
 * the listing is a refugee exclusive listing or a multilisting. We also don't show it if the
 * landlord suggested specific dates for this listing request. It's not possible to
 * calculate if a listing is within max vacancy if from and to dates aren't selected.
 * (We also don't show the notification for extension requests.)
 */
export const shouldUseMoveInDatesSuggetion = ({
  listing,
  from,
  to,
  hasDatesSuggestedByLL,
}) => {
  return (
    from &&
    to &&
    listing &&
    !listing.refugeeExclusive &&
    !listing.partOfGroup &&
    !hasDatesSuggestedByLL
  );
};

export const AmplitudeEventNames = {
  STEP_DISPLAYED: "Booking Wizard Step Displayed",
};
