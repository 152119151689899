export const errorName = "ResourceNotFoundError";

class ResourceNotFoundError extends Error {
  constructor({ resource, message = errorName } = {}) {
    super(message);
    this.resource = resource;
    this.status = 404;
    this.name = errorName;
  }
}

export default ResourceNotFoundError;
