const factory = (http) => {
  return {
    getTenantBookings() {
      return http.get("/users/me/tenant/bookings").then((data) => {
        return data.bookings;
      });
    },

    getTenantBookingById(bookingId) {
      return this.getTenantBookings(bookingId).then((bookings = []) => {
        const booking = bookings.find(({ _id }) => bookingId === _id);
        if (booking) {
          return booking;
        }
        const error = new Error(
          `User doesn't have access to booking: ${bookingId}`,
        );
        error.name = "ResourceNotFoundError";
        throw error;
      });
    },

    getBookingRequest(bookingRequestId) {
      return http
        .get(`/users/me/tenant/booking-requests/${bookingRequestId}`)
        .then((data) => data.bookingRequest);
    },

    createBookingRequest(listingId, bookingRequest) {
      return http
        .post(`/listings/${listingId}/requests`, bookingRequest)
        .then((data) => {
          // v2 route returns { bookingRequest }
          if (data.bookingRequest) {
            return data.bookingRequest;
          }

          // v1 route returns bookingRequest directly on response body
          return data;
        });
    },
  };
};

export default factory;
