import React from "react";
import PropTypes from "prop-types";
import { useI18n } from "../../../spages/spa/context/I18nContext";
import BecomeALandlordLink from "../Links/BecomeALandlordLink";
import DiscoverLink from "../Links/DiscoverLink";
import LandlordDashboardLink from "../Links/LandlordDashboardLink";
import ShortlistLink from "../Links/ShortlistLink";

const DesktopNavigationLinks = ({
  currentUrl,
  analyticsEvent,
  url,
  user,
  showLandlordLinks,
}) => {
  const isLandlord = user && user.landlord;
  const { t, lang } = useI18n();

  return (
    <ul className="AppHeader-DesktopLinkList">
      {showLandlordLinks && isLandlord && (
        <li className="AppHeader-DesktopLinkList-item">
          <LandlordDashboardLink
            lang={lang}
            url={url}
            analyticsEvent={analyticsEvent}
          />
        </li>
      )}

      <li className="AppHeader-DesktopLinkList-item">
        <DiscoverLink
          t={t}
          lang={lang}
          url={url}
          currentUrl={currentUrl}
          analyticsEvent={analyticsEvent}
        />
      </li>

      <li className="AppHeader-DesktopLinkList-item">
        <ShortlistLink
          currentUrl={currentUrl}
          t={t}
          url={url}
          analyticsEvent={analyticsEvent}
        />
      </li>

      {showLandlordLinks && !isLandlord && (
        <li className="AppHeader-DesktopLinkList-item">
          <BecomeALandlordLink url={url} />
        </li>
      )}
    </ul>
  );
};

DesktopNavigationLinks.propTypes = {
  user: PropTypes.object,
  currentUrl: PropTypes.string,
  analyticsEvent: PropTypes.func.isRequired,
  url: PropTypes.func.isRequired,
  showLandlordLinks: PropTypes.bool,
};

export default DesktopNavigationLinks;
