/**
 * Constructing all static urls in this file to avoid hardcoding them in the components
 * Will keep the tests file hardcorded e.g; see the file utils.test.js
 */

export const STATIC_BASE_URL = "https://static.wunderflats.com";
export const ARTEFACTS_BASE_URL = `${STATIC_BASE_URL}/artefacts`;
export const IMAGE_STAGING_BASE_URL = `${STATIC_BASE_URL}/imagesstaging`;

// choosing the naming as component name to avoid confusion
export const cardImageHeaderExample = {
  src: `${ARTEFACTS_BASE_URL}/homepage/Landlord%401x748.jpg`,
  srcSet: `
        ${ARTEFACTS_BASE_URL}/homepage/FSU%400.5x374.jpg 374w,
        ${ARTEFACTS_BASE_URL}/homepage/FSU%401x748.jpg 748w,
        ${ARTEFACTS_BASE_URL}/homepage/FSU%402x1496.jpg 1496w,
        ${ARTEFACTS_BASE_URL}/homepage/FSU%403x2244.jpg 2244w `,
};

export const guaranteeLetterDescription = {
  EN_DE: `${ARTEFACTS_BASE_URL}/documents/buergschaftserklaerung_guarantee.pdf`,
  EN_FR: `${ARTEFACTS_BASE_URL}/documents/acte_de_cautionnement_solidaire_guarantee.pdf`,
};

export const getSidebarImageUrl = (customerType, resolution) => {
  const imageType = customerType === "tenant" ? "apartment" : "landlord";
  return `${ARTEFACTS_BASE_URL}/sidebar/${imageType}-${resolution}.jpg`;
};

export const getSidebarImageSrcSet = (customerType) => {
  return `
    ${getSidebarImageUrl(customerType, 420)} 420w,
    ${getSidebarImageUrl(customerType, 840)} 840w,
    ${getSidebarImageUrl(customerType, 1680)} 1680w
  `;
};

// LanlordSection.js
export const landlordSectionURLs = {
  fsuCardSrcSet: `
    ${ARTEFACTS_BASE_URL}/homepage-webp/FSU%400.5x374.webp 374w,
    ${ARTEFACTS_BASE_URL}/homepage-webp/FSU%401x748.webp 748w,
    ${ARTEFACTS_BASE_URL}/homepage-webp/FSU%402x1496.webp 1496w,
    ${ARTEFACTS_BASE_URL}/homepage-webp/FSU%403x2244.webp 2244w
  `,
  landlordCardSrcSet: `
    ${ARTEFACTS_BASE_URL}/homepage-webp/Landlord%400.5x374.webp 374w,
    ${ARTEFACTS_BASE_URL}/homepage-webp/Landlord%401x748.webp 748w,
    ${ARTEFACTS_BASE_URL}/homepage-webp/Landlord%402x1496.webp 1496w,
    ${ARTEFACTS_BASE_URL}/homepage-webp/Landlord%403x2244.webp 2244w
  `,
  cardImageHeaderLLSrc: `${ARTEFACTS_BASE_URL}/homepage-webp/Landlord%401x748.webp`,
  cardImageHeaderFSUSrc: `${ARTEFACTS_BASE_URL}/homepage-webp/FSU%401x748.webp`,
};
