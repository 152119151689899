import datesUtils from "../utils/dates";

const { sortByFromDateAscending } = datesUtils;

const factory = (http) => {
  return {
    getMultilisting(groupId, query) {
      const url = `/listings/g/${groupId}`;
      return http.get(url, { params: query }).then((data) => data.listing);
    },

    createMultilisting(listingId, payload) {
      return http.put(`/listings/${listingId}`, payload);
    },

    updateMultilistingQuantity(groupId, payload) {
      return http.post(`/listings/g/${groupId}`, payload);
    },

    deleteMultilisting(groupId) {
      return http.delete(`/listings/g/${groupId}`);
    },

    getAvailableListings(groupId, duration) {
      return http
        .get(`/listings/g/${groupId}/availability`, {
          params: duration,
        })
        .then((data) => data.listings);
    },

    updateMultilisting(groupId, values) {
      return http
        .put(`/listings/g/${groupId}`, values)
        .then((data) => data.listing);
    },

    uploadMultilistingImage(groupId, formData) {
      return http
        .post(`/listings/g/${groupId}/images`, formData, {
          timeout: 60 * 1000 * 60,
        })
        .then((data) => data.image);
    },

    addBlock(groupId, duration) {
      return http
        .post(`/listings/g/${groupId}/blocks`, duration)
        .then((res) => res.block);
    },

    getBlocks(groupId) {
      return http
        .get(`/listings/g/${groupId}/blocks`)
        .then((data) => data.items.sort(sortByFromDateAscending));
    },
  };
};

export default factory;
