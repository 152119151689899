import dayjs from "dayjs";
import get from "lodash/get";
import isFunction from "lodash/isFunction";
import store from "store2";
import * as defaultListingFilter from "../default-listing-filter";
import { canUseDOM } from "../dom";

export const SHORTLIST_DATES_COOKIE = "shortlist-dates";

export const sortListings = (listEltA, listEltB) => {
  const addedAtA = dayjs.utc(listEltA.addedAt);
  const addedAtB = dayjs.utc(listEltB.addedAt);
  if (addedAtA.isBefore(addedAtB)) {
    return 1;
  }
  if (addedAtA.isAfter(addedAtB)) {
    return -1;
  }
  return 0;
};

/**
 * Date format expected "yyyy-mm-dd"
 *  */
export const getShareableToken = ({ from, to, userName, listings = [] }) => {
  const data = {
    from,
    to,
    groupIds: listings.sort(sortListings).map((listElt) => listElt.groupId),
    userName,
  };

  let encodedData;
  try {
    encodedData = encodeURIComponent(btoa(JSON.stringify(data)));
  } catch (error) {
    throw new Error("Could not create token", error);
  }

  return encodedData;
};

export const parseShareableToken = (token) => {
  let decodedData;
  try {
    if (!canUseDOM()) {
      decodedData = JSON.parse(
        Buffer.from(decodeURIComponent(token), "base64").toString("binary"),
      );
    } else {
      decodedData = JSON.parse(atob(decodeURIComponent(token)));
    }
  } catch (error) {
    throw new Error("Could not decode token", error);
  }

  return decodedData;
};

export const checkAvailability = (listingsData, from, to, api) => {
  return Promise.all(
    listingsData.map(async (listing) => {
      if (!listing.isOnline) {
        return { ...listing, isAvailable: false };
      }

      const availableListingIds =
        await api.listings.getAvailableListingsByGroupId({
          groupId: listing.groupId,
          from,
          to,
        });

      const isAvailable = availableListingIds.length > 0;
      listing.isAvailable = isAvailable;

      if (isAvailable === false) {
        const firstAvailableVacancy = listing.listings
          .flatMap((listing) => listing.vacancies)
          .sort((a, b) => a.from - b.from)
          .find(
            (vacancy) =>
              dayjs.utc(from).toDate() < dayjs.utc(vacancy.from).toDate(),
          );

        listing.firstPossibleMoveInDate = firstAvailableVacancy
          ? firstAvailableVacancy.from
          : null;
      }

      return listing;
    }),
  );
};

/**
 * @param {Object} object
 * @param {string} object.userId - A user ObjectID as string
 * @param {Object} object.api - A subset of our api http helper
 * @param {Object} object.api.listings.getListingsByGroupIds - helper for publicListingsByGroupIds GraphQL query
 * @param {Object} object.api.shortlist - helpers for shortlist graphql queries
 * @param {Object} object.shortlistData - Initial listing data from the shortlist query
 */
export default ({ userId, api, shortlistData } = {}) => {
  const id = "default";

  /**
   * @type {ShortlistElt[]}
   */
  const list =
    userId && shortlistData
      ? shortlistData.map(({ groupId, addedAt }) => ({
          groupId,
          addedAt,
        }))
      : store.get(id) || [];

  const getGroupIds = () => {
    return list.sort(sortListings).map((listElt) => listElt.groupId);
  };

  const contains = (groupId) => {
    return !!list.find((listElt) => listElt.groupId === groupId);
  };

  let _onChange = () => {};

  const onChange = (onChangeHandler) => {
    if (!isFunction(onChangeHandler)) {
      return;
    }
    _onChange = onChangeHandler;
  };

  // Refresh `list` with content from localStorage
  const refresh = () => {
    const listingsInStorage = store.get(id) || [];
    list.splice(0, list.length, ...listingsInStorage);

    _onChange();
  };

  /**
   * Adds a groupId to your shortlist, or updated it's addedAt
   * value if it was already added.
   * @param {string} groupId
   * @returns {ShortlistElt} The added shortlist element
   */
  const add = (groupId) => {
    if (!canUseDOM()) {
      throw new Error("Shortlist can only be edited client side");
    }

    let shortlistElt = list.find((listElt) => listElt.groupId === groupId);
    if (!shortlistElt) {
      shortlistElt = { groupId, addedAt: dayjs.utc().toISOString() };
      list.unshift(shortlistElt);
    } else {
      shortlistElt.addedAt = dayjs.utc().toISOString();
    }

    store.set(id, list);
    _onChange();
    return shortlistElt;
  };

  const remove = (groupId) => {
    if (!canUseDOM()) {
      throw new Error("Shortlist can only be edited client side");
    }

    const eltIndex = list.findIndex((listElt) => listElt.groupId === groupId);

    if (eltIndex === -1) {
      return;
    }

    list.splice(eltIndex, 1);
    store.set(id, list);

    _onChange();
  };

  const removeListingAsync = async (groupId) => {
    if (userId) {
      await api.shortlist.removeGroupFromShortlist(groupId);
    }

    remove(groupId);
  };

  const addListingAsync = async (groupId) => {
    const shortlistElt = add(groupId);

    if (userId) {
      await api.shortlist.addGroupToShortlist(
        shortlistElt.groupId,
        shortlistElt.addedAt,
      );
    }
  };

  const toggle = async (groupId) => {
    if (contains(groupId)) {
      return removeListingAsync(groupId);
    }
    return addListingAsync(groupId);
  };

  /**
   * @returns {PublicListing[]}
   */
  const getListingsAsync = async ({ from, to } = {}) => {
    /**
     * For logged in users, the shortlist util **should** be instanciated with
     * data fetched by the shortlist page's `getData` function when rendering.
     * It **should not** call the API to get the listing data
     */
    if (userId && shortlistData && shortlistData.length) {
      // AV Check if from and to dates are provided.
      if (from && to) {
        const listingsWithAv = await checkAvailability(
          shortlistData,
          from,
          to,
          api,
        );
        return listingsWithAv;
      }

      return shortlistData;
    }

    const groupIds = getGroupIds();
    let listingsData = await api.listings.getListingsByGroupIds({
      groupIds,
    });

    listingsData = listingsData.map((listing) => {
      listing.isOnline = listing.__typename === "PublicGroup";

      return listing;
    });

    // If we could not get data for one of the groupId, it
    // means that the group is either unpublished, inactive or deleted.
    // Either way, it needs to be purged from the shortlist.
    groupIds.forEach((groupId) => {
      if (!listingsData.find((listing) => listing.groupId === groupId)) {
        remove(groupId);
      }
    });

    if (from && to) {
      listingsData = await checkAvailability(listingsData, from, to, api);
    }

    return listingsData;
  };

  const onLogin = async (user) => {
    userId = user._id;

    // get shortlist from API
    const apiData = await api.shortlist.getShortlist();

    /**
     * @type {ShortlistElt[]}
     */
    const shortlistElements = get(apiData, "shortlist.items", []).map(
      (shortlistElement) => {
        return {
          groupId: get(shortlistElement, "group.groupId"),
          addedAt: get(shortlistElement, "addedAt"),
        };
      },
    );
    if (!shortlistElements.length) {
      return;
    }
    // update localStorage with API data
    list.splice(0, list.length, ...shortlistElements);
    store.set(id, shortlistElements);
    _onChange();

    let listingsData = get(apiData, "shortlist.items", []).map(
      (shortlistItem) => {
        return {
          addedAt: shortlistItem.addedAt,
          ...shortlistItem.group,
        };
      },
    );

    listingsData = listingsData.map((listing) => {
      listing.isOnline = listing.__typename === "PublicGroup";

      return listing;
    });

    const filtersInCookie = defaultListingFilter.get();
    const from = filtersInCookie.from;
    const to = filtersInCookie.to ? filtersInCookie.to : "";

    if (from && to) {
      listingsData = await checkAvailability(listingsData, from, to, api);
    }

    return listingsData;
  };

  const onLogout = () => {
    userId = null;
    list.splice(0, list.length);
    store.set(id, []);
    _onChange();
  };

  return {
    id,
    list,
    get groupIds() {
      return getGroupIds();
    },
    add,
    contains,
    remove,
    refresh,
    toggle,
    getListingsAsync,
    addListingAsync,
    removeListingAsync,
    onLogin,
    onLogout,
    onChange,
  };
};
