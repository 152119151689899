import React, { useState } from "react";
import PropTypes from "prop-types";
import serialize from "form-serialize";
import { useI18n } from "../../spages/spa/context/I18nContext";
import ErrorIndicator from "../ErrorIndicator/ErrorIndicator";
import Input from "../Input/Input";

const propTypes = {
  onSubmit: PropTypes.func,
  error: PropTypes.object,
};

const ForgotPasswordForm = ({ onSubmit, error }) => {
  const { t } = useI18n();
  const [values, setValues] = useState({ email: "" });

  const onChange = (field, e) => {
    setValues((prevValues) => ({
      ...prevValues,
      [field]: e.target.value,
    }));
  };

  const onChangeEmail = (e) => onChange("email", e);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formValues = serialize(e.target, { hash: true });
    if (onSubmit) {
      onSubmit(formValues);
    }
  };

  return (
    <form method="post" onSubmit={handleSubmit}>
      <div className="grid-row">
        <ErrorIndicator t={t} field="email" error={error}>
          <Input
            autoFocus
            name="email"
            type="email"
            label={t("components.ForgotPasswordForm.email")}
            labelFor="email"
            value={values.email}
            onChange={onChangeEmail}
          />
        </ErrorIndicator>
      </div>

      <button className="btn btn-action btn-full" type="submit">
        {t("components.ForgotPasswordForm.action")}
      </button>
    </form>
  );
};

ForgotPasswordForm.propTypes = propTypes;

export default ForgotPasswordForm;
