import dayjs from "dayjs";
import _isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import promiseMemo from "promise-memoize";
import qs from "qs";
import RedirectError from "../../../../errors/redirectError";
import date from "../../../../utils/date";
import { create as createListingViewModel } from "../../../../utils/listing-view-model";
import getWFCONFIG from "../../../../utils/WF_CONFIG";

const WF_CONFIG = getWFCONFIG();

const getCategoriesMemo = promiseMemo(
  (api, lang) => {
    return api.categories.getCategories({ lang });
  },
  { maxAge: 60000 },
);

export default async ({ api, params, query, originalUrl, cookies }) => {
  const queryDatesCheckResult = date.checksIfQueryDatesAreValid(query);
  if (!queryDatesCheckResult.isValid) {
    const [firstPart] = originalUrl.split("?");
    const fromToRemoved = qs.stringify(omit(query, ["from", "to"]), {
      addQueryPrefix: true,
    });
    const redirectTo = `${firstPart}${fromToRemoved || ""}`;
    throw new RedirectError({ redirectTo, status: 301 });
  }

  const listingId = params.id;
  const groupId = params.groupId;
  const listing = listingId
    ? await api.listings.getListing(listingId)
    : await api.multilistings.getMultilisting(groupId);

  if (!listingId) {
    const multilistingAvailableFrom = listing.group.sort(
      (a, b) => new Date(a.availableFrom) - new Date(b.availableFrom),
    )[0].availableFrom;
    listing.availableFrom = multilistingAvailableFrom;
  }

  const blocks = listingId
    ? await api.listings
        .getBlockedDates(listingId, {
          from: dayjs.utc().subtract(3, "years").format("YYYY-MM-DD"),
          to: dayjs.utc().add(3, "years").format("YYYY-MM-DD"),
        })
        .catch(() => [])
    : await api.multilistings.getBlocks(groupId).catch(() => []);

  const lvm = createListingViewModel(() => {}, params.lang);
  const expectedUrl = lvm.permalinkUrl(listing);

  const correctTitleInParams = expectedUrl.includes(`/${params.title}/`);
  const urlIsGroup = originalUrl.match(/.+\/g\/.+/);

  if (!correctTitleInParams || (listing.partOfGroup && !urlIsGroup)) {
    const queryString = qs.stringify(query, {
      addQueryPrefix: true,
    });
    const redirectTo = `${expectedUrl}${queryString || ""}`;
    throw new RedirectError({ redirectTo, status: 301 });
  }

  let totalRent = 0;

  if (query.from && query.to) {
    const { months } = await api.costCalculation.getCostSummary({
      price: listing.price,
      deposit: listing.deposit,
      from: dayjs(query.from).format("YYYY-MM-DD"),
      to: dayjs(query.to).format("YYYY-MM-DD"),
    });

    totalRent = months.reduce((a, item) => a + item.price, 0);
  }

  const categoriesResponse = await (WF_CONFIG.NODE_ENV === "production"
    ? getCategoriesMemo(api, params.lang)
    : api.categories.getCategories({ lang: params.lang }));
  const categories = categoriesResponse?.categories
    ? categoriesResponse.categories
    : [];

  return {
    listing,
    blocks,
    cookies,
    totalRent,
    categories,
  };
};

export function shouldListingPageReload(prev, curr) {
  function extractNonReloadParams({ params, query }) {
    return {
      params,
      query: omit(query, ["imageGallery", "from", "to"]),
    };
  }

  return !_isEqual(extractNonReloadParams(prev), extractNonReloadParams(curr));
}

export function listingRedirectCheck({
  route: { params },
  originalUrl,
  t,
  language,
  pageData: { listing },
  query,
}) {
  const lvm = createListingViewModel(t, language);
  const expectedUrl = lvm.permalinkUrl(listing);

  const correctTitleInParams = expectedUrl.includes(`/${params.title}/`);
  const urlIsGroup = originalUrl.match(/.*\/g\/(\w|-)+\/.*/);

  const isFromQueryBeforeToday = dayjs.utc(query.from).isBefore(dayjs.utc());
  const isToQueryBeforeToday = dayjs.utc(query.to).isBefore(dayjs.utc());

  if (
    isFromQueryBeforeToday ||
    isToQueryBeforeToday ||
    !dayjs.utc(query.from).isValid() ||
    !dayjs.utc(query.to).isValid()
  ) {
    const [firstPart] = originalUrl.split("?");
    const { from, to, ...restQuery } = query;
    const fromToRemoved = qs.stringify(restQuery, {
      addQueryPrefix: true,
    });
    return `${firstPart}${fromToRemoved || ""}`;
  }

  if (!correctTitleInParams || (listing.partOfGroup && !urlIsGroup)) {
    const queryString = qs.stringify(query, {
      addQueryPrefix: true,
    });
    const correctUrl = `${expectedUrl}${queryString || ""}`;
    return correctUrl;
  }
}
