import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";
import { AuthContext } from "../../../../../contexts/AuthContext";
import LandlordMenuContent from "./LandlordMenuContent";
import TenantMenuContent from "./TenantMenuContent";

const LoggedInUserMenu = (props) => {
  const { user, t } = props;
  const { userActionsCount } = useContext(AuthContext);

  return (
    <Fragment>
      <h4 className="AppHeaderDesktopUserMenu-username">
        {user.firstName} {user.lastName}
      </h4>

      {userActionsCount?.missingProfileData > 0 && (
        <p className="AppHeaderDesktopUserMenu-warning">
          {t("pleaseCompleteYourAccount")}
        </p>
      )}

      {user.landlord ? (
        <LandlordMenuContent {...props} />
      ) : (
        <TenantMenuContent {...props} />
      )}
    </Fragment>
  );
};

LoggedInUserMenu.propTypes = {
  t: PropTypes.func.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    landlord: PropTypes.bool,
    lastName: PropTypes.string,
  }).isRequired,
};

export default LoggedInUserMenu;
