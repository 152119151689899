import { extractLanguageContent } from "./localizer";
import slug from "./slug";
import { urlMaker } from "./url";

// eslint-disable-next-line import/prefer-default-export
export const create = (t, lang) => {
  const result = {};
  const url = urlMaker(lang);

  result.price = (listing) => {
    const { price } = listing;
    return Math.round(price);
  };

  result.title = (listing) =>
    extractLanguageContent({ langContentDictionary: listing.title, lang });

  result.titleSlug = (listing) =>
    slug(
      extractLanguageContent({ langContentDictionary: listing.title, lang }),
    );

  result.shortUrl = (listing) => `/l/${listing._id}`;

  result.permalinkUrl = (listing) => {
    if (listing.partOfGroup) {
      return url("multilistingdetails", {
        title: result.titleSlug(listing),
        groupId: listing.groupId,
      });
    }

    return url("listingdetails", {
      id: listing._id,
      title: result.titleSlug(listing),
    });
  };

  result.editLinkUrl = (listing, parentMultilisting) => {
    if (parentMultilisting) {
      return url("multilistingsingleeditV2", {
        listingId: listing._id,
        groupId: parentMultilisting.groupId,
      });
    }

    if (
      (listing.partOfGroup && !listing._id) ||
      (listing.partOfGroup && listing.group)
    ) {
      return url("multilistingeditV2", { groupId: listing.groupId });
    }

    return url("listingEditV2", { listingId: listing._id });
  };

  result.address = (listing) =>
    listing.address
      ? [
          listing.address.street,
          listing.address.streetNumber,
          listing.address.zipCode,
          listing.address.city,
        ]
          .filter((c) => !!c)
          .join(" ")
      : "";

  return result;
};
