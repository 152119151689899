import { createContext, useContext } from "react";

const FeatureFlagsContext = createContext();

// Use this hook in functional components.
// In class components you can use the `isFeatureFlagEnabled` function in `utils`
export const useFeatureFlags = () => {
  const featureFlags = useContext(FeatureFlagsContext);
  return {
    featureFlags,
    isFeatureFlagEnabled: (name) =>
      (featureFlags || []).some((flag) => flag.name === name),
  };
};

export default FeatureFlagsContext;
