import promiseMemo from "promise-memoize";
import faqsApiClient from "../../../../api/faqsApi";
import errorReporter from "../../../../server/error-reporter";
import * as defaultListingFilter from "../../../../utils/default-listing-filter";
import { canUseDOM } from "../../../../utils/dom";
import getWFCONFIG from "../../../../utils/WF_CONFIG";

const WF_CONFIG = getWFCONFIG();

const getCategoriesMemo = promiseMemo(
  (api, lang) => {
    return api.categories.getCategories({ lang });
  },
  { maxAge: 60000 },
);

const getRegionsMemo = promiseMemo(
  (api) => {
    return api.listings.getRegions();
  },
  { maxAge: 60000 },
);

export default async ({ api, user, cookies, params }) => {
  const { lang } = params;
  const faqsApi = faqsApiClient();
  let formattedHomePageFaq = [];

  try {
    const faqs = await faqsApi.getFaqs({ language: lang });

    formattedHomePageFaq = faqs.posts.reduce((acc, post) => {
      if (post.tags.includes("homepage")) {
        acc.push({
          question: post.title,
          answer: post.description,
        });
      }

      return acc;
    }, []);
  } catch (error) {
    if (canUseDOM()) {
      window.rollbar?.error(error);
    } else {
      const reportError = errorReporter(
        WF_CONFIG.ROLLBAR_POST_SERVER_ITEM_ACCESS_TOKEN,
        WF_CONFIG.ROLLBAR_ENABLED === "true",
      );

      reportError(error, { user });
    }
  }

  const response = await (WF_CONFIG.NODE_ENV === "production"
    ? getCategoriesMemo(api, params.lang)
    : api.categories.getCategories({ lang }));
  const categories = response?.categories ? response.categories : [];

  const regions = await getRegionsMemo(api);

  const defaultFilters = defaultListingFilter.getFromCookies(cookies);

  return {
    categories,
    regions,
    defaultFilters,
    homePageFaq: formattedHomePageFaq,
  };
};
