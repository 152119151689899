import React from "react";
import PropTypes from "prop-types";

const LeftArrow = ({
  className,
  width = 16,
  height = 15,
  fillColor = "#fff",
  ...attributes
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
      <path
        d="M6.949 14.001h2.93L3.963 8.087H16V6.015H3.964L9.878.1h-2.93L0 7.051l6.95 6.95z"
        fill={fillColor}
      />
    </svg>
  );
};

LeftArrow.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  fillColor: PropTypes.string,
};

export default LeftArrow;
