const factory = (http) => {
  return {
    requestVerificationCode(payload) {
      return http.post("/users/me/phone/verify", payload);
    },
    submitConfirmationCode(payload) {
      return http.put("/users/me/phone/verify", payload);
    },
    enableSmsNotifications(payload) {
      return http.put("/users/me/phone/sms", payload);
    },
  };
};

export default factory;
