import PropTypes from "prop-types";

const PublicListingPropType = PropTypes.shape({
  groupId: PropTypes.string.isRequired,
  _id: PropTypes.string,
  price: PropTypes.number,
  pictures: PropTypes.arrayOf(
    PropTypes.shape({
      imageId: PropTypes.string,
      urls: PropTypes.object,
    }),
  ),
  title: PropTypes.shape({
    en: PropTypes.string,
    de: PropTypes.string,
  }),
  address: PropTypes.shape({
    city: PropTypes.string.isRequired,
    districts: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
  rooms: PropTypes.number,
  beds: PropTypes.number,
  accommodates: PropTypes.number,
  area: PropTypes.number,
  isOnline: PropTypes.bool,
});

export default PublicListingPropType;
