export const redirectErrorName = "RedirectError";

class RedirectError extends Error {
  constructor({ redirectTo, status, message = redirectErrorName } = {}) {
    super(message);
    this.redirectTo = redirectTo;
    this.status = status;
    this.name = redirectErrorName;
  }
}

export default RedirectError;
