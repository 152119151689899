import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { isCurrentPage } from "../../../utils/url";
import BookingsIcon from "../../Icons/Bookings";

const MyBookingsLink = ({
  currentUrl,
  t,
  url,
  showIcon = false,
  analyticsEvent,
  notifications,
  expand = false,
}) => {
  return (
    <a
      href={url("bookingsLobby")}
      className={classNames(
        "AppHeader-Link",
        {
          "AppHeader-Link--active": isCurrentPage(
            currentUrl,
            "userBackendBookingManagement",
          ),
        },
        {
          "AppHeader-Link--expand": expand,
        },
      )}
      data-testid="AppHeader-Link-bookings"
      onClick={() => analyticsEvent("Menu", "menu_click_booking")}
    >
      {showIcon && (
        <span className="AppHeader-Link-icon">
          <BookingsIcon />
        </span>
      )}

      <span className="AppHeader-Link-text">
        {t("components.AppHeader.bookings")}
      </span>
      {notifications > 0 && (
        <span className="AppHeader-Link-badge">
          {notifications > 9 ? "9+" : notifications}
        </span>
      )}
    </a>
  );
};

MyBookingsLink.propTypes = {
  currentUrl: PropTypes.string,
  showIcon: PropTypes.bool,
  t: PropTypes.func,
  url: PropTypes.func.isRequired,
  analyticsEvent: PropTypes.func.isRequired,
  notifications: PropTypes.any,
  expand: PropTypes.bool,
};

export default MyBookingsLink;
