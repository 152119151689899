import { listings as listingsConstant, misc } from "@wunderflats/constants";
import dayjs from "dayjs";

const { labels } = misc;

// eslint-disable-next-line import/prefer-default-export
export function getSublistingIdentifier(sublisting, { lang }) {
  const {
    externalId,
    apartmentName: _legacyApartmentName,
    locationInBuilding,
  } = sublisting;

  return [
    externalId,
    locationInBuilding[lang] || (!externalId ? _legacyApartmentName : ""),
  ]
    .filter((v) => !!v)
    .join(", ");
}

export const getApartmentAddress = ({ address = {} }) => {
  const { street, zipCode, city } = address;
  const fullAddress = [street, zipCode, city];

  return fullAddress.filter((item) => Boolean(item)).join(", ");
};

export const getCitiesAndMaxBookingDurationsInMonths = () => [
  {
    title: "vienna",
    restriction: listingsConstant.MAX_BOOKING_DURATION.Vienna,
  },
];

export const getMaxBookingDurationInMonthsByCityName = (cityTitle) => {
  const city = getCitiesAndMaxBookingDurationsInMonths().find(
    (c) => c.title === cityTitle,
  );

  return city ? city.restriction : null;
};

export const isACityWithCustomMaxBookingDuration = (city) => {
  const cities = getCitiesAndMaxBookingDurationsInMonths().map((c) => c.title);

  return cities.includes(city);
};

export const isBookingPeriodValid = (fromDate, toDate, region) => {
  if (
    !fromDate ||
    (region !== "vienna" && !toDate) ||
    !dayjs.utc(fromDate, "YYYY-MM-DD", true).isValid
  ) {
    return false;
  }

  if (region === "vienna") {
    return toDate
      ? dayjs
          .utc(toDate)
          .isSameOrBefore(
            dayjs
              .utc(fromDate)
              .add(getMaxBookingDurationInMonthsByCityName(region), "month"),
          )
      : true;
  }

  return dayjs.utc(toDate).diff(dayjs.utc(fromDate), "days") >= 90;
};

export const cityNameFromDeToEn = (city) => {
  if (!city) {
    return null;
  }

  const citiesWithDifferentEnNames = [
    {
      en: "Cologne",
      de: "Köln",
    },
    {
      en: "Munich",
      de: "München",
    },
  ];

  const result = citiesWithDifferentEnNames.find(
    (c) => c.de.toLocaleUpperCase() === city.toLocaleUpperCase(),
  );

  return result ? result.en : city;
};

export const isServicedApartment = (listing) =>
  (listing?.labels || []).some(
    (label) => label === labels.CATEGORY_SERVICED_APARTMENTS,
  );

export const getCountryDialCode = (countryCode) => {
  return listingsConstant.COUNTRY_DIAL_CODE[countryCode || "DE"];
};

export const getTotalRent = async ({ from, to, listing, api }) => {
  let totalRent = 0;

  if (from && to) {
    const { months } = await api.costCalculation.getCostSummary({
      price: listing.price,
      deposit: listing.deposit,
      from: dayjs(from).format("YYYY-MM-DD"),
      to: dayjs(to).format("YYYY-MM-DD"),
    });

    totalRent = months.reduce((a, item) => a + item.price, 0);
  }

  return totalRent;
};
