import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { prepareModifiersForClassNames } from "../../utils/bem";
import Card from "../Card/Card";

import "./StatusCard.scss";

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  hideIndicator: PropTypes.bool,
  intent: PropTypes.oneOf(["success", "warning", "neutral", "danger", "brand"]),
  WrapperComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  onTransitionEnd: PropTypes.func,
  isSmall: PropTypes.bool,
};

const StatusCard = React.forwardRef((props, ref) => {
  const {
    children,
    className,
    intent = "neutral",
    WrapperComponent = "div",
    hideIndicator = false,
    onTransitionEnd,
    isSmall = false,
    ...attributes
  } = props;
  const classNameModifiers = intent
    ? { [`intent_${intent}`]: intent, hideIndicator }
    : {};

  return (
    <div
      onTransitionEnd={onTransitionEnd}
      ref={ref}
      className={`${classnames("StatusCard-outer", className)}`}
    >
      <WrapperComponent
        className={classnames(
          "StatusCard",
          prepareModifiersForClassNames("StatusCard", classNameModifiers),
        )}
        {...attributes}
      >
        <Card
          className={classnames("StatusCard-inner", {
            "StatusCard--small": isSmall,
          })}
          bordered
        >
          {children}
        </Card>
      </WrapperComponent>
    </div>
  );
});

StatusCard.propTypes = propTypes;

export default StatusCard;
