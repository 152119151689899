/* eslint-disable import/first */
import "./dayjs";
import dayjs from "dayjs";
import cookie from "js-cookie";
import isNull from "lodash/isNull";
import omit from "lodash/omit";
import omitBy from "lodash/omitBy";
import date from "./date";
import { canUseDOM } from "./dom";

export const COOKIE_NAME = "listing-filter";

const clearPastDates = (obj = {}) => {
  const checkRes = date.checksIfQueryDatesAreValid(obj);
  return omitBy(
    { ...omit(obj, ["from", "to"]), ...omit(checkRes, ["isValid"]) },
    isNull,
  );
};

const defaultValues = {
  region: "berlin",
  from: dayjs.utc().format("YYYY-MM-DD"),
};

export const get = (req) => {
  if (canUseDOM()) {
    try {
      const persistedFilter = JSON.parse(cookie.get(COOKIE_NAME));
      return clearPastDates(Object.assign({}, defaultValues, persistedFilter));
    } catch (err) {
      return defaultValues;
    }
  } else {
    try {
      const persistedFilter = JSON.parse(req.cookies[COOKIE_NAME]);
      return clearPastDates(Object.assign({}, defaultValues, persistedFilter));
    } catch (error) {
      return defaultValues;
    }
  }
};

export const set = (field, value) => {
  if (canUseDOM()) {
    const persistedFilter = exports.get();
    persistedFilter[field] = value;

    cookie.set(COOKIE_NAME, JSON.stringify(persistedFilter), {
      path: "/",
      expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000), // in one year
    });
  }
};

export const getFromCookies = (cookies) => {
  const cookieValue = cookies[COOKIE_NAME];

  if (typeof cookieValue === "string") {
    let cookieValueParsed;
    try {
      cookieValueParsed = JSON.parse(cookieValue);
    } catch (err) {
      cookieValueParsed = {};
    }

    const clearedPastDates = clearPastDates(cookieValueParsed);
    // if from date is not set, we don't want a to dates
    if (clearedPastDates.to && !clearedPastDates.from) {
      clearedPastDates.to = "";
    }

    return { ...clearedPastDates };
  }

  return clearPastDates(cookieValue);
};

export const removeCookie = () => {
  if (canUseDOM()) {
    cookie.remove(COOKIE_NAME);
  }
};
