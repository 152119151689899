import dayjs from "dayjs";
import _isBetween from "dayjs/plugin/isBetween";
import isNull from "lodash/isNull";
import omitBy from "lodash/omitBy";

dayjs.extend(_isBetween);

const checksIfQueryDatesAreValid = ({ from: fromInput, to: toInput }) => {
  const today = dayjs.utc().startOf("day");
  const from = fromInput ? dayjs.utc(fromInput) : null;
  const to = toInput ? dayjs.utc(toInput) : null;

  const res = {
    isValid: true,
    from: from && from.format("YYYY-MM-DD"),
    to: to && to.format("YYYY-MM-DD"),
  };

  if (from && (!from.isValid() || from.isBefore(today))) {
    res.from = dayjs.utc().format("YYYY-MM-DD");
    res.isValid = false;
  }

  if (to && (!to.isValid() || to.isBefore(from) || to.isBefore(today))) {
    res.to = null;
    res.isValid = false;
  }

  return omitBy(res, isNull);
};

const isBetween = (before, date, after) =>
  isBefore(before, date) && isAfter(after, date);

const isAvailableBetween = (minDate, date, maxDate) => {
  const mMinDateUTC = dayjs.utc(minDate);
  const mDateUTC = dayjs.utc(date);
  const mMaxDateUTC = dayjs.utc(maxDate);

  const minDateIsUndefined = !minDate;
  const maxDateIsUndefined = !maxDate;
  const dateAfterOrSameAsMin =
    minDateIsUndefined ||
    mDateUTC.isAfter(mMinDateUTC) ||
    mDateUTC.isSame(mMinDateUTC, "day");
  const dateBeforeOrSameAsMax =
    maxDateIsUndefined ||
    mDateUTC.isBefore(mMaxDateUTC) ||
    mDateUTC.isSame(mMaxDateUTC, "day");

  return dateAfterOrSameAsMin && dateBeforeOrSameAsMax;
};

function isAfter(date, after) {
  if (!after) return false;
  const now = dayjs.utc(date);
  const max = dayjs.utc(after);
  return now.isAfter(max) || now.isSame(max);
}

function isBefore(date, before) {
  if (!before) return false;
  const now = dayjs.utc(date);
  const min = dayjs.utc(before);
  return now.isBefore(min) || now.isSame(min);
}

const datePropTypeFactory =
  (isRequired) => (props, propName, componentName) => {
    if (props[propName] == null && isRequired) {
      console.error(
        `Expected prop '${propName}' to be defined in ${componentName}.`,
      );
    } else if (!/\d{4}-\d{2}-\d{2}/.test(props[propName])) {
      return new Error(
        `Invalid date prop '${propName}' supplied to ${componentName}. Expected format YYYY-MM-DD.`,
      );
    }
  };

const datePropType = datePropTypeFactory(false);
datePropType.isRequired = datePropTypeFactory(true);

export default {
  datePropType,
  isAfter,
  isBefore,
  isAvailableBetween,
  isBetween,
  checksIfQueryDatesAreValid,
};
