import { checkAvailability } from "../../../../utils/shortlist/shortlist";
import shortlistLoaderFactory from "./shortlistLoaderFactory";

export default async ({ api, user, cookies, query }) => {
  // Input api, query, cookies, user
  // Output from, to, shortlistData, userName
  const loader = shortlistLoaderFactory({
    api,
    user,
    cookies,
    query,
  }).getLoader();
  const { from, to, shortlistData, userName } = await loader.loadData();

  // One of the dates is missing, no way to check AV so just return all the listings
  if (!from || !to) {
    return {
      shortlistData,
      from,
      to,
      userName,
    };
  }

  const shortlistDataWithAVCheck = await checkAvailability(
    shortlistData,
    from,
    to,
    api,
  );

  return {
    shortlistData: shortlistDataWithAVCheck,
    from,
    to,
    userName,
  };
};
