import React from "react";
import PropTypes from "prop-types";

const Search = ({ width = 26, height = 26 }) => (
  <svg
    height={height}
    viewBox="0 0 26 26"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <circle cx="15" cy="10.81" r="7.5" fill="#fff" />
      <path d="M15 2.31a8.5 8.5 0 108.5 8.5 8.51 8.51 0 00-8.5-8.5zm0 15a6.5 6.5 0 116.5-6.5 6.51 6.51 0 01-6.5 6.5zM2.64 21.62l1.41 1.42 4.13-4.13.11-1.53-1.53.12-4.12 4.12z" />
    </g>
  </svg>
);

Search.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default Search;
