import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";
import { AuthContext } from "../../../../contexts/AuthContext";
// Links
import BecomeALandlordLink from "../../Links/BecomeALandlordLink";
import DiscoverLink from "../../Links/DiscoverLink";
import Help from "../../Links/HelpLink";
import InviteLandlords from "../../Links/InviteLandlords";
import LandlordDashboardLink from "../../Links/LandlordDashboardLink";
import LogoutLink from "../../Links/LogoutLink";
import MyAccountLink from "../../Links/MyAccountLink";
import MyBookingsLink from "../../Links/MyBookingsLink";
import ShortlistLink from "../../Links/ShortlistLink";
import LanguageSwitch from "./LanguageSwitch";

const LoggedInUser = ({
  currentUrl,
  analyticsEvent,
  lang,
  t,
  translationUrls,
  url,
  user,
}) => {
  const { userActionsCount } = useContext(AuthContext);
  const totalUserActions = userActionsCount?.totalUserActions;
  const accountActions = userActionsCount?.accountActions;
  const bookingActions = userActionsCount?.bookingActions;

  const showMyBookingsLink =
    !user.landlord || (user.landlord && user.hasTenantBookingRequests);

  return (
    <Fragment>
      <div className="AppHeaderDrawer-avatar">
        {user.firstName ? user.firstName.charAt(0) : "-"}
      </div>
      <h4 className="AppHeaderDrawer-name">
        {user.firstName && user.lastName
          ? `${user.firstName} ${user.lastName}`
          : "-"}
      </h4>

      {totalUserActions > 0 && (
        <p className="AppHeaderDrawer-warning">
          {t("pleaseCompleteYourAccount")}
        </p>
      )}

      <ul className="AppHeaderDrawer-LinkList">
        {user.landlord && (
          <li className="AppHeaderDrawer-LinkList-item">
            <LandlordDashboardLink
              expand
              lang={lang}
              showIcon
              url={url}
              analyticsEvent={analyticsEvent}
            />
          </li>
        )}

        {user.landlord && (
          <li className="AppHeaderDrawer-LinkList-item">
            <InviteLandlords
              showIcon
              t={t}
              url={url}
              user={user}
              analyticsEvent={analyticsEvent}
            />
          </li>
        )}

        <li className="AppHeaderDrawer-LinkList-item">
          <MyAccountLink
            currentUrl={currentUrl}
            expand
            showIcon
            t={t}
            url={url}
            notifications={accountActions}
            analyticsEvent={analyticsEvent}
          />
        </li>

        {showMyBookingsLink && (
          <li className="AppHeaderDrawer-LinkList-item">
            <MyBookingsLink
              currentUrl={currentUrl}
              expand
              notifications={bookingActions}
              t={t}
              url={url}
              showIcon
              analyticsEvent={analyticsEvent}
            />
          </li>
        )}

        <li className="AppHeaderDrawer-LinkList-item">
          <DiscoverLink
            currentUrl={currentUrl}
            expand
            lang={lang}
            showIcon
            t={t}
            url={url}
            analyticsEvent={analyticsEvent}
          />
        </li>

        <li className="AppHeaderDrawer-LinkList-item">
          <ShortlistLink
            currentUrl={currentUrl}
            expand
            showIcon
            t={t}
            url={url}
            analyticsEvent={analyticsEvent}
          />
        </li>
        {!user.landlord && (
          <li className="AppHeaderDrawer-LinkList-item">
            <BecomeALandlordLink url={url} showIcon />
          </li>
        )}
      </ul>

      <ul className="AppHeaderDrawer-LinkList AppHeaderDrawer-LinkList-bottom">
        <li className="AppHeaderDrawer-LinkList-item">
          <Help t={t} url={url} lang={lang} currentUrl={currentUrl} />
        </li>

        {translationUrls && (
          <li className="AppHeaderDrawer-LinkList-item">
            <LanguageSwitch lang={lang} translationUrls={translationUrls} />
          </li>
        )}

        <li className="AppHeaderDrawer-LinkList-item">
          <LogoutLink
            t={t}
            analyticsEvent={analyticsEvent}
            url={url}
            lang={lang}
            currentUrl={currentUrl}
          />
        </li>
      </ul>
    </Fragment>
  );
};

LoggedInUser.propTypes = {
  currentUrl: PropTypes.string,
  analyticsEvent: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  translationUrls: PropTypes.shape({
    de: PropTypes.string.isRequired,
    en: PropTypes.string.isRequired,
  }),
  url: PropTypes.func.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    hasTenantBookingRequests: PropTypes.bool,
    landlord: PropTypes.bool,
    lastName: PropTypes.string,
  }).isRequired,
};

export default LoggedInUser;
