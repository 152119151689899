const factory = (http, httpFrontend) => {
  function sendPasswordResetLink(payload) {
    return http.post("/users/password-reset-token", payload);
  }

  function validateResetPasswordToken(payload) {
    return httpFrontend.post("/users/validate-reset-password-token", payload);
  }

  function resetPassword({ reCaptchaToken, password }) {
    return httpFrontend.post("/users/reset-password", {
      reCaptchaToken,
      password,
    });
  }

  function getUserInfo() {
    return http.get("/users/me");
  }

  function updateUser(userId, payload) {
    return http.put(`/users/${userId}`, payload);
  }

  function getListingsCount() {
    return http.get("/listings/count");
  }

  function getStats() {
    return http.get("/stats");
  }
  function getJobAds() {
    return http.get("/job-ads");
  }

  return {
    sendPasswordResetLink,
    validateResetPasswordToken,
    resetPassword,
    getUserInfo,
    updateUser,
    getListingsCount,
    getStats,
    getJobAds,
  };
};

export default factory;
