import React from "react";
import PropTypes from "prop-types";

const UkrainianFlag = ({ height = 22, width = 22, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_2081_359"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width={width}
      height={height}
    >
      <circle cx="10" cy="10" r="10" fill="#C4C4C4" />
    </mask>
    <g mask="url(#mask0_2081_359)">
      <rect width="20" height="10" fill="#005BBB" />
      <rect y="10" width="20" height="10" fill="#FFD500" />
    </g>
  </svg>
);

UkrainianFlag.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  className: PropTypes.string,
};

export default UkrainianFlag;
