import React from "react";
import PropTypes from "prop-types";

const RussianFlag = ({ height = 22, width = 22 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_2111_373"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="22"
      height="22"
    >
      <circle cx="11" cy="11" r="11" fill="#C4C4C4" />
    </mask>
    <g mask="url(#mask0_2111_373)">
      <rect width="22" height="7.33333" fill="#F0F0F0" />
      <rect y="7.33325" width="22" height="7.33333" fill="#0052B4" />
      <rect y="14.6667" width="22" height="7.33333" fill="#D80027" />
    </g>
  </svg>
);

RussianFlag.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default RussianFlag;
