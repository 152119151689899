import React from "react";
import { loadableReady } from "@loadable/component";
import { QueryClientProvider } from "@tanstack/react-query";
import { hydrateRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import bootstrapClient from "../bootstrap-client";
import queryClient from "./queryClient";
import { App } from "./SpaPage";

// Rehydrate data that was sent down from the server
const LOCALE_DATA = JSON.parse(document.querySelector("#locales-hydrant").text);
const APP_DATA = JSON.parse(document.querySelector("#data-hydrant").text);

bootstrapClient(LOCALE_DATA, APP_DATA, onReady);

function onReady({ analytics, api, url, localizer, featureFlags, tracker }) {
  loadableReady(() => {
    hydrateRoot(
      document.querySelector("#main"),
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <App
            {...APP_DATA}
            api={api}
            analytics={analytics}
            lang={localizer.lang}
            url={url}
            featureFlags={featureFlags}
            tracker={tracker}
          />
        </QueryClientProvider>
      </BrowserRouter>,
    );
  });
}
