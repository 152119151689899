import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { getDisplayName } from "../../../utils/react-utils";
import { url } from "../../../utils/url";

const RouterUtilsContext = React.createContext({ url });

const providerPropTypes = {
  url: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export function RouterUtilsContextProvider({ url, children }) {
  const contextValue = useMemo(() => ({ url }), [url]);

  return (
    <RouterUtilsContext.Provider value={contextValue}>
      {children}
    </RouterUtilsContext.Provider>
  );
}

RouterUtilsContextProvider.propTypes = providerPropTypes;

export function useRouterUtils() {
  return useContext(RouterUtilsContext);
}

export function withRouterUtils(Component) {
  function WrappedComponent(props) {
    const routerUtilsContextValue = useRouterUtils();
    return <Component {...props} {...routerUtilsContextValue} />;
  }

  // Assign static properties like propTypes:
  for (const key of Object.keys(Component)) {
    WrappedComponent[key] = Component[key];
  }

  WrappedComponent.displayName = `WithRouterUtils(${getDisplayName(
    Component,
  )})`;

  return WrappedComponent;
}
