import React from "react";
import PropTypes from "prop-types";
import AppHeaderUserMenu from "./AppHeaderUserMenu/AppHeaderUserMenu";
import DesktopNavigationLinks from "./DesktopNavigationLinks/DesktopNavigationLinks";
import LanguageDropdown from "./LanguageDropdown/LanguageDropdown";
import SignInLink from "./Links/SignInLink";

const AppHeaderNavigation = ({ ...props }) => {
  const {
    currentUrl,
    analyticsEvent,
    lang,
    t,
    translationUrls,
    url,
    user,
    showLandlordLinks,
  } = props;

  return (
    <nav className="AppHeaderNavigation">
      <DesktopNavigationLinks
        currentUrl={currentUrl}
        analyticsEvent={analyticsEvent}
        url={url}
        user={user}
        showLandlordLinks={showLandlordLinks}
      />

      <ul className="AppHeaderNavigation-List">
        {/*
         * ErrorPage doesn't have translationUrls.
         * Therefore we can't render LanguageDropdown in ErrorPage.
         */}
        {translationUrls && (
          <li className="AppHeaderNavigation-ListItem AppHeaderNavigation-ListItem--languageDropdown">
            <LanguageDropdown lang={lang} translationUrls={translationUrls} />
          </li>
        )}

        <li className="AppHeaderNavigation-ListItem">
          <AppHeaderUserMenu
            currentUrl={currentUrl}
            analyticsEvent={analyticsEvent}
            lang={lang}
            t={t}
            translationUrls={translationUrls}
            url={url}
            user={user}
          />
        </li>

        {!user && (
          <li className="AppHeaderNavigation-ListItem AppHeaderNavigation-ListItem-signInLink">
            <SignInLink currentUrl={currentUrl} t={t} url={url} />
          </li>
        )}
      </ul>
    </nav>
  );
};

AppHeaderNavigation.propTypes = {
  currentUrl: PropTypes.string,
  analyticsEvent: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  translationUrls: PropTypes.shape({
    en: PropTypes.string.isRequired,
    de: PropTypes.string.isRequired,
  }),
  url: PropTypes.func.isRequired,
  user: PropTypes.object,
  showLandlordLinks: PropTypes.bool,
};

export default AppHeaderNavigation;
