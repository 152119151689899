export const actions = {
  SET_USER: "SET_USER",

  // Login with email & password
  LOGIN_LOADING: "LOGIN_LOADING",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",

  // Forgot password
  FORGOT_PASSWORD_LOADING: "FORGOT_PASSWORD_LOADING",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_ERROR: "FORGOT_PASSWORD_ERROR",

  // Signup
  SIGNUP_LOADING: "SIGNUP_LOADING",
  SIGNUP_ERROR: "SIGNUP_ERROR",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",

  // User Actions
  USER_ACTIONS_COUNT: "USER_ACTIONS_COUNT",
};

export const INITIAL_STATE = {
  user: null,
  loginLoading: false,
  loginError: null,
  signupLoading: false,
  signupError: null,
  forgotPasswordSuccess: false,
  forgotPasswordLoading: false,
  forgotPasswordError: null,
  userActions: null,
};

export const authReducer = (state, action) => {
  switch (action.type) {
    case actions.SET_USER:
      return {
        ...state,
        user: action.payload,
        loginError: null,
        loginLoading: false,
      };

    case actions.LOGIN_LOADING:
      return {
        ...state,
        user: null,
        loginLoading: true,
        loginError: null,
      };

    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loginLoading: false,
        loginError: null,
      };

    case actions.LOGIN_ERROR:
      return {
        ...state,
        user: null,
        loginLoading: false,
        loginError: action.error,
      };

    case actions.FORGOT_PASSWORD_LOADING:
      return {
        ...state,
        forgotPasswordLoading: true,
        forgotPasswordSuccess: false,
        forgotPasswordError: null,
      };

    case actions.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordLoading: false,
        forgotPasswordSuccess: true,
        forgotPasswordError: null,
      };

    case actions.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        forgotPasswordLoading: false,
        forgotPasswordSuccess: false,
        forgotPasswordError: action.error,
      };

    case actions.SIGNUP_LOADING:
      return {
        ...state,
        user: null,
        signupLoading: true,
        signupError: false,
      };

    case actions.SIGNUP_ERROR:
      return {
        ...state,
        user: null,
        signupLoading: false,
        signupError: action.error,
      };

    case actions.SIGNUP_SUCCESS:
      return {
        ...state,
        user: action.user,
        signupLoading: false,
        signupError: null,
      };

    case actions.USER_ACTIONS_COUNT:
      return {
        ...state,
        userActionsCount: action.payload,
      };

    default:
      return state;
  }
};
