import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useSpaPage } from "../../../spages/spa/context/SpaPageContext";

const SignInLink = ({ t, url, currentUrl }) => {
  // Use Link component if we're in the SPA, otherwise use a element.
  const { isSpaPage } = useSpaPage();

  // If `currentUrl` exists then parse it and pass as a redirect.
  let loginRoute = url("login");
  if (currentUrl) {
    const parsedCurrentURL = new URL(currentUrl);
    const redirect = parsedCurrentURL.pathname + parsedCurrentURL.search;

    loginRoute = url("login", {}, { query: { redirect } });
  }

  return (
    <Fragment>
      {isSpaPage ? (
        <Link to={loginRoute} className="AppHeader-SignInLink">
          {t("components.AppHeader.signIn")}
        </Link>
      ) : (
        <a href={loginRoute} className="AppHeader-SignInLink">
          {t("components.AppHeader.signIn")}
        </a>
      )}
    </Fragment>
  );
};

SignInLink.propTypes = {
  currentUrl: PropTypes.string,
  t: PropTypes.func.isRequired,
  url: PropTypes.func.isRequired,
};

export default SignInLink;
