import React from "react";
import propTypes from "prop-types";
import Overlay from "../Overlay/Overlay";
import Spinner from "../SvgSpinner/SvgSpinner";

import "./LoadingSpinner.scss";

const LoadingSpinner = ({ className, showOverlay = true, ...otherProps }) => {
  return (
    <div
      data-testid="LoadingSpinner"
      className={`SpinnerContainer${className ? ` ${className}` : ""}`}
    >
      {showOverlay && <Overlay />}
      <Spinner {...otherProps} />
    </div>
  );
};

LoadingSpinner.propTypes = {
  showOverlay: propTypes.bool,
  className: propTypes.string,
};

export default LoadingSpinner;
