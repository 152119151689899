import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  customClassName: PropTypes.string,
};

/* Accordion-details-summary-wrapper is needed to fix a Safari bug (summary can't be a flex container)
   Read more: https://github.com/philipwalton/flexbugs#9-some-html-elements-cant-be-flex-containers */

const AccordionSection = ({
  title,
  children,
  customClassName = "Accordion",
}) => {
  const childrenWithProps = React.Children.map(children, (child) => {
    const props = { customClassName };
    if (React.isValidElement(child)) {
      return React.cloneElement(child, props);
    }
    return child;
  });

  return (
    <details
      data-testid={`${customClassName}-details-${title}`}
      className={`${customClassName}-details`}
    >
      <summary className={`${customClassName}-details-summary`}>
        <div className={`${customClassName}-details-summary-wrapper`}>
          {title}
          <span className={`${customClassName}-details-summary-arrowContainer`}>
            <span
              className={`${customClassName}-details-summary-arrowContainer-arrow`}
            />
          </span>
        </div>
      </summary>
      {childrenWithProps}
    </details>
  );
};

AccordionSection.propTypes = propTypes;

export default AccordionSection;
