import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";

const CurrentRouteContext = React.createContext({
  currentPath: "",
});

const providerPropTypes = {
  currentPath: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export function CurrentRouteContextProvider({ currentPath, children }) {
  const contextValue = useMemo(() => ({ currentPath }), [currentPath]);

  return (
    <CurrentRouteContext.Provider value={contextValue}>
      {children}
    </CurrentRouteContext.Provider>
  );
}

CurrentRouteContextProvider.propTypes = providerPropTypes;

export function useCurrentRoute() {
  return useContext(CurrentRouteContext);
}
