import React from "react";
import PropTypes from "prop-types";
import ForgotPasswordForm from "../ForgotPasswordForm/ForgotPasswordForm";
import Modal from "../Modal/Modal";

class ForgotPasswordModal extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
    loading: PropTypes.bool,
    success: PropTypes.bool,
    error: PropTypes.object,
    noModal: PropTypes.bool,
    url: PropTypes.func.isRequired,
  };

  render() {
    const t = this.props.t;
    const url = this.props.url;
    const error = this.props.error;
    const success = this.props.success;
    const noModal = this.props.noModal;
    const validationError =
      error && error.name === "ValidationError" ? error : null;

    const errorMessage = (() => {
      if (!error) return null;

      if (error.name === "ResourceNotFoundError") {
        return (
          <p className="error-message">
            {t("components.ForgotPasswordModal.errorMessage.unknownEmail")}
          </p>
        );
      }
    })();

    const className = noModal
      ? "ForgotPasswordModal ForgotPasswordModal--noModal"
      : "ForgotPasswordModal";

    const body = (
      <div className={className}>
        <div className="Modal-card">
          <h4 className="heading-small heading-small--centered">
            {t("components.ForgotPasswordModal.title")}
          </h4>
          {errorMessage}
          {success && (
            <p className="success-message">
              {t("components.ForgotPasswordModal.successMessage")}
            </p>
          )}
          {!success && (
            <ForgotPasswordForm
              t={t}
              onSubmit={this.props.onSubmit}
              error={validationError}
            />
          )}
        </div>
        <div className="Modal-footer">
          <span>{t("components.ForgotPasswordModal.haveAccount")}</span>
          <a className="Modal-footer-link" href={url("login")}>
            {t("components.ForgotPasswordModal.login")}
          </a>
        </div>
      </div>
    );

    if (noModal) {
      return body;
    }

    return <Modal onClose={this.props.onClose}>{body}</Modal>;
  }
}

export default ForgotPasswordModal;
