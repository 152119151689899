import { useEffect, useState } from "react";

// https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia
function useMedia(query) {
  if (typeof window === "undefined") {
    return null;
  }

  const mediaQuery = window.matchMedia(query);
  if (mediaQuery === "undefined") {
    return null;
  }
  // Disabled hooks linting because I wanted to get the lint-rule
  // in without changing the semantics of existing code.
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isMatched, setIsMatched] = useState(mediaQuery.matches);

  // Disabled hooks linting because I wanted to get the lint-rule
  // in without changing the semantics of existing code.
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const onChange = () => {
      setIsMatched(mediaQuery.matches);
    };

    // MediaQueryList.addEventListener doesn't work in Safari and IE
    // That's why we're using "addListener" instead.
    mediaQuery.addListener(onChange);

    return () => {
      mediaQuery.removeListener(onChange);
    };
  }, [mediaQuery]);

  return isMatched;
}

export default useMedia;
