import promiseMemo from "promise-memoize";

export default ({ contentPage }) => {
  return promiseMemo(
    async ({ params: { lang }, query: { country = "de" } = {}, api }) => {
      const documentRawString = await api.documents.getDocument({
        lang,
        name: contentPage,
        country,
      });
      return {
        documentRawString,
      };
    },
    { maxAge: 60000 },
  );
};
