/* eslint-disable import/prefer-default-export */

export const APP_ID = "website";
export const NAMESPACE = "website-tracker";

export const funnelKey = {
  REQUEST_TO_BOOK: "REQUEST_TO_BOOK",
  CREATE_LISTING: "CREATE_LISTING",
};

export const listingsSearchResultClickPlacement = {
  MAP: "MAP",
  LIST: "LIST",
};

export const globalEntityType = {
  USER: "USER",
  PAGE: "PAGE",
};

export const apartmentSizes = {
  COZY: "COZY",
  COMFORTABLE: "COMFORTABLE",
  SPACIOUS: "SPACIOUS",
  OTHER: "OTHER",
};

export const listingSearchCollection = {
  FAMILY: "FAMILY",
  WORK: "WORK",
  STUDENT: "STUDENT",
  BALCONY: "BALCONY",
  LUXURY: "LUXURY",
  PET_FRIENDLY: "PET_FRIENDLY",
  TECH_STUDIO: "TECH_STUDIO",
  OTHER: "OTHER",
};

export const schemas = {
  incompleteAccount:
    "iglu:com.wunderflats/account_completion_attempted/jsonschema/1-0-0",
  incompleteProfile:
    "iglu:com.wunderflats/tenant_profile_completion_started/jsonschema/1-0-0",
  incompleteDocuments:
    "iglu:com.wunderflats/upload_verification_documents_requested/jsonschema/1-0-0",
};

export const LANDLORD_INTENT_LOCATION = {
  HEADER: "header",
  FOOTER: "footer",
  HOMEPAGE_CARD: "homepage_card",
};
