import React from "react";
import PropTypes from "prop-types";
import { getFlagIcon } from "../../utils/phoneNumbers";

const CustomPhoneNumberInputDefault = ({
  onFocus,
  onBlur,
  selectedCountry,
  dialCode,
  phoneNumber,
  onBlurDialCode,
  onBlurPhoneNumber,
  onChangeDialCode,
  onChangePhoneNumber,
  onSelectCountry,
  sortedDialCodes,
  className,
}) => {
  const flag = getFlagIcon(selectedCountry);

  return (
    <div className={className}>
      <div className="CustomPhoneNumberInput-dialCode-container Input">
        <div className="CustomPhoneNumberInput-country">
          <select
            className="CustomPhoneNumberInput-country-select"
            data-testid="PhoneCountrySelect"
            value={selectedCountry}
            onChange={(e) => onSelectCountry(e.target.value)}
            onFocus={onFocus}
            onBlur={onBlur}
          >
            {sortedDialCodes.map((value) => (
              <option key={value.code} value={value.code}>
                {value.countryName}
              </option>
            ))}
          </select>
          <figure
            className={`CustomPhoneNumberInput-country-flag ${
              !selectedCountry
                ? "CustomPhoneNumberInput-country-flag--planet"
                : ""
            }`}
          >
            <img alt={selectedCountry} src={flag} />
          </figure>
        </div>
        <span className="CustomPhoneNumberInput-dialCode-icon" />
        <input
          type="tel"
          maxLength="5"
          placeholder="49"
          pattern="[0-9]*"
          className="Input CustomPhoneNumberInput-textInput CustomPhoneNumberInput-dialCode qa-dialCode"
          data-testid="dialCode"
          onChange={(e) => onChangeDialCode(e.target.value)}
          value={dialCode}
          onFocus={onFocus}
          onBlur={onBlurDialCode}
        />
      </div>
      <div className="CustomPhoneNumberInput-phoneNumber-container Input">
        <input
          type="tel"
          maxLength="15"
          placeholder="987654321"
          className="Input CustomPhoneNumberInput-textInput CustomPhoneNumberInput-phoneNumber qa-phoneNumber"
          data-testid="phoneNumber"
          onChange={(e) => onChangePhoneNumber(e.target.value)}
          value={phoneNumber}
          onFocus={onFocus}
          onBlur={onBlurPhoneNumber}
        />
      </div>
    </div>
  );
};

CustomPhoneNumberInputDefault.propTypes = {
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  focused: PropTypes.bool,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  selectedCountry: PropTypes.string,
  dialCode: PropTypes.string,
  phoneNumber: PropTypes.string,
  onBlurDialCode: PropTypes.func,
  onBlurPhoneNumber: PropTypes.func,
  onChangeDialCode: PropTypes.func,
  onChangePhoneNumber: PropTypes.func,
  onSelectCountry: PropTypes.func,
  sortedDialCodes: PropTypes.array,
  className: PropTypes.string,
};

export default CustomPhoneNumberInputDefault;
