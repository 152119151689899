/* eslint-disable graphql/template-strings */
import gql from "./gql";

const factory = (http) => {
  return {
    async getCostCommission({ fee, price, from, to }) {
      const {
        data: {
          costCommission: { commission },
        },
      } = await http.post("/nexus", {
        query: gql`
          query costCommission(
            $fee: Int!
            $price: Int!
            $from: Date!
            $to: Date!
          ) {
            costCommission(fee: $fee, price: $price, from: $from, to: $to) {
              commission
            }
          }
        `,
        variables: {
          from,
          to,
          fee,
          price,
        },
      });

      return commission;
    },

    async getCostSummary({
      price,
      from,
      to,
      deposit,
      firstMonthsRent,
      lastMonthsRent,
    }) {
      const {
        data: { costSummary },
      } = await http.post("/nexus", {
        query: gql`
          query costSummary(
            $from: Date!
            $to: Date!
            $price: Int!
            $deposit: Int!
            $firstMonthsRent: Int
            $lastMonthsRent: Int
          ) {
            costSummary(
              from: $from
              to: $to
              price: $price
              deposit: $deposit
              firstMonthsRent: $firstMonthsRent
              lastMonthsRent: $lastMonthsRent
            ) {
              instalment
              months {
                month
                price
              }
              deposit
              rent
            }
          }
        `,
        variables: {
          from,
          to,
          price,
          deposit,
          firstMonthsRent,
          lastMonthsRent,
        },
      });

      return costSummary;
    },
  };
};

export default factory;
