import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useI18n } from "../../../spages/spa/context/I18nContext";
import DashboardIcon from "../../Icons/Dashboard";

const LandlordDashboardLink = ({
  expand = false,
  lang,
  showIcon = false,
  url,
  analyticsEvent,
}) => {
  const { t } = useI18n();
  return (
    <a
      href={url("dashboard", { lang })}
      className={classNames("AppHeader-Link", {
        "AppHeader-Link--expand": expand,
      })}
      data-testid="AppHeader-Link-dashboard"
      onClick={() => {
        analyticsEvent("Menu", "menu_click_dashboard");
      }}
    >
      {showIcon && (
        <span className="AppHeader-Link-icon">
          <DashboardIcon />
        </span>
      )}

      <span className="AppHeader-Link-text">
        {t("components.AppHeader.dashboard")}
      </span>
    </a>
  );
};

LandlordDashboardLink.propTypes = {
  expand: PropTypes.bool,
  lang: PropTypes.string.isRequired,
  showIcon: PropTypes.bool,
  url: PropTypes.func.isRequired,
  analyticsEvent: PropTypes.func.isRequired,
};

export default LandlordDashboardLink;
