import React from "react";
import PropTypes from "prop-types";

const AccordionContent = ({ children }) => {
  return <div className="Accordion-details-answer">{children}</div>;
};

AccordionContent.propTypes = {
  children: PropTypes.node,
};

export default AccordionContent;
