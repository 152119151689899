import faqsApiClient from "../../../../../api/faqsApi";
import { throwRedirectErrorIfUnsupportedLang } from "../utils/languageSupport";

export default async ({ params, url }) => {
  throwRedirectErrorIfUnsupportedLang({ params, lang: params.lang, url });

  const faqsApi = faqsApiClient();

  try {
    const faqs = await faqsApi.getFaqs({ language: params.lang });

    const categories = faqs.categories;

    const categoryId = String(params.categoryId || categories[0]?.id);

    const subcategories = faqs.subcategories.filter(
      (subcategory) => String(subcategory.category_id) === categoryId,
    );

    return {
      categories,
      subcategories,
      posts: faqs?.posts,
    };
  } catch (error) {
    return {
      categories: [],
      subcategories: [],
      posts: [],
    };
  }
};
