import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";
import LoggedInUserMenu from "./LoggedInUserMenu/LoggedInUserMenu";
import NotLoggedInUserMenu from "./NotLoggedInUserMenu";

const AppHeaderDesktopUserMenu = forwardRef(
  ({ currentUrl, analyticsEvent, isOpen, lang, t, user, url }, ref) => (
    <CSSTransition
      in={isOpen}
      timeout={150}
      classNames="AppHeader-DropdownTransition"
      unmountOnExit
    >
      <div
        className={classNames("AppHeaderDesktopUserMenu", {
          "AppHeaderDesktopUserMenu--loggedIn": user,
        })}
        ref={ref}
      >
        {user && (
          <LoggedInUserMenu
            currentUrl={currentUrl}
            analyticsEvent={analyticsEvent}
            lang={lang}
            t={t}
            url={url}
            user={user}
          />
        )}

        {!user && (
          <NotLoggedInUserMenu
            currentUrl={currentUrl}
            analyticsEvent={analyticsEvent}
            lang={lang}
            t={t}
            url={url}
          />
        )}
      </div>
    </CSSTransition>
  ),
);

AppHeaderDesktopUserMenu.propTypes = {
  currentUrl: PropTypes.string,
  analyticsEvent: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  lang: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  url: PropTypes.func.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    landlord: PropTypes.bool,
    lastName: PropTypes.string,
  }),
};

export default AppHeaderDesktopUserMenu;
