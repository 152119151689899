import React, { useCallback, useEffect, useState } from "react";
import getWFCONFIG from "../../../utils/WF_CONFIG";

const WF_CONFIG = getWFCONFIG();

export const IS_LOCALISE_ENABLED_STORAGE_KEY =
  "IS_LOCALISE_ENABLED_STORAGE_KEY";

const isCorrectUser = (user) => {
  return user && user?.permissions?.includes("LOCALISATION");
};

export const useLokalise = ({
  locale = "en",
  projectId = WF_CONFIG.LOKALISE_PROJECT_ID,
  user,
} = {}) => {
  const [isLokaliseEnabled, setIsLokaliseEnabled] = useState(false);

  useEffect(() => {
    const isEnabled =
      localStorage.getItem(IS_LOCALISE_ENABLED_STORAGE_KEY) === "true";
    setIsLokaliseEnabled(isEnabled);
  }, [user]);

  useEffect(() => {
    if (isLokaliseEnabled) {
      // --------------------------------------------------------------
      // THIS CODE IS COPIED FROM https://docs.lokalise.com/en/articles/1592525-livejs-web-in-context-editor
      // Keep the code the same as in the docs to make it more easier to maintain
      window.LOKALISE_CONFIG = {
        projectId,
        locale,
        disableAdvancedHtml: false,
        plainKey: true,
      };
      const a = document.createElement("script");
      a.type = "text/javascript";
      a.async = !0;
      a.src = [
        "https://app.lokalise.com/live-js/script.js?",
        new Date().getTime(),
      ].join("");
      document.body.appendChild(a);
    }

    // --------------------------------------------------------------
  }, [locale, projectId, isLokaliseEnabled]);

  return {
    isLokaliseEnabled,
  };
};

export const useLokaliseMenuBtnHook = (user) => {
  const [showLokaliseBtn, setShowLokaliseBtn] = useState(false);

  useEffect(() => {
    // TODO show only if the user has a specific role
    if (isCorrectUser(user)) {
      setShowLokaliseBtn(true);
    }
  }, [user]);

  const renderLokaliseBtn = useCallback(() => {
    const isLocaliseEnabled =
      localStorage.getItem(IS_LOCALISE_ENABLED_STORAGE_KEY) === "true";
    return (
      showLokaliseBtn && (
        <li className="AppHeaderDesktopUserMenu-LinkList-item">
          <a
            className="AppHeader-Link"
            data-testid="Lokalise-toggle"
            onClick={() => {
              if (isLocaliseEnabled) {
                localStorage.removeItem(IS_LOCALISE_ENABLED_STORAGE_KEY);
              } else {
                localStorage.setItem(IS_LOCALISE_ENABLED_STORAGE_KEY, true);
              }

              // eslint-disable-next-line local/no-window-location
              window.location.href = "/";
            }}
          >
            {isLocaliseEnabled
              ? "Disable in-context localisation"
              : "Enable in-context localisation"}
          </a>
        </li>
      )
    );
  }, [showLokaliseBtn]);

  return {
    renderLokaliseBtn,
    showLokaliseBtn,
  };
};

export const refreshLokaliseKeys = () => {
  document.dispatchEvent(new Event("lokalise-update-elements"));
};
