import React from "react";
import PropTypes from "prop-types";

const TooltipIcon = ({ width = 18, height = 18 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="9"
        cy="8.87879"
        rx="8"
        ry="7.87879"
        stroke="#24272E"
        strokeWidth="1.5"
      />
      <path
        d="M9.70898 6.66016V13H8.29102V6.66016H9.70898ZM8.19727 4.99609C8.19727 4.78125 8.26758 4.60352 8.4082 4.46289C8.55273 4.31836 8.75195 4.24609 9.00586 4.24609C9.25586 4.24609 9.45312 4.31836 9.59766 4.46289C9.74219 4.60352 9.81445 4.78125 9.81445 4.99609C9.81445 5.20703 9.74219 5.38281 9.59766 5.52344C9.45312 5.66406 9.25586 5.73438 9.00586 5.73438C8.75195 5.73438 8.55273 5.66406 8.4082 5.52344C8.26758 5.38281 8.19727 5.20703 8.19727 4.99609Z"
        fill="#24272E"
      />
    </svg>
  );
};

TooltipIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default TooltipIcon;
