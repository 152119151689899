import React from "react";
import PropTypes from "prop-types";

const LogoPlus = ({ width = 26, height = 26 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 2.28889L17.155 7.975V15.5556L14.25 17.7139L9.5 14.1833L4.75 17.7139L1.84785 15.5556V7.975L9.5 2.28889ZM9.5 0L0 7.06667V16.4694L4.75 20L9.5 16.4694L14.25 20L19 16.4694V7.06667L9.5 0Z"
        fill="black"
      />
      <path
        d="M9.49981 2.28906L17.1548 7.97517V15.5557L14.2498 17.7141L9.49981 14.1835L4.74981 17.7141L1.84766 15.5557V7.97517L9.49981 2.28906Z"
        fill="white"
      />
      <path
        d="M19 25C22.866 25 26 21.866 26 18C26 14.134 22.866 11 19 11C15.134 11 12 14.134 12 18C12 21.866 15.134 25 19 25Z"
        fill="white"
      />
      <path
        d="M19 23C21.7614 23 24 20.7614 24 18C24 15.2386 21.7614 13 19 13C16.2386 13 14 15.2386 14 18C14 20.7614 16.2386 23 19 23Z"
        fill="white"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M21.1301 17.5801H16.8701V18.4301H21.1301V17.5801Z"
        fill="black"
      />
      <path
        d="M19.4203 15.8799H18.5703V20.1399H19.4203V15.8799Z"
        fill="black"
      />
    </svg>
  );
};

LogoPlus.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default LogoPlus;
