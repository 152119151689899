/* eslint-disable import/prefer-default-export */
import RedirectError from "../../../../../errors/redirectError";
import { supportedHelpPageLangs } from "../../../../../routes";
import { getHelpRoute } from "../../../../../utils/url";

export const throwRedirectErrorIfUnsupportedLang = ({
  params = {},
  lang = "en",
  url,
}) => {
  if (!supportedHelpPageLangs.includes(lang)) {
    const route = getHelpRoute(params);
    const redirectTo = url(route, { ...params, lang: "en" });
    throw new RedirectError({ redirectTo, status: 301 });
  }
};
