import React from "react";
import PropTypes from "prop-types";

const WunderflatsLogoBadge = ({
  id = "WunderflatsLogoBadge",
  fill = "#542EBC",
  height = 36,
  width = 34,
}) => (
  <svg
    className="WunderflatsLogoBadge"
    height={height}
    viewBox="0 0 447.54 475.51"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    id={id}
  >
    <defs>
      <clipPath id="clippath">
        <path
          className="clipPath"
          fill={fill}
          d="m43.51,369.84v-180.26L223.77,54.39l180.26,135.2v180.26l-68.37,51.28-111.89-83.91-111.89,83.91-68.37-51.28ZM0,167.83v223.77l111.89,83.91,111.89-83.91,111.89,83.91,111.89-83.91v-223.77L223.77,0,0,167.83Z"
        />
      </clipPath>
    </defs>
    <path d="m43.51,369.84v-180.26L223.77,54.39l180.26,135.2v180.26l-68.37,51.28-111.89-83.91-111.89,83.91-68.37-51.28ZM0,167.83v223.77l111.89,83.91,111.89-83.91,111.89,83.91,111.89-83.91v-223.77L223.77,0,0,167.83Z" />
    <g clipPath="url(#clippath)">
      <rect
        width="447.54"
        height="475.51"
        fill={fill}
        className="clipPath-second"
      />
    </g>
  </svg>
);

WunderflatsLogoBadge.propTypes = {
  id: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

export default WunderflatsLogoBadge;
