/* eslint-disable import/prefer-default-export */
import {
  enableLinkClickTracking,
  LinkClickTrackingPlugin,
} from "@snowplow/browser-plugin-link-click-tracking";
import {
  addGlobalContexts,
  newTracker,
  removeGlobalContexts,
} from "@snowplow/browser-tracker";
import getWFCONFIG from "../utils/WF_CONFIG";
import { APP_ID, globalEntityType, NAMESPACE } from "./constants";
import { pageEntity, userEntity } from "./entities";

const WF_CONFIG = getWFCONFIG();

let __tracker = null;
const __globalContexts = {};

export { generateFunnelId } from "./utils";

export const init = () => {
  if (__tracker) {
    return __tracker;
  }

  // https://docs.snowplowanalytics.com/docs/collecting-data/collecting-from-own-applications/javascript-trackers/browser-tracker/browser-tracker-v3-reference/tracker-setup/initialization-options
  const snowplowConfig = {
    appId: APP_ID,
    cookieSameSite: "Lax",
    // we use a scrambled post path on prod to avoid content blockers
    // leave undefined for default value
    postPath: WF_CONFIG.NODE_ENV === "production" ? "/67199/cd9" : undefined,
    // Track link click
    /* eslint-disable-next-line new-cap */
    plugins: [LinkClickTrackingPlugin()],
    contexts: {
      session: true,
    },
  };

  // If the user has not consented to functional cookies,
  // We will only collect anonymised data for them.
  // CookieFirst is usually not loaded at that point
  if (!window.CookieFirst?.consent?.functional) {
    snowplowConfig.anonymousTracking = {
      withSessionTracking: true,
      withServerAnonymisation: true,
    };
  }

  __tracker = newTracker(
    NAMESPACE,
    WF_CONFIG.SNOWPLOW_COLLECTOR_URL,
    snowplowConfig,
  );

  enableLinkClickTracking();

  return __tracker;
};

export const setUserId = (id) => {
  __tracker.setUserId(id);
};

/**
 * @description Configure global contexts that should be sent with events e.g. user etc
 * @param {Array<{type: string, entity: object}>} contexts - An array of global contexts to configure
 */
export const configureGlobalContext = (contexts) => {
  function selfDescribingEventFilter(args) {
    return args.eventType === "ue";
  }

  // To avoid sending historical states of an entity, we need to remove
  // its previous value from the global context
  // This will enable us to send its latest value using [addGlobalContexts]
  const contextsToRemove = contexts.reduce((acc, context) => {
    if (context.type in __globalContexts) {
      acc.push(__globalContexts[context.type]);
    }

    return acc;
  }, []);

  removeGlobalContexts([[selfDescribingEventFilter, contextsToRemove]]);

  // Retrieve a valid entity schema based on the entity type and
  // throw an error if the type is invalid
  const parsedContexts = contexts.map((context) => {
    let parsedEntity = null;

    switch (context.type) {
      case globalEntityType.USER:
        parsedEntity = userEntity(context.entity);
        break;
      case globalEntityType.PAGE:
        parsedEntity = pageEntity(context.entity);
        break;
      default:
        throw new Error("You must pass a valid entity type");
    }

    __globalContexts[context.type] = parsedEntity;
    return parsedEntity;
  });

  addGlobalContexts([[selfDescribingEventFilter, parsedContexts]]);
};
