import * as constants from "@wunderflats/constants";
import _isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import RedirectError from "../../../../errors/redirectError";
import errorReporter from "../../../../server/error-reporter";
import isFeatureFlagEnabled from "../../../../utils/isFeatureFlagEnabled";
import getWFCONFIG from "../../../../utils/WF_CONFIG";
import yousignUtils from "./yousignUtils";

const WF_CONFIG = getWFCONFIG();

export default async ({ api, params, user, url, featureFlags, query }) => {
  const { lang, listingRequestId } = params;

  const listingRequest =
    await api.listingRequests.listingRequestByIdWithLatestBookingOffer(
      listingRequestId,
    );

  const bookingOffer = listingRequest.latestBookingOffer;
  const isUserFeatureFlagEnabled = await api.users.isYousignEnabledForUSer(
    bookingOffer.landlord.id,
  );
  const isGlobalFeatureFlagEnabled =
    isFeatureFlagEnabled(
      featureFlags,
      constants.misc.FEATURE_FLAGS.ENVIRONMENT_YOUSIGN_CONTRACTS,
    ) && bookingOffer.yousignContract != null;

  const isYousignEnabled =
    isUserFeatureFlagEnabled || isGlobalFeatureFlagEnabled;

  if (isYousignEnabled && query.yousign === "signed") {
    await api.bookingOffers.updateYousignMemberStatus({
      bookingOfferId: bookingOffer.id,
    });

    const urlWithoutYousignQueryParam = url(
      "contract",
      { listingRequestId },
      { query: omit(query, "yousign") },
    );

    throw new RedirectError({
      redirectTo: urlWithoutYousignQueryParam,
      status: 302,
    });
  }

  if (isYousignEnabled && query.yousign !== "error") {
    try {
      const yousignContractUrl = await yousignUtils.getYousignContractUrl({
        api,
        bookingOfferId: bookingOffer.id,
        lang,
        listingRequestId,
        bookingId: query.bookingId,
        url,
      });

      bookingOffer.yousignContractUrl = yousignContractUrl;
    } catch (error) {
      const urlWithYousignErrorFlag = url(
        "contract",
        { listingRequestId },
        { query: { showPreviewStage: true, yousign: "error" } },
      );

      const reportError = errorReporter(
        WF_CONFIG.ROLLBAR_POST_SERVER_ITEM_ACCESS_TOKEN,
        WF_CONFIG.ROLLBAR_ENABLED === "true",
      );

      reportError(error, { user });

      throw new RedirectError({
        redirectTo: urlWithYousignErrorFlag,
        status: 302,
      });
    }
  }

  if (
    !bookingOffer ||
    bookingOffer.cancellationReason ||
    !bookingOffer.isOnlineSigning
  ) {
    const error = new Error("ResourceNotFoundError");
    error.name = "ResourceNotFoundError";
    throw error;
  }

  const bankAccounts = await api.bankAccounts.getBankAccounts(user._id);

  return {
    bookingOffer,
    bankAccounts,
    isYousignEnabled,
  };
};

export const shouldContractPageReload = (prev, curr) => {
  function extractNonReloadParams({ params, query }) {
    return {
      params,
      query: omit(query, ["showPreviewStage"]),
    };
  }

  return !_isEqual(extractNonReloadParams(prev), extractNonReloadParams(curr));
};
