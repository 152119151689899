// All queries with this tag are linted against the schema on the endpoint
// `api/graphql`.
import gql from "./gql";
// Give different tag name to bypass linting against apollo graphql endpoint.
const nexusGql = gql;

const factory = (http) => {
  return {
    async getMyStayListForUser(userId) {
      const { data, errors } = await http.post("/nexus", {
        query: nexusGql`
          query ($userId: ID!) {
            getMyStayListForUser(userId: $userId) {
              id
              from
              to
              name
              status
              sftStatus
              listingRequestsLink {
                id
                type
                requestedAt
                updatedAt
                tenantStatus {
                  type
                  actionable
                }
                listing {
                  groupId
                  id
                  partOfGroup
                  coverImageLink {
                    id
                    smallURL
                    mediumURL
                    largeURL
                    thumbnailURL
                    originalURL
                  }
                  images {
                    name
                    smallURL
                    mediumURL
                    largeURL
                    thumbnailURL
                    originalURL
                  }
                }
              }
              bookingChain {
                id
                listingRequestLink {
                  id
                  type
                  listing {
                    groupId
                    id
                    partOfGroup
                    images {
                      smallURL
                      mediumURL
                      largeURL
                      thumbnailURL
                      originalURL
                    }
                    coverImageLink {
                      smallURL
                      mediumURL
                      largeURL
                      thumbnailURL
                      originalURL
                    }
                  }
                }
                listing {
                  groupId
                  id
                  partOfGroup
                  address {
                    addressLine1
                    addressLine2
                    city
                    zipCode
                  }
                }
              }
            }
          }
        `,
        variables: { userId },
      });

      if (errors && errors.length) {
        throw new Error(errors.map((e) => `${e.message}\n`));
      }

      return data.getMyStayListForUser;
    },

    async getMyStayForId(id) {
      const { data, errors } = await http.post("/nexus", {
        query: nexusGql`
          query myStayById($id: String!) {
            myStayById(id: $id) {
              id
              from
              to
              name
              status
              sftStatus
              hasTenantBeenInvoicedForServiceFee
              bookingChain {
                contactPersonOnSite {
                  email
                  name
                  phone1
                }
              }
              listingRequestsLink {
                id
                from
                to
                methodOfBooking
                optionToExtend
                type
                requestedAt
                updatedAt
                reasonForStay
                sftValue
                tenantStatus {
                  type
                  actionable
                  reason
                  explanation
                }
                listing {
                  id
                  groupId
                  title {
                    en
                    de
                  }
                  beds
                  rooms
                  accommodates
                  area
                  onlyStudentsAllowed
                  price
                  deposit
                  finalCleaningFee
                  coverImageLink {
                    id
                    smallURL
                    mediumURL
                    largeURL
                    thumbnailURL
                    originalURL
                  }
                  images {
                    name
                    smallURL
                    mediumURL
                    largeURL
                    thumbnailURL
                    originalURL
                  }
                  address {
                    street
                    districts
                    city
                    zipCode
                    country
                  }
                  landlordLink {
                    averageTimeToRespondAsLandlord
                  }
                  partOfGroup
                  labels
                }
                numberOfOtherOnGoingRequestsForTheSameListing
              }
              bookingChain {
                id
                from
                to
                price
                deposit
                finalCleaningFee
                reasonForStay
                createdAt
                optionToExtend
                lastSignatureDate
                signedContractUrl
                isOnlineSigning
                listingRequestLink {
                  id
                  optionToExtend
                  from
                  to
                  methodOfBooking
                  sftValue
                  reasonForStay
                  requestedAt
                  updatedAt
                  listing {
                    id
                    price
                    deposit
                    groupId
                    finalCleaningFee
                    rooms
                    area
                    title {
                      en
                      de
                    }
                    address {
                      city
                      street
                      country
                      zipCode
                      country
                      districts
                    }
                    beds
                    accommodates
                    onlyStudentsAllowed
                    images {
                      name
                      smallURL
                      mediumURL
                      largeURL
                      thumbnailURL
                      originalURL
                    }
                    coverImageLink {
                      id
                      smallURL
                      mediumURL
                      largeURL
                      thumbnailURL
                      originalURL
                    }
                    landlordLink {
                      averageTimeToRespondAsLandlord
                    }
                    partOfGroup
                  }
                }
                listing {
                  id
                  rooms
                  area
                  partOfGroup
                  groupId
                  address {
                    addressLine1
                    addressLine2
                    city
                    country
                    zipCode
                  }
                }
              }
            }
          }
        `,
        variables: { id },
      });

      if (errors && errors.length) {
        throw new Error(errors.map((e) => `${e.message}\n`));
      }

      return data.myStayById;
    },
  };
};

export default factory;
