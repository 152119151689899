import getWFCONFIG from "../../../../utils/WF_CONFIG";

const WF_CONFIG = getWFCONFIG();

const getYousignRedirectUrl = ({
  url,
  listingRequestId,
  bookingId,
  success,
}) => {
  const query = {};

  if (success) {
    query.yousign = "signed";
  } else {
    query.yousign = "error";
    query.showPreviewStage = true;
  }

  if (bookingId) {
    query.bookingId = bookingId;
  }

  const contractUrl = url("contract", { listingRequestId }, { query });

  const protocol = WF_CONFIG.HTTPS === "false" ? "http://" : "https://";

  return `${protocol}${WF_CONFIG.LANGUAGE_HOST_EN}${contractUrl}`;
};

const getYousignContractUrl = async ({
  api,
  bookingOfferId,
  listingRequestId,
  bookingId,
  url,
  lang,
}) => {
  const yousignContractUrl = await api.bookingOffers.getYousignContractUrl({
    bookingOfferId,
    language: lang,
    successRedirectUrl: getYousignRedirectUrl({
      url,
      listingRequestId,
      bookingId,
      success: true,
    }),
    errorRedirectUrl: getYousignRedirectUrl({
      url,
      listingRequestId,
      bookingId,
      success: false,
    }),
  });

  return yousignContractUrl;
};

export default {
  getYousignContractUrl,
};
