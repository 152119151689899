import store from "store2";

export const set = (key, object) => {
  store.session.set(key, object);
};

export const get = (key) => {
  let res = {};
  try {
    res = store.session.get(key);
  } catch (e) {
    // invalid data in sessionStorage, clean it
    store.session.remove(key);
  }
  return res;
};

export const clear = (key) => {
  store.session.remove(key);
};
