import gql from "./gql";

const factory = (http) => {
  return {
    getBankAccounts(userId) {
      return http
        .get(`/users/${userId}/bank-accounts`)
        .then((data) => data.bankAccounts);
    },

    setBankAccountAsDefault(bankAccountId) {
      return http
        .put(`/bank-accounts/${bankAccountId}/default`)
        .then((data) => data.bankAccounts);
    },

    async setDepositBankAccount(bankAccountId) {
      const { data, errors } = await http.post("/graphql", {
        query: gql`
          mutation setDepositBankAccount($input: SetDepositBankAccountInput!) {
            setDepositBankAccount(input: $input) {
              ... on SetDepositBankAccountPayloadSuccess {
                bankAccount {
                  _id
                  depositAccount
                  default
                  iban
                  bic
                  ownerName
                  ownerAddress {
                    addressLine1
                    addressLine2
                    zipCode
                    region
                    city
                    country
                  }
                  createdAt
                }
              }
              ... on SetDepositBankAccountPayloadError {
                code
                message
              }
            }
          }
        `,
        variables: {
          input: { bankAccountId },
        },
      });
      if (errors && errors.length) {
        throw new Error(errors.map((e) => `${e.message}\n`));
      }
      return data.setDepositBankAccount.bankAccount;
    },

    createBankAccount(userId, bankAccount) {
      return http
        .post(`/users/${userId}/bank-accounts`, bankAccount)
        .then((data) => data.bankAccount);
    },

    deleteBankAccount(bankAccountId) {
      return http
        .delete(`/bank-accounts/${bankAccountId}`)
        .then((data) => data.bankAccounts);
    },
  };
};

export default factory;
