/* eslint-disable react/no-multi-comp */

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { countryDialCodes } from "../../utils/phoneNumbers";
import CustomPhoneNumberInputDefault from "./CustomPhoneNumberInputDefault";
import CustomPhoneNumberInputLocked from "./CustomPhoneNumberInputLocked";

const propTypes = {
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  focused: PropTypes.bool,
  locked: PropTypes.bool,
  isVerified: PropTypes.bool,
  defaultValue: PropTypes.string,
  defaultCountry: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  t: PropTypes.func,
};

class CustomPhoneNumberInput extends React.Component {
  constructor(props) {
    super(props);

    let defaultDialCode = "";
    if (props.defaultCountry && !props.defaultValue) {
      const defaultCountryDialCode = countryDialCodes.find(
        (countryDialCode) =>
          props.defaultCountry.toLowerCase() ===
          countryDialCode.code.toLowerCase(),
      );

      defaultDialCode = defaultCountryDialCode
        ? defaultCountryDialCode.dial_code
        : "";
    }

    if (props.defaultValue) {
      this.state = this.deserialize(props.defaultValue);
    } else {
      this.state = {
        dialCode: defaultDialCode,
        phoneNumber: "",
        selectedCountry: props.defaultCountry || "",
      };
    }

    if (props.value) {
      this.state = this.deserialize(props.value);
    }

    this.sortedDialCodes = this.sortCountryNames();
  }

  // TODO: https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html
  /* eslint-disable-next-line camelcase */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.value && nextProps.value !== this.serialize(this.state)) {
      this.setState(this.deserialize(nextProps.value));
    }
  }

  onChange = () => {
    if (this.props.onChange) {
      this.props.onChange({ target: this.hiddenInput, type: "change" });
    }
  };

  onChangeDialCode = (dialCode) => {
    if (!/^\d*$/.test(dialCode)) {
      return false;
    }
    const matchingCountry = this.findCountryCodeFromDialCode(
      dialCode.replace(/^0/, ""),
    );

    if (matchingCountry) {
      this.setState(
        {
          dialCode,
          selectedCountry: dialCode === "1" ? "US-CA" : matchingCountry.code,
        },
        this.onChange,
      );
    } else {
      this.setState(
        {
          dialCode,
          selectedCountry: "",
        },
        this.onChange,
      );
    }
  };

  onSelectCountry = (countryCode) => {
    const matchingCountry = countryDialCodes.find(
      (countryDialCode) =>
        countryCode.toLowerCase() === countryDialCode.code.toLowerCase(),
    );
    if (matchingCountry) {
      this.setState(
        {
          dialCode: matchingCountry.dial_code,
          selectedCountry: countryCode,
        },
        this.onChange,
      );
    } else {
      throw new Error("COUNTRY NOT FOUND IN DIAL CODE LIST");
    }
  };

  onChangePhoneNumber = (phoneNumber) => {
    this.setState(
      {
        phoneNumber,
      },
      this.onChange,
    );
  };

  onBlurDialCode = () => {
    this.setState((prevState) => ({
      dialCode: prevState.dialCode.replace(/^0/, ""),
    }));
    this.props.onBlur();
  };

  onBlurPhoneNumber = () => {
    this.setState((prevState) => ({
      phoneNumber: prevState.phoneNumber.replace(/^0/, ""),
    }));
    this.props.onBlur();
  };

  serialize({ dialCode = "", phoneNumber = "" }) {
    if (phoneNumber === "") {
      return "";
    }

    return `${dialCode ? "+" : ""}${dialCode
      .replace(" ", "")
      .replace(/^0/, "")}${dialCode ? " " : ""}${phoneNumber
      .replace(" ", "")
      .replace(/^0/, "")}`;
  }

  deserialize(formattedPhoneNumber) {
    const phoneNumberRegex = /^\+[0-9]{1,5}\s[0-9]*/;

    if (
      formattedPhoneNumber.indexOf(" ") === -1 &&
      formattedPhoneNumber.startsWith("+") &&
      formattedPhoneNumber.length < 6
    ) {
      const dialCode = formattedPhoneNumber.substring(1);
      const matchingCountry = this.findCountryCodeFromDialCode(dialCode);
      return {
        dialCode,
        phoneNumber: "",
        selectedCountry: matchingCountry ? matchingCountry.code : "",
      };
    }

    if (!phoneNumberRegex.test(formattedPhoneNumber)) {
      return {
        dialCode: "",
        phoneNumber: formattedPhoneNumber,
        selectedCountry: "",
      };
    }

    const dialCodeSplitIndex = formattedPhoneNumber.indexOf(" ");
    const dialCode = formattedPhoneNumber
      .slice(0, dialCodeSplitIndex)
      .replace("+", "");
    const phoneNumber = formattedPhoneNumber.slice(dialCodeSplitIndex + 1);
    const matchingCountry = this.findCountryCodeFromDialCode(dialCode);
    return {
      dialCode,
      phoneNumber,
      selectedCountry: matchingCountry ? matchingCountry.code : "",
    };
  }

  sortCountryNames = () => {
    function compare(a, b) {
      if (a.countryName < b.countryName) return -1;
      if (a.countryName > b.countryName) return 1;
      return 0;
    }

    countryDialCodes.forEach((obj) => {
      obj.countryName = this.props.t(`countries.${obj.code}`);
    });

    return countryDialCodes.sort(compare);
  };

  findCountryCodeFromDialCode(dialCode) {
    return dialCode
      ? countryDialCodes.find(
          (countryDialCode) =>
            dialCode.replace(" ", "") ===
            countryDialCode.dial_code.replace(" ", ""),
        )
      : "";
  }

  render() {
    const {
      t,
      isVerified,
      locked,
      onFocus,
      onBlur,
      focused,
      name,
      defaultValue,
    } = this.props;
    const { dialCode, phoneNumber, selectedCountry } = this.state;
    const className = classNames("CustomPhoneNumberInput", {
      Input: !locked,
      "Input--focused": !locked && focused,
      "CustomPhoneNumberInput--locked": locked,
    });
    const serializedValue = this.serialize({ dialCode, phoneNumber });

    return (
      <div className="CustomPhoneNumberInput-outer">
        {locked ? (
          <CustomPhoneNumberInputLocked
            t={t}
            isVerified={isVerified}
            dialCode={dialCode}
            phoneNumber={phoneNumber}
            className={className}
          />
        ) : (
          <CustomPhoneNumberInputDefault
            onFocus={onFocus}
            onBlur={onBlur}
            focused={focused}
            name={name}
            defaultValue={defaultValue}
            selectedCountry={selectedCountry}
            dialCode={dialCode}
            phoneNumber={phoneNumber}
            onBlurDialCode={this.onBlurDialCode}
            onBlurPhoneNumber={this.onBlurPhoneNumber}
            onChangeDialCode={this.onChangeDialCode}
            onChangePhoneNumber={this.onChangePhoneNumber}
            onSelectCountry={this.onSelectCountry}
            sortedDialCodes={this.sortedDialCodes}
            className={className}
          />
        )}
        <input
          data-testid="CustomPhoneNumberInput"
          type="hidden"
          name={name}
          ref={(node) => (this.hiddenInput = node)}
          value={serializedValue}
        />
      </div>
    );
  }
}

CustomPhoneNumberInput.propTypes = propTypes;
export default CustomPhoneNumberInput;
