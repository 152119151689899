import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { prepareModifiersForClassNames } from "../../utils/bem";

function PhoneNumber({ t, booking, subtle, transparent }) {
  const number = booking
    ? "+49 30 120 86 22 0"
    : t("contactDetails.support.phoneFormatted");
  const href = `tel:${number.replace(/ /g, "").replace(/\+/g, "00")}`;

  return (
    <a
      className={classnames(
        "PhoneNumber",
        prepareModifiersForClassNames("PhoneNumber", { subtle, transparent }),
      )}
      href={href}
    >
      <i className="PhoneNumber-phoneIcon" />
      <span className="PhoneNumber-phoneNumber">{number}</span>
    </a>
  );
}

PhoneNumber.propTypes = {
  t: PropTypes.func.isRequired,
  booking: PropTypes.bool,
  subtle: PropTypes.bool,
  transparent: PropTypes.bool,
};

export default PhoneNumber;
