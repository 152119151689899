import React from "react";
import PropTypes from "prop-types";

const Dashboard = ({ width = 26, height = 26 }) => (
  <svg
    height={height}
    viewBox="0 0 26 26"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fill="#fff"
        d="M8.66 5.26h13a1 1 0 011 1v13.61a1 1 0 01-1 1h-13V5.26z"
      />
      <path d="M21 4H5a3 3 0 00-3 3v12a3 3 0 003 3h16a3 3 0 003-3V7a3 3 0 00-3-3zM4 19V7a1 1 0 011-1h3.06v14H5a1 1 0 01-1-1zm18 0a1 1 0 01-1 1H9.56V6H21a1 1 0 011 1z" />
      <path d="M11.52 11.86h6v1h-6zm0-2h8v1h-8z" />
    </g>
  </svg>
);

Dashboard.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default Dashboard;
