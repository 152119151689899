import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import cookieCutter from "js-cookie";
import {
  actions,
  authReducer,
  INITIAL_STATE,
} from "../spages/AuthContainer/authReducer";
import { useApi } from "../spages/spa/context/ApiContext";
import { useTracker } from "../spages/spa/context/TrackerContext";
import dataLayer from "../utils/data-layer";
import * as sessionStorage from "../utils/sessionStorage";

export const signupValidation =
  ({ isPhoneNumberRequired }) =>
  (values) => {
    const errors = {};

    if (!values.firstName) {
      errors.firstName = "required";
    }

    if (!values.lastName) {
      errors.lastName = "required";
    }

    // Phone number with dial code only should be invalid
    if (
      isPhoneNumberRequired &&
      (!values.phone || values?.phone?.split(" ")[1]?.length === 0)
    ) {
      errors.phone = "required";
    }

    if (!values.password) {
      errors.password = "required";
    }

    if (values.password && values.password.length < 8) {
      errors.password = "mustBeMinLength:8";
    }

    if (!values.tos) {
      errors.tos = "required";
    }

    return Object.keys(errors).length === 0 ? null : errors;
  };

export const AuthContext = createContext({
  ...INITIAL_STATE,
  login: async () => {},
  loginWithCode: async () => {},
  signup: async () => {},
  logout: async () => {},
  acceptGeneralTos: async () => {},
  forgotPassword: async () => {},
  updateUser: () => {},
  refetchUser: async () => {},
});

const isUserLogggedIn = () => !!cookieCutter.get("isUserLoggedIn");

export const AuthContextProvider = ({
  children,
  user: initialUser = null,
  userActionsCount = null,
}) => {
  const api = useApi();
  const { tracker } = useTracker();
  const [state, dispatch] = React.useReducer(authReducer, {
    ...INITIAL_STATE,
    userActionsCount,
    user: initialUser,
  });

  useEffect(() => {
    tracker.setUserId(state.user?._id);
  }, [tracker, state.user]);

  // This function should be called whenever the userActionsCount needs to be updated.
  const reloadUserActionsCount = useCallback(async () => {
    try {
      if (isUserLogggedIn()) {
        const userActionsCount = await api.users.getUserActions();

        dispatch({
          type: actions.USER_ACTIONS_COUNT,
          payload: userActionsCount,
        });
      }
    } catch (error) {
      window.rollbar?.error(error);
    }
  }, [api.users]);

  const event = () => {};

  const login = (credentials) => {
    dispatch({ type: actions.LOGIN_LOADING });

    return api.auth
      .login(credentials)
      .then(api.getUserInfo)
      .then(onSuccess)
      .catch(onError);

    function onSuccess({ user }) {
      dispatch({ type: actions.LOGIN_SUCCESS, payload: user });

      event("login succeeded");
      dataLayer.pushToDataLayer(
        dataLayer.initialDataLayer("AuthContext", { user }),
      );

      return user;
    }

    function onError(error) {
      event("login failed");
      dispatch({ type: actions.LOGIN_ERROR, error });

      return Promise.reject(error);
    }
  };

  const loginWithCode = (credentials) => {
    dispatch({ type: actions.LOGIN_LOADING });

    return api.auth
      .loginWithCode(credentials)
      .then(api.getUserInfo)
      .then(onSuccess)
      .catch(onError);

    function onSuccess({ user }) {
      dispatch({ type: actions.LOGIN_SUCCESS, payload: user });

      event("login with code succeeded");
      dataLayer.pushToDataLayer(
        dataLayer.initialDataLayer("AuthContext", { user }),
      );

      return user;
    }

    function onError(error) {
      event("login with code failed");
      dispatch({ type: actions.LOGIN_ERROR, error });

      return Promise.reject(error);
    }
  };

  const forgotPassword = (payload) => {
    dispatch({ type: actions.FORGOT_PASSWORD_LOADING });

    return api.sendPasswordResetLink(payload).then(onSuccess).catch(onError);

    function onSuccess() {
      dispatch({ type: actions.FORGOT_PASSWORD_SUCCESS });
    }

    function onError(error) {
      dispatch({ type: actions.FORGOT_PASSWORD_ERROR, error });

      return Promise.reject(error);
    }
  };

  const signup = (values, isPhoneNumberRequired = false) => {
    const error = signupValidation({ isPhoneNumberRequired })(values);

    if (error) {
      dispatch({ type: actions.SIGNUP_ERROR, error });

      return Promise.reject(error);
    }

    dispatch({ type: actions.SIGNUP_LOADING });
    const referralId = sessionStorage.get("referralId");
    if (referralId) {
      values.referredBy = referralId;
    }

    return api.auth
      .signup(values)
      .then(onSuccess)
      .then(api.getUserInfo)
      .then(doneLoading)
      .catch(onError);

    function onSuccess() {
      tracker.events.trackSignupClicked({
        loginMethod: "email",
        isNewUser: true,
      });
      event("signup succeeded");
    }

    function doneLoading(data) {
      const user = data.user;

      dispatch({ type: actions.SIGNUP_SUCCESS, user });

      dataLayer.pushToDataLayer(
        dataLayer.initialDataLayer("AuthContext", { user }),
      );

      return user;
    }

    function onError(error) {
      event("signup failed");

      dispatch({ type: actions.SIGNUP_ERROR, error });

      return Promise.reject(error);
    }
  };

  const acceptGeneralTos = () => {
    return api.users
      .acceptGeneralTos()
      .then((user) => {
        dispatch({ type: actions.SET_USER, payload: user });
      })
      .catch(() => {});
  };

  const logout = () => {
    return api.auth.logout().then(() => {
      event("logout");
      dispatch({ type: actions.SET_USER, payload: null });

      dataLayer.pushToDataLayer(
        dataLayer.initialDataLayer("AuthContext", { user: null }),
      );
    });
  };

  const updateUser = useCallback((user) => {
    dispatch({ payload: user, type: actions.SET_USER });
  }, []);

  const refetchUser = useCallback(async () => {
    if (!isUserLogggedIn()) {
      return;
    }

    const user = await api.users.getAuthenticatedUser();

    updateUser(user);
  }, [api.users, updateUser]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        loginWithCode,
        signup,
        logout,
        acceptGeneralTos,
        forgotPassword,
        updateUser,
        refetchUser,
        reloadUserActionsCount,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  user: PropTypes.object,
  userActionsCount: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export function useAuth() {
  return useContext(AuthContext);
}
