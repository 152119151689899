// !!!!!!!!!!!!!!!! IMPORTANT
// This file is used to map en, de and fr versions of regions because these names
// are used as url parameters on some of the pages.
// At the moment we only want to use english region names as the key or a slug,
// but for legacy and SEO reasons we have to support several german and french regions
// that have a different german, french and english name

function replaceUmlauts(str) {
  return str
    .toLowerCase()
    .replace(/\u00df/g, "ss")
    .replace(/\u00e4/g, "ae")
    .replace(/\u00f6/g, "oe")
    .replace(/\u00fc/g, "ue");
}

export const legacyRegionMapping = [
  { de: "deutschland", en: "germany", fr: "allemagne" },
  { de: "braunschweig", en: "brunswick", fr: "brunswick" },
  { de: "koeln", en: "cologne", fr: "cologne" },
  { de: "hannover", en: "hanover", fr: "hanover" },
  { de: "muenchen", en: "munich", fr: "munich" },
  { de: "muenster", en: "munster", fr: "munster" },
  { de: "nurnberg", en: "nuremberg", fr: "fruremberg" },
  { de: "wien", en: "vienna", fr: "vienna" },
  {
    de: "1-arrondissement-von-paris",
    en: "1st-arrondissement-of-paris",
    fr: "1er-arrondissement-de-paris",
  },
  {
    de: "2-arrondissement-von-paris",
    en: "2nd-arrondissement-of-paris",
    fr: "2e-arrondissement-de-paris",
  },
  {
    de: "3-arrondissement-von-paris",
    en: "3rd-arrondissement-of-paris",
    fr: "3e-arrondissement-de-paris",
  },
  {
    de: "4-arrondissement-von-paris",
    en: "4th-arrondissement-of-paris",
    fr: "4e-arrondissement-de-paris",
  },
  {
    de: "5-arrondissement-von-paris",
    en: "5th-arrondissement-of-paris",
    fr: "5e-arrondissement-de-paris",
  },
  {
    de: "6-arrondissement-von-paris",
    en: "6th-arrondissement-of-paris",
    fr: "6e-arrondissement-de-paris",
  },
  {
    de: "7-arrondissement-von-paris",
    en: "7th-arrondissement-of-paris",
    fr: "7e-arrondissement-de-paris",
  },
  {
    de: "8-arrondissement-von-paris",
    en: "8th-arrondissement-of-paris",
    fr: "8e-arrondissement-de-paris",
  },
  {
    de: "9-arrondissement-von-paris",
    en: "9th-arrondissement-of-paris",
    fr: "9e-arrondissement-de-paris",
  },
  {
    de: "10-arrondissement-von-paris",
    en: "10th-arrondissement-of-paris",
    fr: "10e-arrondissement-de-paris",
  },
  {
    de: "11-arrondissement-von-paris",
    en: "11th-arrondissement-of-paris",
    fr: "11e-arrondissement-de-paris",
  },
  {
    de: "12-arrondissement-von-paris",
    en: "12th-arrondissement-of-paris",
    fr: "12e-arrondissement-de-paris",
  },
  {
    de: "13-arrondissement-von-paris",
    en: "13th-arrondissement-of-paris",
    fr: "13e-arrondissement-de-paris",
  },
  {
    de: "14-arrondissement-von-paris",
    en: "14th-arrondissement-of-paris",
    fr: "14e-arrondissement-de-paris",
  },
  {
    de: "15-arrondissement-von-paris",
    en: "15th-arrondissement-of-paris",
    fr: "15e-arrondissement-de-paris",
  },
  {
    de: "16-arrondissement-von-paris",
    en: "16th-arrondissement-of-paris",
    fr: "16e-arrondissement-de-paris",
  },
  {
    de: "17-arrondissement-von-paris",
    en: "17th-arrondissement-of-paris",
    fr: "17e-arrondissement-de-paris",
  },
  {
    de: "18-arrondissement-von-paris",
    en: "18th-arrondissement-of-paris",
    fr: "18e-arrondissement-de-paris",
  },
  {
    de: "19-arrondissement-von-paris",
    en: "19th-arrondissement-of-paris",
    fr: "19e-arrondissement-de-paris",
  },
  {
    de: "20-arrondissement-von-paris",
    en: "20th-arrondissement-of-paris",
    fr: "20e-arrondissement-de-paris",
  },
];

export function mapRegionSlugToLegacyLang(region, lang) {
  return (
    legacyRegionMapping.find((reg) => {
      return region === reg.en || region === reg.fr || region === reg.de;
    })?.[lang] || region
  );
}

export function mapLegacyRegionToRegionSlug(region) {
  return (
    legacyRegionMapping.find((reg) => {
      return replaceUmlauts(region) === reg.de || region === reg.fr;
    })?.en || region
  );
}
