import React, { useMemo } from "react";
import PropTypes from "prop-types";
import FranceFlag from "../../../Icons/Flags/France";
import GermanyFlag from "../../../Icons/Flags/Germany";
import Russia from "../../../Icons/Flags/Russia";
import Ukraine from "../../../Icons/Flags/Ukraine";
import UnitedKingdomFlag from "../../../Icons/Flags/UnitedKingdom";

const LanguageSwitch = ({ lang, translationUrls }) => {
  const possibleLanguageSwitches = useMemo(
    () => [
      {
        lang: "en",
        href: translationUrls.en,
        dataTestId: "AppHeaderDrawer-LanguageSwitch-english",
        flag: UnitedKingdomFlag,
        text: "English",
      },
      {
        lang: "de",
        href: translationUrls.de,
        dataTestId: "AppHeaderDrawer-LanguageSwitch-german",
        flag: GermanyFlag,
        text: "Deutsch",
      },
      {
        lang: "fr",
        href: translationUrls.fr,
        dataTestId: "AppHeaderDrawer-LanguageSwitch-french",
        flag: FranceFlag,
        text: "Français",
      },
      {
        lang: "uk",
        href: translationUrls.uk,
        dataTestId: "AppHeaderDrawer-LanguageSwitch-ukranian",
        flag: Ukraine,
        text: "Українська",
      },
      {
        lang: "ru",
        href: translationUrls.ru,
        dataTestId: "AppHeaderDrawer-LanguageSwitch-russian",
        flag: Russia,
        text: "Pусский",
      },
    ],
    [translationUrls],
  );

  const availableLanguageSwitches = useMemo(
    () =>
      possibleLanguageSwitches.filter(
        (possibleLanguageSwitch) => possibleLanguageSwitch.lang !== lang,
      ),
    [lang, possibleLanguageSwitches],
  );

  return availableLanguageSwitches.map((availableLanguageSwitch) => {
    const Flag = availableLanguageSwitch.flag;

    return (
      <a
        href={availableLanguageSwitch.href}
        className="AppHeader-Link AppHeader-Link--expand"
        data-testid={availableLanguageSwitch.dataTestId}
        key={availableLanguageSwitch.dataTestId}
      >
        <span className="AppHeader-Link-icon">
          <Flag />
        </span>
        <span className="AppHeader-Link-text">
          {availableLanguageSwitch.text}
        </span>
      </a>
    );
  });
};

LanguageSwitch.propTypes = {
  lang: PropTypes.string.isRequired,
  translationUrls: PropTypes.shape({
    de: PropTypes.string.isRequired,
    en: PropTypes.string.isRequired,
    fr: PropTypes.string.isRequired,
  }).isRequired,
};

export default LanguageSwitch;
