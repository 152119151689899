const factory = (http) => {
  return {
    getImagesByIds(ids) {
      const imageIds = ids.join(",");
      return http.get(`/images?image_ids=${imageIds}`);
    },
  };
};

export default factory;
