import { Children } from "react";

export const findByType = (children, component) => {
  return (
    Children.toArray(children).find((child) => child?.type === component) ||
    null
  );
};

export const isChild = (parent, child) => {
  let node = child && child.parentNode;

  while (node !== null) {
    if (node === parent) return true;
    node = node.parentNode;
  }

  return false;
};

// taken from http://stackoverflow.com/a/35940276/5530329
export const findScrollParent = (node) => {
  if (node === null) {
    return null;
  }

  if (node.scrollHeight > node.clientHeight) {
    return node;
  }

  return findScrollParent(node.parentNode);
};

export const getCoordinates = (node) => {
  const bodyRect = document.body.getBoundingClientRect();
  const eltRect = node.getBoundingClientRect();
  return { x: eltRect.left - bodyRect.left, y: eltRect.top - bodyRect.top };
};

export const canUseDOM = () => {
  return !!(
    typeof window !== "undefined" &&
    window.document &&
    window.document.createElement
  );
};

export const isInViewport = (element) => {
  const rect = element.getBoundingClientRect();
  const html = document.documentElement;

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || html.clientHeight) &&
    rect.right <= (window.innerWidth || html.clientWidth)
  );
};
