import "./InputTooltip.scss";

import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Button } from "../DesignSystem/Button/Button";
import TooltipIcon from "../Icons/TooltipIcon";
import Popover from "../Popover/Popover";

const InputTooltip = ({
  containerClassName,
  popoverClassName,
  usePortal = false,
  tooltipContent,
  contentComponent,
  tooltipIcon,
  placement,
  closeButtonText,
  testId,
}) => {
  const [showPopover, setShowPopover] = useState(false);

  const closeTooltip = (e) => {
    e.preventDefault();
    setShowPopover(false);
  };

  const around = (
    <button
      onClick={(e) => {
        e.preventDefault();
        setShowPopover(!showPopover);
      }}
      className="InputTooltip-iconContainer"
    >
      {tooltipIcon || <TooltipIcon />}
    </button>
  );
  return (
    <div
      className={classnames("InputTooltip-container", containerClassName)}
      data-testid={testId || "inputTooltip"}
    >
      <Popover
        className={popoverClassName}
        usePortal={usePortal}
        placement={placement}
        around={around}
        show={showPopover}
        onClose={() => setShowPopover(false)}
      >
        <div
          className="InputTooltip-text"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: tooltipContent,
          }}
        />
        {contentComponent}
        {closeButtonText && (
          <Button
            className="InputTooltip-closeButton"
            colorVariant="secondary"
            widthVariant="fullWidth"
            onClick={(e) => closeTooltip(e)}
            tabIndex="-1"
          >
            {closeButtonText}
          </Button>
        )}
      </Popover>
    </div>
  );
};

InputTooltip.propTypes = {
  containerClassName: PropTypes.string,
  popoverClassName: PropTypes.string,
  contentComponent: PropTypes.node, // to be used when content is JSX
  tooltipContent: PropTypes.string, // to be used when content is just a plain string
  usePortal: PropTypes.bool,
  tooltipIcon: PropTypes.node,
  placement: PropTypes.oneOf([
    "right",
    "right-end",
    "right-start",
    "left",
    "left-end",
    "left-start",
    "top",
    "bottom",
    "bottom-start",
  ]),
  closeButtonText: PropTypes.string,
  testId: PropTypes.string,
};

export default InputTooltip;
