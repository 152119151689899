import { supportedHelpPageLangs } from "../routes";

export default function createFaqsApiClient() {
  return {
    getFaqs: ({ language }) => {
      return supportedHelpPageLangs.includes(language)
        ? import(`../assets/faqs/${language}.json`).then(
            (importedModule) => importedModule,
          )
        : {
            categories: [],
            subcategories: [],
            posts: [],
          };
    },
  };
}
