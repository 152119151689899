import qs from "qs";

const factory = (http) => {
  return {
    getContent: (lang, slug, category = null) => {
      const queryParams = qs.stringify({ lang, category });

      return http.get(`/regions/${slug}/content?${queryParams}`);
    },
  };
};

export default factory;
