import gql from "./gql";

// Give different tag name to bypass linting against apollo graphql endpoint.
const nexusGql = gql;

class InvalidEmailException extends Error {
  constructor() {
    super();
    this.name = "InvalidEmail";
  }
}

class ReCaptchaValidationError extends Error {
  constructor() {
    super();
    this.name = "ReCaptchaValidationError";
  }
}

const factory = (http, httpFrontend) => {
  return {
    async emailExists({ email, reCaptchaToken }) {
      const { data, errors } = await http.post("/graphql", {
        query: gql`
          query emailExists($email: String!, $reCaptchaToken: String!) {
            emailExists(email: $email, reCaptchaToken: $reCaptchaToken)
          }
        `,
        variables: { email, reCaptchaToken },
      });

      if (errors && Array.isArray(errors)) {
        const errorCode = errors[0].extensions.code;

        if (errorCode === "BAD_USER_INPUT") {
          throw new InvalidEmailException();
        } else if (errorCode === "RE_CAPTCHA_VALIDATION_ERROR") {
          throw new ReCaptchaValidationError();
        }
      }

      return data.emailExists || false;
    },

    login(payload) {
      return httpFrontend.post("/auth/login", payload);
    },

    async requestLoginCode({ email }) {
      const response = await http.post("/nexus", {
        query: nexusGql`
          mutation requestLoginCode($email: String!) {
            requestLoginCode(email: $email) {
              __typename
              ... on RequestLoginCodePayloadError {
                message
                name
              }
              ... on RequestLoginCodePayloadSuccess {
                success
              }
            }
          }
        `,
        variables: { email },
        /* eslint-enable graphql/template-strings */
      });

      if (response.errors && response.errors.length) {
        throw new Error(response.errors.map((e) => `${e.message}\n`));
      }

      if (
        response.data.requestLoginCode.__typename ===
        "RequestLoginCodePayloadSuccess"
      ) {
        return response.data;
      }

      if (
        response.data.requestLoginCode.__typename ===
        "RequestLoginCodePayloadError"
      ) {
        const error = new Error(response.data.requestLoginCode.message);
        error.name = response.data.requestLoginCode.name;
        throw error;
      }
    },

    loginWithCode(payload) {
      return httpFrontend.post("/auth/login/code", payload);
    },

    logout() {
      return httpFrontend.post("/auth/logout");
    },

    signup(payload) {
      return httpFrontend.post("/auth/signup", payload);
    },
  };
};

export default factory;
