/* eslint-disable lodash/prefer-matches */
import ResourceNotFoundError from "../errors/resourceNotFoundError";

function createDocumentItem(name, country, lang, path) {
  return {
    name,
    country,
    lang,
    path,
  };
}

const documents = [
  createDocumentItem(
    "tos",
    "en",
    "en",
    "public/files/legal-documents/tos/combined.md",
  ),
  createDocumentItem(
    "tos",
    "de",
    "de",
    "public/files/legal-documents/tos-uk/de-de.md",
  ),
  createDocumentItem(
    "tos",
    "de",
    "en",
    "public/files/legal-documents/tos-uk/de-en.md",
  ),
  createDocumentItem(
    "impressum",
    "de",
    "de",
    "public/files/legal-documents/impressum/de-de.md",
  ),
  createDocumentItem(
    "impressum",
    "de",
    "en",
    "public/files/legal-documents/impressum/de-en.md",
  ),
  createDocumentItem(
    "impressum",
    "de",
    "fr",
    "public/files/legal-documents/impressum/de-fr.md",
  ),
  createDocumentItem(
    "privacypolicy",
    "de",
    "de",
    "public/files/legal-documents/privacy-policy/de-de.md",
  ),
  createDocumentItem(
    "privacypolicy",
    "de",
    "en",
    "public/files/legal-documents/privacy-policy/de-en.md",
  ),
  createDocumentItem(
    "privacypolicy",
    "de",
    "fr",
    "public/files/legal-documents/privacy-policy/de-fr.md",
  ),
];

const factory = (http) => {
  return {
    async getDocument({ lang, country, name }) {
      let document = documents.find(
        (iter) =>
          iter.lang === lang && iter.name === name && iter.country === country,
      );
      if (!document) {
        // If the document doesn't exist for the language try getting english version
        document = documents.find(
          (iter) =>
            iter.lang === "en" &&
            iter.name === name &&
            iter.country === country,
        );
      }
      if (!document) {
        throw new ResourceNotFoundError({
          resource: "document",
          message: `Document not found - name: ${name}, lang: ${lang}, country: ${country}`,
        });
      }

      return http.get(document.path);
    },
  };
};

export default factory;
