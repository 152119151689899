import { ARTEFACTS_BASE_URL } from "../../../../utils/GCPArtefacts/static-artefacts";

const imagesBaseUrl = `${ARTEFACTS_BASE_URL}/about-page`;
const getSrcSet = (
  teamMemberName,
  sizes = [150, 300, 450, 600, 1200],
  baseUrl = imagesBaseUrl,
) => {
  return sizes
    .map((size) => {
      return `${baseUrl}/${teamMemberName}@${size}.jpg ${size}w`;
    })
    .join(", ");
};

const TEAM_MEMBERS = {
  founders: [
    {
      name: "Jan",
      role: "Founder",
      src: `${imagesBaseUrl}/jan@600.jpg`,
      srcSet: getSrcSet("jan"),
    },
    {
      name: "Arkadi",
      role: "Founder",
      src: `${imagesBaseUrl}/arkadi@600.jpg`,
      srcSet: getSrcSet("arkadi"),
    },
  ],
  employees: [
    {
      name: "Cristina",
      role: "Business Development",
      src: `${imagesBaseUrl}/cristina@600.jpg`,
      srcSet: getSrcSet("cristina"),
    },
    {
      name: "Irena",
      role: "Business Operations",
      src: `${imagesBaseUrl}/irena@600.jpg`,
      srcSet: getSrcSet("irena"),
    },
    {
      name: "Oliver",
      role: "FSU",
      src: `${imagesBaseUrl}/oliver@600.jpg`,
      srcSet: getSrcSet("oliver"),
    },
    {
      name: "Patricia",
      role: "Customer Support",
      src: `${imagesBaseUrl}/patricia@600.jpg`,
      srcSet: getSrcSet("patricia"),
    },
    {
      name: "Anne",
      role: "Business Operations",
      src: `${imagesBaseUrl}/anne@600.jpg`,
      srcSet: getSrcSet("anne"),
    },
    {
      name: "Evgeniia",
      role: "Finance",
      src: `${imagesBaseUrl}/evgeniia@600.jpg`,
      srcSet: getSrcSet("evgeniia"),
    },
    {
      name: "Matthias",
      role: "Sales",
      src: `${imagesBaseUrl}/matthias@600.jpg`,
      srcSet: getSrcSet("matthias"),
    },
    {
      name: "Lan",
      role: "Finance",
      src: `${imagesBaseUrl}/lan@600.jpg`,
      srcSet: getSrcSet("lan"),
    },
    {
      name: "Lena",
      role: "Booking",
      src: `${imagesBaseUrl}/lena@600.jpg`,
      srcSet: getSrcSet("lena"),
    },
    {
      name: "Michael",
      role: "Key Account Management",
      src: `${imagesBaseUrl}/michaelgurzan@600.jpg`,
      srcSet: getSrcSet("michaelgurzan"),
    },
    {
      name: "Danny",
      role: "IT",
      src: `${imagesBaseUrl}/danny@600.jpg`,
      srcSet: getSrcSet("danny"),
    },
    {
      name: "Carina",
      role: "Communications",
      src: `${imagesBaseUrl}/carina@600.jpg`,
      srcSet: getSrcSet("carina"),
    },
    {
      name: "Claudi",
      role: "Booking",
      src: `${imagesBaseUrl}/Claudia@600.jpg`,
      srcSet: getSrcSet("Claudia"),
    },
    {
      name: "Christian",
      role: "FSU",
      src: `${imagesBaseUrl}/christian@600.jpg`,
      srcSet: getSrcSet("christian"),
    },
    {
      name: "Diego",
      role: "Product",
      src: `${imagesBaseUrl}/diego@600.jpg`,
      srcSet: getSrcSet("diego"),
    },
    {
      name: "Anna",
      role: "People",
      src: `${imagesBaseUrl}/anna@600.jpg`,
      srcSet: getSrcSet("anna"),
    },
  ],
};

export default () => ({
  TEAM_MEMBERS,
});
