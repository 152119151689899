import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ArrowRight from "../ArrowRight/ArrowRight";
import AccordionContent from "./AccordionContent";
import AccordionSection from "./AccordionSection";

import "./Accordion.scss";

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  headline: PropTypes.string,
  viewMore: PropTypes.shape({
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  customClassName: PropTypes.string,
};

const Accordion = ({
  children,
  headline = "",
  viewMore,
  className,
  customClassName = "Accordion",
}) => {
  const childrenWithProps = React.Children.map(children, (child) => {
    const props = { customClassName };
    if (React.isValidElement(child)) {
      return React.cloneElement(child, props);
    }
    return child;
  });

  return (
    <article
      className={classNames(customClassName, className)}
      data-testid={`${customClassName}-details`}
    >
      <h2 className={`${customClassName}-headline`}>{headline}</h2>
      {childrenWithProps}
      {viewMore ? (
        <a className={`${customClassName}-viewMore`} href={viewMore.url}>
          <p className={`${customClassName}-viewMore-text`}>{viewMore.text}</p>
          <ArrowRight fillColor="#732dd9" width={17} height={15} />
        </a>
      ) : null}
    </article>
  );
};

Accordion.Section = AccordionSection;
Accordion.Content = AccordionContent;
Accordion.propTypes = propTypes;

export default Accordion;
