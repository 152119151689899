import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useI18n } from "../../spages/spa/context/I18nContext";
import { IconButton } from "../DesignSystem/IconButton/IconButton";
import LeftArrow from "../LeftArrow/LeftArrow";

import "./BackButton.scss";

const propTypes = {
  type: PropTypes.oneOf(["white", "translucid", "transparent"]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.string,
  dataTestId: PropTypes.string,
};

const BackButton = ({
  type = "white",
  className,
  onClick,
  label,
  dataTestId,
  ...rest
}) => {
  const { t } = useI18n();
  const defaultOnClick = () => window.history.back();
  return (
    <IconButton
      dataTestId={dataTestId}
      className={classnames("BackButton", `BackButton--${type}`, className)}
      onClick={onClick || defaultOnClick}
      aria-label={t("back")}
      label={label}
      size="large"
      icon={
        <LeftArrow
          fillColor={
            type === "white" || type === "transparent" ? "#24272e" : "#fff"
          }
        />
      }
      {...rest}
    />
  );
};

BackButton.propTypes = propTypes;

export default BackButton;
