import React, { useContext } from "react";
import PropTypes from "prop-types";
import analytics from "../../../analytics";
import { getDisplayName } from "../../../utils/react-utils";

const AnalyticsContext = React.createContext(analytics);

const providerPropTypes = {
  analytics: PropTypes.object,
  children: PropTypes.node,
};

export function AnalyticsContextProvider({ analytics, children }) {
  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
}

AnalyticsContextProvider.propTypes = providerPropTypes;

export function useAnalytics() {
  return useContext(AnalyticsContext);
}

export function withAnalytics(Component) {
  function WrappedComponent(props) {
    const analytics = useAnalytics();
    return <Component {...props} analytics={analytics} />;
  }

  // Assign static properties like propTypes:
  for (const key of Object.keys(Component)) {
    WrappedComponent[key] = Component[key];
  }

  WrappedComponent.displayName = `WithAnalytics(${getDisplayName(Component)})`;

  return WrappedComponent;
}
