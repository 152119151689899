import React, { useContext } from "react";
import PropTypes from "prop-types";
import createApiClient from "../../../api";
import { getDisplayName } from "../../../utils/react-utils";

const ApiContext = React.createContext(createApiClient({}));

const providerPropTypes = {
  api: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export function ApiContextProvider({ api, children }) {
  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
}

ApiContextProvider.propTypes = providerPropTypes;

export function useApi() {
  return useContext(ApiContext);
}

export function withApi(Component) {
  function WrappedComponent(props) {
    const api = useApi();
    // For legacy pages outside the SPA, there is no ApiContext. In this case,
    // the wrapped Component receives the api via props.
    // eslint-disable-next-line react/prop-types
    return <Component {...props} api={props.api || api} />;
  }

  // Assign static properties like propTypes:
  for (const key of Object.keys(Component)) {
    WrappedComponent[key] = Component[key];
  }

  WrappedComponent.displayName = `WithApi(${getDisplayName(Component)})`;

  return WrappedComponent;
}
