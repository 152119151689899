import axios from "axios";
import nexusGql from "./gql";

const factory = (http) => {
  return {
    upload({ formData, setProgress }) {
      const source = axios.CancelToken.source();

      const promise = http.post("/file", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress(progressEvent) {
          setProgress(progressEvent);
        },
        // It takes around 3.5 minutes to upload 50mb on 3g
        timeout: 1000 * 60 * 4,
        cancelToken: source.token,
      });

      return {
        promise,
        cancel: source.cancel,
      };
    },

    async getFiles(userId) {
      const { data, errors } = await http.post("/nexus", {
        query: nexusGql`
          query($createdBy: String!) {
            filesByCreatedBy(createdBy: $createdBy) {
              fileName
              createdAt
              createdBy
              _id
              type
              azureFileName
              isPublic
              downloadUrl
              deleteDate
            }
          }
        `,

        variables: { createdBy: userId },
      });

      if (errors && errors.length) {
        throw new Error(errors.map((e) => `${e.message}\n`));
      }

      return data.filesByCreatedBy;
    },

    async deleteFile(fileId) {
      const { errors } = await http.post("/nexus", {
        query: nexusGql`
          mutation deleteFileById($fileId: String!) {
            deleteFileById(fileId: $fileId)
          }
        `,
        variables: { fileId },
      });

      if (errors && errors.length) {
        throw new Error(errors.map((e) => `${e.message}\n`));
      }
    },
  };
};

export default factory;
