import dayjs from "../dayjs";
import normalize from "./normalize";
import occupation from "./occupation";
import utils from "./utils";

/**
 * Wunderflat's Date Library
 *
 * *Please keep in mind that the exported Symbol names may differ from internal function names.*
 *
 * __DO NOT require internal modules in your project as they are not part of the public API__
 *
 * Type Definitions can be found at the bottom of the documentation.
 *
 * The documentation currently does not reflect the internal structure of the module due to JSDoc 3 limitations.
 * @namespace WunderDate
 */

/**
 * Wrapper for DayJS
 *
 * {@link https://day.js.org/ | Get me to DayJS Docs}
 * @memberof WunderDate
 * @name dayjs
 * @function
 */

const lib = {
  currentYear: utils.currentYear,
  dayjs: dayjs.utc,
  normalizeBlock: normalize.block,
  normalizeFrom: normalize.from,
  normalizeTo: normalize.to,
  nextFreePeriod: utils.nextFreePeriod,
  periodAvailableWithin: utils.periodAvailableWithin,
  freePeriods: utils.freePeriods,
  normalizeBlocks: normalize.blocks,
  occupancyPerMonth: occupation.ofRange,
  occupancyOfMonth: occupation.ofMonth,
  monthToRange: utils.monthToRange,
  rangeToMonths: utils.rangeToMonths,
  rangeIntersectsBlocks: utils.rangeWithBlocks,
  sortBlocks: normalize.sortBlocks,
  dateIntersectsBlock: utils.dateIntersectsBlock,
  sortByFromDateAscending: utils.sortByFromDateAscending,
};

export default lib;
