/**
 * READ FIRST
 * Each time the consent changed, the CookieFirst banner will reload the page
 * to make sure the new status applied. That leads to the following:
 * - INIT event is triggered on every page load as expected;
 * - CONSENT_CHANGED is triggered only once, when the user makes
 *   the first decision;
 * - OLD_CONSENT_LOAD is triggered after every page load, but contains
 *   new data, which are completely useless.
 */

import * as amplitude from "@amplitude/analytics-browser";
import getWFCONFIG from "../utils/WF_CONFIG";
import { trackCookieFirstChange } from "./events";

const WF_CONFIG = getWFCONFIG();

export const events = {
  INIT: "cf_init",
  CONSENT_CHANGED: "cf_consent",
  OLD_CONSENT_LOADED: "cf_consent_loaded",
  OLD_SERVICES_CONSENT_LOADED: "cf_services_consent_loaded",
  POPUP_RENDERED: "cf_layer_ready",
  SERVICE_CONSENT_CHANGED: "cf_services_consent",
};

const OLD_CONSENT_LOCAL_STORAGE_KEY = "cookie-first-old-consent";

const saveConsent = (consent) => {
  localStorage.setItem(OLD_CONSENT_LOCAL_STORAGE_KEY, JSON.stringify(consent));
};

const loadConsent = () => {
  try {
    return JSON.parse(localStorage.getItem(OLD_CONSENT_LOCAL_STORAGE_KEY));
  } catch {
    //
  }

  return null;
};

const consentChanged = (before, after) => {
  for (const key of Object.keys(after)) {
    if (before[key] !== after[key]) {
      return true;
    }
  }

  return false;
};

export const onInit = (event) => {
  const consentAfter = event.detail || window.CookieFirst?.consent;
  if (!consentAfter) {
    return;
  }
  const consentBefore = loadConsent();
  if (consentBefore && consentChanged(consentBefore, consentAfter)) {
    trackCookieFirstChange({ consent: consentAfter });
    saveConsent(consentAfter);
  }
};

export const onConsentChange = (event) => {
  const consent = event.detail || window.CookieFirst?.consent;
  if (!consent) {
    return;
  }
  trackCookieFirstChange({ consent });
  saveConsent(consent);
};

export const registerCookieConstentListeners = (tracker) => {
  // Listen to CookieFirst init and change events, so we
  // can set anonymous tracking according to user consent.
  const onCookieFirstChange = (event) => {
    // We want to delay sending events until the cookie consent status is known.
    // Hence, we keep track of this in memory using
    // "COOKIE_CONSENT_STATUS_KNOWN".
    // Also, it's possible that we have events that haven't been dequeued yet
    // and snowplow attempts to send them when enabling/disabling anonymous
    // tracking. This causes a "No collectorURL found".
    // We want to silence this by logging a warning on rollbar.
    // These events are also later sent after a page load.
    try {
      const consent = event.detail || window.CookieFirst?.consent;
      if (consent?.functional) {
        amplitude.setOptOut(false);
        const trackerInstance = tracker.init();
        trackerInstance.setCollectorUrl(WF_CONFIG.SNOWPLOW_COLLECTOR_URL);
        trackerInstance.disableAnonymousTracking();
      } else {
        amplitude.setOptOut(true);
        tracker.init().enableAnonymousTracking({
          options: { withServerAnonymisation: true },
        });
      }
      window.COOKIE_CONSENT_STATUS_KNOWN = true;
    } catch (error) {
      window.rollbar?.warn(error);
    }
  };
  window.addEventListener(events.INIT, onCookieFirstChange);
  window.addEventListener(events.CONSENT_CHANGED, onCookieFirstChange);
  window.addEventListener(events.INIT, onInit);
  window.addEventListener(events.CONSENT_CHANGED, onConsentChange);
};
