import React, { Fragment } from "react";
import PropTypes from "prop-types";
// Links
import { Link } from "../../../DesignSystem/Button/Button";
import BecomeALandlordLink from "../../Links/BecomeALandlordLink";
import DiscoverLink from "../../Links/DiscoverLink";
import HelpLink from "../../Links/HelpLink";
import ShortlistLink from "../../Links/ShortlistLink";

const NotLoggedInUserMenu = ({ currentUrl, analyticsEvent, lang, t, url }) => (
  <Fragment>
    <ul className="AppHeaderDesktopUserMenu-LinkList">
      <li className="AppHeaderDesktopUserMenu-LinkList-item">
        <DiscoverLink
          currentUrl={currentUrl}
          expand
          lang={lang}
          t={t}
          url={url}
          analyticsEvent={analyticsEvent}
        />
      </li>

      <li className="AppHeaderDesktopUserMenu-LinkList-item">
        <ShortlistLink
          currentUrl={currentUrl}
          expand
          t={t}
          url={url}
          analyticsEvent={analyticsEvent}
        />
      </li>
    </ul>

    <hr className="AppHeaderDesktopUserMenu-separator" />

    <ul className="AppHeaderDesktopUserMenu-LinkList">
      <li className="AppHeaderDesktopUserMenu-LinkList-item">
        <BecomeALandlordLink url={url} />
      </li>

      <li className="AppHeaderDesktopUserMenu-LinkList-item">
        <HelpLink t={t} url={url} currentUrl={currentUrl} lang={lang} />
      </li>
    </ul>

    <div className="AppHeaderDesktopUserMenu--signInButton">
      <Link
        widthVariant="fullWidth"
        href={url("login")}
        className="AppHeaderDesktopUserMenu--signInButton"
      >
        {t("components.AppHeader.signIn")}
      </Link>
    </div>
  </Fragment>
);

NotLoggedInUserMenu.propTypes = {
  currentUrl: PropTypes.string,
  analyticsEvent: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  url: PropTypes.func.isRequired,
};

export default NotLoggedInUserMenu;
