import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  className: PropTypes.string,
};

function RoundCloseSvg({ className }) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle
        cx="15"
        cy="15"
        r="14.5"
        fill="#575D64"
        fillOpacity="0.25"
        stroke="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9236 13.8664L11.2481 10.1908L10.1167 11.3222L13.7923 14.9978L10.1144 18.6756L11.2458 19.8069L14.9236 16.1291L18.602 19.8075L19.7333 18.6761L16.055 14.9978L19.7311 11.3217L18.5997 10.1903L14.9236 13.8664Z"
        fill="white"
      />
    </svg>
  );
}

RoundCloseSvg.propTypes = propTypes;

export default RoundCloseSvg;
