import React, { createContext } from "react";
import PropTypes from "prop-types";
import pick from "lodash/pick";
import { withApi } from "../spages/spa/context/ApiContext";
import PublicListingPropType from "../types/PublicListing/PublicListingPropType";
import shortlist from "../utils/shortlist/shortlist";
import { isCurrentPage } from "../utils/url";

export const ShortlistContext = createContext({ shortlist: { list: [] } });

export const ShortlistContextProvider = withApi(
  class ShortlistContextProvider extends React.Component {
    static propTypes = {
      api: PropTypes.object,
      user: PropTypes.object,
      shortlistData: PropTypes.arrayOf(PublicListingPropType),
      children: PropTypes.any,
    };

    constructor(props) {
      super(props);
      const { user, api, shortlistData } = props;
      this.shortlist = shortlist({
        userId: user ? user._id : null,
        api: pick(api, [
          "listings.getListingsByGroupIds",
          "listings.getAvailableListingsByGroupId",
          "shortlist",
        ]),
        shortlistData,
      });
    }

    componentDidMount() {
      this.shortlist.onChange(() => this.forceUpdate());

      window.addEventListener(
        "visibilitychange",
        this.syncStateBetweenWindows,
        true,
      );
    }

    componentWillUnmount() {
      this.shortlist.onChange(() => {});

      window.removeEventListener(
        "visibilitychange",
        this.syncStateBetweenWindows,
      );
    }

    componentDidUpdate(prevProps) {
      // We don't want to do this on the shortlist page
      // because of on edge case related to race conditions.
      if (isCurrentPage(document.location.href, "shortlist")) {
        return;
      }

      if (prevProps.user && !this.props.user) {
        this.shortlist.onLogout();
      }
    }

    syncStateBetweenWindows = () => {
      if (document.hidden) {
        return;
      }

      this.shortlist.refresh();
    };

    render() {
      const { shortlist } = this;
      return (
        <ShortlistContext.Provider value={{ shortlist }}>
          {this.props.children}
        </ShortlistContext.Provider>
      );
    }
  },
);
