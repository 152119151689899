import gql from "./gql";
// Give different tag name to bypass linting against apollo graphql endpoint.
const nexusGql = gql;

const factory = (http) => {
  return {
    async get() {
      const { data, errors } = await http.post("/nexus", {
        query: nexusGql`
          query emailSubscriptionTypesForUser {
            emailSubscriptionTypesForUser {
              __typename
              ... on EmailSubscriptionTypesForUserPayloadSuccess {
                subscriptionTypes {
                  generalNews
                  productUpdates
                  promotions
                  tips
                }
              }
              ... on EmailSubscriptionTypesForUserPayloadError {
                name
                message
              }
            }
          }
        `,
      });

      if (errors && errors.length) {
        throw new Error(errors.map((e) => `${e.message}\n`));
      }

      if (
        data.emailSubscriptionTypesForUser.__typename ===
        "EmailSubscriptionTypesForUserPayloadError"
      ) {
        throw new Error(data.emailSubscriptionTypesForUser.name);
      }

      return {
        emailSubscriptionTypes:
          data.emailSubscriptionTypesForUser.subscriptionTypes,
      };
    },
    async updateEmailSubscriptionTypesViaZapier(subscriptionTypes) {
      const { data, errors } = await http.post("/nexus", {
        query: nexusGql`
         mutation updateEmailSubscriptionTypesViaZapier($subscriptionTypes: UpdateEmailSubscriptionTypesInput!) {
          updateEmailSubscriptionTypesViaZapier(subscriptionTypes: $subscriptionTypes) {
            __typename
            ... on UpdateEmailSubscriptionTypesViaZapierPayloadSuccess {
              success
            }
            ... on UpdateEmailSubscriptionTypesViaZapierPayloadError {
              name
              message
            }
          }
        }
        `,
        variables: { subscriptionTypes },
      });

      if (errors && errors.length) {
        throw new Error(errors.map((e) => `${e.message}\n`));
      }

      if (
        data.updateEmailSubscriptionTypesViaZapier.__typename ===
        "UpdateEmailSubscriptionTypesViaZapierPayloadError"
      ) {
        throw new Error(data.updateEmailSubscriptionTypesViaZapier.name);
      }

      return data;
    },

    async update(data) {
      const response = await http.post("/nexus", {
        query: nexusGql`
          mutation updateEmailNotificationsSettings($subscriptionTypes: UpdateEmailSubscriptionTypesInput!) {
            updateEmailNotificationsSettings(subscriptionTypes: $subscriptionTypes) {
              __typename
              ... on UpdateEmailNotificationsSettingsPayloadSuccess {
                success
              }
              ... on UpdateEmailNotificationsSettingsPayloadError {
                name
                message
              }
            }
          }
        `,
        variables: {
          subscriptionTypes: data.emailSubscriptionTypes,
        },
      });

      if (response.errors && response.errors.length) {
        throw new Error(response.errors.map((e) => `${e.message}\n`));
      }

      if (
        response.data.updateEmailNotificationsSettings.__typename ===
        "UpdateEmailNotificationsSettingsPayloadError"
      ) {
        throw new Error(response.data.updateEmailNotificationsSettings.name);
      }

      return response.data.updateEmailNotificationsSettings;
    },
  };
};

export default factory;
