import RedirectError from "../../../../errors/redirectError";

export default async ({ api, url, user, query }) => {
  // If the landlord already created a listing by clicking the email confirmation link before,
  // we redirect to it to avoid creating duplicate listings
  if (query.createListing && query.emailConfirmed) {
    const listings = await api.listings.getUserListings(user?._id);
    const [firstListing] = listings;

    if (firstListing) {
      throw new RedirectError({
        redirectTo: url("listingCreationWizard", {
          listingId: firstListing._id,
        }),
        status: 302,
      });
    }
  }
  return {};
};

export const shouldCreateListingPageV2Reload = () => {
  return false;
};
