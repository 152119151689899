/* eslint-disable import/prefer-default-export */

export const getRelatedRegions = (region, regions) => {
  let districts = [];
  if (region) {
    if (!region.parentSlug) {
      // find all districts (children) in this region
      districts = regions.items.filter(
        (item) => item.parentSlug === region.slug,
      );
    } else {
      // find all districts (siblings) with the same parent as this region and the parent reigon
      districts = regions.items.filter(
        (item) =>
          item.slug === region.parentSlug ||
          (item.parentSlug === region.parentSlug && item.slug !== region.slug),
      );
    }
  }
  return districts;
};
