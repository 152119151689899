import React, { useCallback, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import {
  create as createLocalizer,
  extractLanguageContent,
} from "../../../utils/localizer";
import { getDisplayName } from "../../../utils/react-utils";

const I18nContext = React.createContext({
  t: createLocalizer().t,
  keyExists: createLocalizer().keyExists,
  extractContent: () => null,
  lang: "en",
});

const providerPropTypes = {
  t: PropTypes.func,
  keyExists: PropTypes.func,
  lang: PropTypes.string,
  children: PropTypes.node,
};

export function I18nContextProvider({ t, lang, keyExists, children }) {
  const extractContent = useCallback(
    (langContentDictionary) => {
      return extractLanguageContent({ langContentDictionary, lang });
    },
    [lang],
  );

  const contextValue = useMemo(
    () => ({ t, lang, extractContent, keyExists }),
    [t, lang, extractContent, keyExists],
  );

  return (
    <I18nContext.Provider value={contextValue}>{children}</I18nContext.Provider>
  );
}

I18nContextProvider.propTypes = providerPropTypes;

export function useI18n() {
  return useContext(I18nContext);
}

export function withI18n(Component) {
  function WrappedComponent(props) {
    const i18nContextValue = useI18n();
    return <Component {...props} {...i18nContextValue} />;
  }

  // Assign static properties like propTypes:
  for (const key of Object.keys(Component)) {
    WrappedComponent[key] = Component[key];
  }

  WrappedComponent.displayName = `WithI18n(${getDisplayName(Component)})`;

  return WrappedComponent;
}
