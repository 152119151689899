import React from "react";
import PropTypes from "prop-types";

function ArrowRight({
  className = "",
  pathClassName = "",
  fillColor = "#fff",
  width = 12,
  height = 11,
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 0H4.4L8.7 4.3H0V5.8H8.7L4.4 10.1H6.5L11.6 5L6.5 0Z"
        fill={fillColor}
        className={pathClassName}
      />
    </svg>
  );
}

ArrowRight.propTypes = {
  className: PropTypes.string,
  pathClassName: PropTypes.string,
  fillColor: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ArrowRight;
