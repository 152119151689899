import { isBookingWizardPage } from "./auth-page-utils";

export default async ({ api, params, query = {} }) => {
  const { lang = "en" } = params;
  const redirect = decodeURIComponent(query.redirect);

  /**
   * Fetches the listing details when the `redirect` param targets booking wizard page
   * because we want to show the listing summary on the sidebar.
   */
  let listing = null;
  if (isBookingWizardPage({ redirect, lang })) {
    const groupId = redirect.split("/")[3];

    try {
      const listings = await api.listings.getListingsByGroupIds({
        groupIds: [groupId],
      });
      listing = listings[0];
    } catch (_error) {
      /**
       * Do nothing because will show a generic sidebar
       * when the listing is null
       */
    }
  }

  return {
    listing,
  };
};
